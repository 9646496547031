import React, { useEffect, useState } from 'react';

interface IProps {
    qty?: number;
    step?: number;
    onChange: (newQty: number) => void;
    disabled?: boolean;
    labels: {
        lessLabel: string;
        moreLabel: string;
        qtyLabel: string;
    };
}

const ControlQuantity = (props: IProps) => {
    const { labels, onChange, disabled } = props;
    const [qty, setQty] = useState(1);
    const step = props.step ?? 1;

    const decrease = () => {
        const newQty = qty - step;

        if (qty > 0) {
            setQty(newQty);
            onChange(newQty);
        }
    };

    const increase = () => {
        const newQty = qty + step;

        setQty(newQty);
        onChange(newQty);
    };

    useEffect(() => {
        if (props.qty && props.qty !== qty) {
            setQty(props.qty);
        }
    }, [props]);

    return (
        <React.Fragment>
            <button onClick={decrease} disabled={disabled}>
                {labels.lessLabel}
            </button>
            <input
                type="number"
                aria-label={labels.qtyLabel}
                value={qty}
                step={step ?? 1}
                disabled={disabled}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const newQty = parseInt(e.target.value);

                    setQty(newQty);
                    props.onChange(newQty);
                }}
            />
            <button onClick={increase} disabled={disabled}>
                {labels.moreLabel}
            </button>
        </React.Fragment>
    );
};

export default ControlQuantity;
