import * as React from 'react';
import { useEffect, useState } from 'react';
import { useMutation } from 'redux-query-react';
import { genericRequest } from 'components/Checkout/requests/genericRequest';
import { empty } from '../../helpers/empty';
import SystemNotifications from 'andbeauty-ui/lib/Applications/eBeauty/Components/SystemNotifications';
import SystemNotification from 'andbeauty-ui/lib/Applications/eBeauty/Components/SystemNotification';
import Button from 'andbeauty-ui/lib/Components/Button';
import RenderHTML from 'andbeauty-ui/lib/Components/RenderHTML';

let requesting = true;

interface IMessageState {
    giftIconPath: string;
    labels: {
        callout: string;
        chooseProducts: string;
    };
    url: string;
}

const MessageWidget = () => {
    const [{}, messageRequest] = useMutation(() => genericRequest({ type: 'message', url: window.messageWidgetUrl }));
    const [message, setMessage] = useState<IMessageState>();

    useEffect(() => {
        if (window.messageWidgetUrl && requesting) {
            requesting = false;
            messageRequest().then((response) => {
                if (response.status === 200 && response.body) {
                    const responseBody: IMessageState = response.body;
                    if (!empty(responseBody.giftIconPath)) {
                        setMessage(responseBody);
                    }
                }
            });
        }
    });

    return (
        <SystemNotifications inline={true} compact={true}>
            <SystemNotification iconPath={message?.giftIconPath} iconSize="large" intent="success">
                {message?.labels.callout && message?.url && (
                    <p>
                        <RenderHTML html={message.labels.callout} nowrapper={true} />{' '}
                        <Button
                            size="small"
                            intent="primary"
                            onClick={() => {
                                window.location.href = message?.url ? message.url : window.location.href;
                            }}
                            title={message.labels.chooseProducts}
                        />
                    </p>
                )}
            </SystemNotification>
        </SystemNotifications>
    );
};

export default MessageWidget;
