import { request } from 'data/requests/request';

export interface ICartSummaryResponse {
    quoteId: string;
    addToCartUri: string;
    cartItems: ICartItem[];
    summaryCount: number;
    subTotal: number;
    cartTotals: ICartTotalsRow[];
}

export interface ICartTotalsRow {
    label: string | React.ReactNode;
    value: string;
}

export interface ICartItem {
    id: number;
    sku: string;
    name: string;
    url: string;
    image: string;
    brand?: string;
    qty: number;
    price: number;
    specialPrice?: number;
    discountPercent?: number;
    isDisableQtyChange: boolean;
    rowTotal: number;
    rowTotalSpecial?: number;
    messages: ICartItemMessage[];
    options?: ICartItemOption[];
}

export enum IMessageType {
    ERROR = 'error',
    NOTICE = 'notice',
}

interface ICartItemMessage {
    type: IMessageType;
    text: string;
}

interface ICartItemOption {
    option_id: number;
    label: string;
    value: string;
}

const CartSummaryRequest = () =>
    request({
        type: 'cartSummaryRequest',
        url: 'checkout/ajax/cartsummary',
        method: 'GET',
        notApi: true,
    });

export default CartSummaryRequest;
