import { request } from 'data/requests/request';

const addressDeleteRequest = (addressId: number) =>
    request({
        type: 'addressDeleteRequest',
        url: 'customer/ajax/addressDelete',
        method: 'POST',
        data: { addressId },
        notApi: true,
    });

export default addressDeleteRequest;
