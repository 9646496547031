import React from 'react';
import Img from 'components/Image/Img';
import { formatPriceLocale } from '../../helpers/priceUtils';
import useCartSummary from '../../hooks/useCartSummary';
import { useDispatch } from 'react-redux';
import { closeOverlay, openOverlay } from 'data/overlays/overlayHandler';

interface IProps {
    config: {
        deliveryCallout?: string;
        labels: {
            myCart: string;
            freeDelivery: string;
        };
    };
}

const MiniCartButton = (props: IProps) => {
    const { config } = props;
    const { deliveryCallout, labels } = config;
    const dispatch = useDispatch();
    const { subTotal, itemsQty } = useCartSummary();

    const openMiniCart = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        dispatch(closeOverlay({ name: 'all' }));
        dispatch(openOverlay({ name: 'minicart' }));
    };

    return (
        <a href="/checkout/cart" className="mini-cart" onClick={openMiniCart}>
            <span className="icon">
                <Img iconName="sprite_cart" alt={labels.myCart} />
                <span className="products">{itemsQty}</span>
            </span>
            <span className="label">{labels.myCart}</span>
            <span className="amount">{formatPriceLocale(subTotal)}</span>
            {deliveryCallout && (
                <span className="delivery">
                    <Img iconName="icon_delivery" alt={labels.freeDelivery} /> {deliveryCallout}
                </span>
            )}
        </a>
    );
};

export default MiniCartButton;
