import { request } from 'data/requests/request';
import { CookieType } from 'components/Gdpr/enums/CookieType';

const instagramFeedRequest = () =>
    request({
        type: 'instagramFeedRequest',
        url: 'mp_instagram/feed/get',
        method: 'GET',
        notApi: true,
        absolute: false,
    });

export default instagramFeedRequest;

export interface IInstagramFeedResponse {
    data: IInstagramPost[];
}

export interface IInstagramPost {
    media_url: string;
    permalink: string;
    caption?: string;
}
