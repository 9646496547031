import React, { useState } from 'react';
import { useKeypress } from 'andbeauty-ui/lib/Utilities/Generic';

export interface IProps {
    config: IPagerProps;
    onChange?: (pageNum: number) => void;
}

export interface IPagerProps {
    currentPage: number;
    lastPage: number;
    pageParam: string;
    labels: {
        page: string;
        previous: string;
        next: string;
    };
}

const Pager = (props: IProps) => {
    const { config, onChange } = props;
    const { lastPage, labels } = config;
    const [isInputFocused, setIsInputFocused] = useState(false);
    const [currentPage, setCurrentPage] = useState(config.currentPage);

    const changePage = (pageNum: number) => {
        if (onChange) {
            onChange(pageNum);
        }
    };

    const previousPage = () => {
        const newPage = currentPage - 1;

        if (newPage >= 1) {
            changePage(newPage);
            setCurrentPage(newPage);
        }
    };

    const nextPage = () => {
        const newPage = currentPage + 1;

        if (newPage <= lastPage) {
            changePage(newPage);
            setCurrentPage(newPage);
        }
    };

    useKeypress(
        'Enter',
        () => {
            if (isInputFocused) {
                changePage(currentPage);
            }
        },
        [isInputFocused, currentPage],
    );

    return (
        <div className="paginator">
            <a
                className="paginator__prev"
                href="#"
                onClick={(e) => {
                    e.preventDefault();
                    previousPage();
                }}
            >
                <span>{labels.previous}</span>
            </a>
            <div className="paginator__counter">
                <input
                    type="number"
                    value={currentPage}
                    onChange={(e) => {
                        const newPage = isNaN(e.target.valueAsNumber) ? currentPage : e.target.valueAsNumber;

                        if (newPage <= lastPage) {
                            setCurrentPage(newPage);
                        }
                    }}
                    onFocus={() => setIsInputFocused(true)}
                    onBlur={() => setIsInputFocused(false)}
                    aria-label={labels.page}
                />
                <span className="paginator__label"> / {lastPage}</span>
            </div>
            <a
                className="paginator__next"
                href="#"
                onClick={(e) => {
                    e.preventDefault();
                    nextPage();
                }}
            >
                <span>{labels.next}</span>
            </a>
        </div>
    );
};

export default Pager;
