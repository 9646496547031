import * as React from 'react';
import { useDispatch } from 'react-redux';
import { closeOverlay, openOverlay } from 'data/overlays/overlayHandler';

export interface IProps {
    config: {
        loginLabel: string;
    };
}

const LoginButton = (props: IProps) => {
    const { config } = props;
    const dispatch = useDispatch();

    const openLogin = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        dispatch(closeOverlay({ name: 'all' }));
        dispatch(openOverlay({ name: 'login' }));
    };

    return (
        <a href="#" onClick={openLogin}>
            {config.loginLabel}
        </a>
    );
};

export default LoginButton;
