import React from 'react';
import { postRequest } from 'data/requests/postRequest';
import { useMutation } from 'redux-query-react';
import Toaster from 'andbeauty-ui/lib/Components/Toaster';

const PostDataHelper = () => {
    const elements = document.querySelectorAll('a[data-post]');
    const [{}, postDataRequest] = useMutation((url: string, data: string) =>
        postRequest({
            method: 'POST',
            type: 'PostDataRequest',
            absoluteUrl: true,
            url,
            data,
        }),
    );

    const onClickHandler = (e) => {
        const dataPost = JSON.parse(e.currentTarget.getAttribute('data-post'));
        let redirectUrl;

        if (dataPost.data.redirectUrl) {
            redirectUrl = dataPost.data.redirectUrl;
            delete dataPost.data.redirectUrl;
        }

        postDataRequest(dataPost.action, dataPost.data)
            .then(() => {
                if (redirectUrl) {
                    window.location.href = redirectUrl;
                } else {
                    window.location.reload();
                }
            })
            .catch((reason) => {
                Toaster.addToast({
                    intent: 'danger',
                    text: reason,
                });
            });

        return false;
    };

    Array.from(elements).forEach((element) => {
        element.addEventListener('click', onClickHandler);
    });
    return null;
};

export default PostDataHelper;
