import React, { useState } from 'react';
import Button from 'andbeauty-ui/lib/Components/Button';
import { useRequest } from 'redux-query-react';
import DataConfigRequest, { IDataConfigResponse } from 'components/BrandMenu/requests/DataConfigRequest';
import { useSelector } from 'react-redux';

interface IProps {
    categoryId: number;
}

const mapStateToProps = (state) => {
    return {
        brandMenuDataConfig: state.entities.brandMenuDataConfig as IDataConfigResponse,
    };
};
const BrandMenu = (props: IProps) => {
    const { categoryId } = props;
    const [{ isFinished }] = useRequest(DataConfigRequest(categoryId));
    const { brandMenuDataConfig } = useSelector(mapStateToProps);
    const [selectedGroup, setSelectedGroup] = useState<string>();

    return (
        <div className="brands-menu__inner">
            {isFinished && brandMenuDataConfig && (
                <React.Fragment>
                    <nav className="brands-menu__alphabet">
                        <ul>
                            {Object.keys(brandMenuDataConfig.categories).map((group) => (
                                <li key={group}>
                                    <Button
                                        className={selectedGroup === group ? 'active' : ''}
                                        title={group}
                                        size="small"
                                        onClick={() => setSelectedGroup(selectedGroup === group ? '' : group)}
                                    />
                                </li>
                            ))}
                        </ul>
                    </nav>
                    <div className="brands-menu__brands">
                        {Object.keys(brandMenuDataConfig.categories).map((group) => (
                            <React.Fragment key={group}>
                                {(group === selectedGroup || !selectedGroup) && (
                                    <div className="brands-menu__brands-group">
                                        <h2>{group}</h2>
                                        <ul>
                                            {brandMenuDataConfig.categories[group].map((category, i) => (
                                                <li key={i}>
                                                    <a href={category.url}>{category.name}</a>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </React.Fragment>
                        ))}
                    </div>
                </React.Fragment>
            )}
        </div>
    );
};

export default BrandMenu;
