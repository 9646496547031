import React from 'react';
import Img from 'components/Image/Img';
import Buttons from 'andbeauty-ui/lib/Components/Buttons';
import Button from 'andbeauty-ui/lib/Components/Button';
import CartTotals from 'andbeauty-ui/lib/Applications/eBeauty/Components/CartTotals/index';
import useCartSummary from '../../../hooks/useCartSummary';
import { formatPriceLocale } from '../../../helpers/priceUtils';
import Coupon, { ICouponConfig } from 'components/Checkout/Cart/Coupon';
import CustomerBenefitCallout from 'components/Checkout/Cart/CustomerBenefitCallout';
import useAuth from '../../../hooks/useAuth';

interface IProps {
    config: {
        checkoutUrl: string;
        couponConfig: ICouponConfig;
        customerBenefitCallout?: string;
        labels: {
            summaryTitle: string;
            toCheckout: string;
            orderTotal: string;
        };
    };
}

const Sidebar = (props: IProps) => {
    const { config } = props;
    const { couponConfig, labels } = config;
    const { subTotal, cartTotals } = useCartSummary();
    const { isLoggedIn } = useAuth();

    return (
        <React.Fragment>
            <div className="layout-cart__sidebar-scroll">
                <div className="layout-cart__sidebar-icon">
                    <Img iconName="icon_cart_big" />
                </div>
                <h2>{labels.summaryTitle}</h2>
                <Coupon config={couponConfig} />
                {cartTotals?.length && <CartTotals data={cartTotals} />}
                {!isLoggedIn && config.customerBenefitCallout && (
                    <CustomerBenefitCallout callout={config.customerBenefitCallout} />
                )}
            </div>
            <p className="layout-cart__sidebar-total">
                {labels.orderTotal}: <span className="text-accented">{formatPriceLocale(subTotal)}</span>
            </p>
            <Buttons>
                <Button intent="primary" title={labels.toCheckout} type="anchor" href={config.checkoutUrl} />
            </Buttons>
        </React.Fragment>
    );
};

export default Sidebar;
