import React, { useEffect, useState } from 'react';
import { IProduct } from 'components/Catalog/interfaces/IProduct';
import { IProductListLabels } from 'components/Catalog/Product/ProductList';
import MultiCarousel from 'andbeauty-ui/lib/Components/MultiCarousel';
import BoxProduct from 'components/Catalog/Product/List/BoxProduct';
import RenderTabs from 'andbeauty-ui/lib/Applications/eBeauty/Components/RenderTabs';

export interface ISlide {
    categoryId: number;
    label: string;
    products: IProduct[];
}

interface IProps {
    config: {
        slides: ISlide[];
        labels: IProductListLabels;
    };
}

const CategorySlider = (props: IProps) => {
    const { config } = props;
    const { slides, labels } = config;
    const [activeSlide, setActiveSlide] = useState<number>();

    useEffect(() => {
        if (!activeSlide && slides.length) {
            setActiveSlide(slides[0].categoryId);
        }
    }, []);

    const slideData = {};
    slides.forEach((slide) =>
        Object.assign(slideData, {
            [slide.label]: {
                title: slide.label,
                content: (
                    <div
                        className="carousel carousel-inline size-auto"
                        data-gtm-context={JSON.stringify({ itemListName: 'Product slider', itemListId: slide.label })}
                    >
                        <MultiCarousel showArrows renderArrowsOutside>
                            {slide.products.map((product) => (
                                <BoxProduct product={product} labels={labels} key={product.sku} />
                            ))}
                        </MultiCarousel>
                    </div>
                ),
                contentClassName: 'style-minimal',
            },
        }),
    );

    return (
        <React.Fragment>
            {slides.length > 0 && (
                <RenderTabs
                    className={'style-minimal'}
                    tabsClassName={'align-center'}
                    activeTab={slides[0].label}
                    tabs={slideData}
                />
            )}
        </React.Fragment>
    );
};

export default CategorySlider;
