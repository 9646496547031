import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import SystemNotifications from 'andbeauty-ui/lib/Applications/eBeauty/Components/SystemNotifications';
import SystemNotification from 'andbeauty-ui/lib/Applications/eBeauty/Components/SystemNotification';
import { mapStateToProps } from 'components/SampleProduct/SampleProductPopup';
import RenderHTML from 'andbeauty-ui/lib/Components/RenderHTML/index';
import SampleProductPopupTriggerButton from 'components/SampleProduct/SampleProductPopupTriggerButton';

interface IProps {
    config: {
        giftIconPath: string;
        labels: {
            callout: string;
            chooseProducts: string;
        };
    };
}

const SampleProductCartCallout = (props: IProps) => {
    const { config } = props;
    const { giftIconPath, labels } = config;
    const [calloutMsg, setCalloutMsg] = useState<string>();
    const { sampleProductsDataConfig } = useSelector(mapStateToProps);

    useEffect(() => {
        if (sampleProductsDataConfig?.availableSamplesQty) {
            setCalloutMsg(labels.callout.replace('%1', sampleProductsDataConfig.availableSamplesQty.toString()));
        }
    }, [sampleProductsDataConfig]);

    return (
        <React.Fragment>
            {sampleProductsDataConfig?.availableSamplesQty > 0 && (
                <SystemNotifications inline compact>
                    <SystemNotification iconPath={giftIconPath} iconSize="large" intent="success">
                        {calloutMsg && (
                            <p>
                                <RenderHTML html={calloutMsg} nowrapper />{' '}
                                <SampleProductPopupTriggerButton labels={{ addFreeSamples: labels.chooseProducts }} />
                            </p>
                        )}
                    </SystemNotification>
                </SystemNotifications>
            )}
        </React.Fragment>
    );
};

export default SampleProductCartCallout;
