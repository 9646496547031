import React from 'react';
import { ITopSearch } from 'components/CatalogSearch/interfaces/ITopSearch';
import Overlay from 'andbeauty-ui/lib/Components/Overlay';
import Icon from 'andbeauty-ui/lib/Components/Icon/index';
import { overlaysSelector } from 'data/overlays/overlaysSelector';
import { closeOverlay, openOverlay } from 'data/overlays/overlayHandler';
import Img from 'components/Image/Img';
import { includes } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import TopSearch from 'components/CatalogSearch/TopSearch';

interface IProps {
    config: ITopSearch;
}

const TopSearchMobile = (props: IProps) => {
    const { config } = props;
    const { labels } = config;
    const dispatch = useDispatch();
    const { openOverlays } = useSelector(overlaysSelector);

    const openSearch = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        dispatch(openOverlay({ name: 'search-mobile' }));
    };

    const closeSearch = () => {
        dispatch(closeOverlay({ name: 'search-mobile' }));
    };

    return (
        <React.Fragment>
            <a href="#" className="toggler" onClick={openSearch}>
                <span className="icon">
                    <Img iconName="icon_search" alt={labels.search} />
                </span>
                <span>{labels.search}</span>
            </a>
            <Overlay
                isOpen={includes(openOverlays, 'search-mobile')}
                doClose={closeSearch}
                layout="focusview"
                autoFocus={false}
                size="max"
                className="site-menu-mobile overlay-search"
                customHeader={
                    <div className="site-menu-mobile-heading right">
                        <button onClick={closeSearch}>
                            <Icon kind="close03" width={16} height={16} />
                        </button>
                    </div>
                }
            >
                <div className="search-mobile-content">
                    <TopSearch config={config} autoFocus />
                </div>
            </Overlay>
        </React.Fragment>
    );
};

export default TopSearchMobile;
