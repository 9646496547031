interface Window {
    jwt: string;
}

export const getHeaders = (): { [key: string]: string } => {
    const headers: { [key: string]: string } = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
    };
    const token = localStorage.getItem('jwt');

    if (token) {
        headers.Authorization = `Bearer ${token}`;
    }

    return headers;
};
