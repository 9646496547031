import { request } from 'data/requests/request';

const reviewPostRequest = (url, data) =>
    request({
        type: 'reviewPost',
        url: `${url}`,
        method: 'POST',
        force: true,
        notApi: true,
        absolute: true,
        clearContentType: true,
        data,
    });

export default reviewPostRequest;
