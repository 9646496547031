import React, { CSSProperties, useEffect, useState } from 'react';
import { intersection } from 'lodash';
import classNames from 'classnames';
import {
    IAttribute,
    IAttributeOption,
    ISwatchOption,
    SWATCH_TYPE,
} from 'components/Catalog/interfaces/IProductConfigurator';

interface IProps {
    attribute: IAttribute;
    swatchPlaceholder: string;
    swatchOptions?: {
        [key: number]: ISwatchOption;
    };
    availableProducts: string[];
    onOptionSelect: (attribute: IAttribute, option: IAttributeOption) => void;
}

const AttributeRenderer = (props: IProps) => {
    const { attribute, swatchPlaceholder, swatchOptions, availableProducts, onOptionSelect } = props;
    const [selectedOption, setSelectedOption] = useState<IAttributeOption>();

    const isSwatchAttribute = () => {
        if (swatchOptions) {
            const firstOption = Object.values(swatchOptions)[0];

            return firstOption.type === SWATCH_TYPE.IMAGE;
        }

        return false;
    };

    const getSwatchStyles = (option: IAttributeOption) => {
        const style: CSSProperties = {
            backgroundImage: `url(${swatchPlaceholder})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
        };

        if (swatchOptions) {
            const swatchOption = swatchOptions[option.id];

            if (swatchOption) {
                style.backgroundImage = `url(${swatchOption.thumb})`;
            }
        }

        return style;
    };

    const isOptionAvailable = (availableProducts: string[], optionProducts: string[]) => {
        // nothing selected, everything is available
        if (availableProducts.length === 0) {
            return true;
        }

        return intersection(availableProducts, optionProducts).length > 0;
    };

    const handleOptionSelect = (option: IAttributeOption) => {
        if (!selectedOption || (selectedOption && selectedOption.id !== option.id)) {
            setSelectedOption(option);
            onOptionSelect(attribute, option);
        }
    };

    useEffect(() => {
        if (!selectedOption && attribute['options'].length === 1) {
            handleOptionSelect(attribute['options'][0]);
        }
    }, []);

    return (
        <React.Fragment>
            <p className="product-configurator__param-title">
                {attribute.label}: {selectedOption && selectedOption.label}
            </p>
            <div className="product-configurator__values">
                <ul
                    className={isSwatchAttribute() ? 'product-configurator__swatches' : 'product-configurator__options'}
                >
                    {attribute.options.map((option) => (
                        <li
                            key={option.id}
                            className={classNames({
                                selected: selectedOption && selectedOption.id === option.id,
                                disabled: !isOptionAvailable(availableProducts, option.products),
                            })}
                        >
                            <label title={option.label}>
                                <input type="radio" name={attribute.code} onClick={() => handleOptionSelect(option)} />
                                <span>
                                    {isSwatchAttribute() && (
                                        <span className="img" style={getSwatchStyles(option)}></span>
                                    )}
                                    {!isSwatchAttribute() && option.label}
                                </span>
                            </label>
                        </li>
                    ))}
                </ul>
            </div>
        </React.Fragment>
    );
};

export default AttributeRenderer;
