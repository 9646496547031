import * as React from 'react';
import SimplePayment from 'components/Checkout/Payment/Methods/SimplePayment';
import { IPaymentMethodProps } from 'components/Checkout/Payment/PaymentBlock';
import { useMutation, useRequest } from 'redux-query-react';
import { IPaymentMethodResponse } from '../../interfaces/payment/IPaymentMethodResponse';
import { useEffect, useState } from 'react';
import { PaymentMethodEnum } from 'components/Checkout/Payment/Methods/MethodHandler';
import { request } from 'data/requests/request';
import { useSelector } from 'react-redux';
import { empty } from '../../../../helpers/empty';
import { ResponseStatus } from '../../../../enums/ResponseStatus';

interface IProps {
    paymentMethodProps: IPaymentMethodProps;
    method: IPaymentMethodResponse;
    email: string | undefined;
    setAllowQuery: (allowQuery: boolean) => void;
    extensionAttributes?: () => {
        risks_awareness?: string;
        agreement_ids?: string[];
    };
}

interface IDirectBank {
    code: string;
    name: string;
    paymentMethod: string;
    order?: number;
    buttonUrl?: string;
    description?: string;
}

interface ISaveOptionData {
    option_code: string;
    option_label: string;
}

const mapStateToProps = (state) => {
    return {
        paymentConfig: state.entities.directPaymentConfig,
    };
};

const ModenaPaymentInstallment = (props: IProps) => {
    const [directBanks, setDirectBanks] = useState<IDirectBank[] | undefined>([]);
    const [methods, setMethods] = useState<React.ReactFragment[]>([]);

    const [{}, saveOption] = useMutation((data: ISaveOptionData) =>
        request({
            type: 'modenaDirectSaveOption',
            url: 'direct/request/saveOption',
            notApi: true,
            data,
        }),
    );

    const requestPayload = {
        type: 'directPaymentConfig',
        url: 'modenapayment/payment/directBanks',
        notApi: true,
    };
    const [{ isFinished }] = useRequest(request(requestPayload));
    const { paymentConfig } = useSelector(mapStateToProps);

    useEffect(() => {
        if (isFinished) {
            setDirectBanks(paymentConfig?.banks);
        }
    }, [isFinished]);

    useEffect(() => {
        if (directBanks && directBanks.length) {
            const methodsLocal: React.ReactFragment[] = [];

            const simpleElement = (bank: IDirectBank) => (
                <SimplePayment
                    method={PaymentMethodEnum.modena_direct}
                    key={bank.code}
                    onClick={() => {
                        saveOption({
                            option_code: bank.code,
                            option_label: bank.name,
                        }).then((response) => {
                            if (response.status === ResponseStatus.ok) {
                                props.paymentMethodProps.onClick({ redirect: undefined });
                            }
                        });
                    }}
                    logoUrl={bank.buttonUrl}
                    description={bank?.description}
                    label={bank.name}
                    paymentMethodProps={props.paymentMethodProps}
                />
            );

            directBanks.map((bank: IDirectBank) => {
                methodsLocal.push(simpleElement(bank));
            });

            setMethods(methodsLocal);
        }
    }, [directBanks]);

    return <>{methods && !empty(methods.length) && methods}</>;
};

export default ModenaPaymentInstallment;
