import * as React from 'react';
import { useRef, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Buttons from 'andbeauty-ui/lib/Components/Buttons';
import FormList from 'andbeauty-ui/lib/Applications/eBeauty/Components/FormList/index';
import Button from 'andbeauty-ui/lib/Components/Button/index';
import FormKey from 'components/GeneralForm/Field/FormKey';
import { getCaptchaToken } from 'components/GoogleRecaptcha/Index';
import { ITranslations } from './CartSharePopup';
import FormListItem from 'andbeauty-ui/lib/Applications/eBeauty/Components/FormListItem';
import ControlTextarea from 'andbeauty-ui/lib/Components/ControlTextarea';
import ControlInput from 'andbeauty-ui/lib/Components/ControlInput';

interface IProps {
    cartShareUrl: string;
    actionUrl: string;
    labels: ITranslations;
}

const CartShareEmailForm = (props: IProps) => {
    const { cartShareUrl, labels, actionUrl } = props;
    const [captchaToken, setCaptchaToken] = useState('');
    const formElement = useRef<HTMLFormElement>(null);

    const FormDataValidationSchema = Yup.object().shape({
        name: Yup.string().nullable().required(labels.requiredField),
        recipientName: Yup.string().nullable().required(labels.requiredField),
        recipientEmail: Yup.string().email(labels.invalidEmail).nullable().required(labels.requiredField),
        message: Yup.string().nullable(),
    });

    const formik = useFormik({
        initialValues: {
            name: '',
            recipientName: '',
            recipientEmail: '',
            message: '',
        },
        validationSchema: FormDataValidationSchema,
        validateOnBlur: true,
        validateOnChange: false,
        onSubmit: () => {
            void getCaptchaToken().then((token) => {
                setCaptchaToken(token);

                if (formElement.current) {
                    formElement.current.submit();
                }
            });
        },
    });

    return (
        <div className="layout-grid__column separated">
            <h3 className="mt10">{labels.orShareByEmail}</h3>
            <form className="mt10" ref={formElement} action={actionUrl} method="post" onSubmit={formik.handleSubmit}>
                <FormKey />
                <input type="hidden" name="g-recaptcha-response" value={captchaToken} />
                <input type="hidden" name="cartShareUrl" value={cartShareUrl} />
                <FormList size="full">
                    <FormListItem label={labels.yourName} required={true} error={formik.errors.name}>
                        <ControlInput name="name" value={formik.values.name} onChange={formik.handleChange} />
                    </FormListItem>
                    <FormListItem label={labels.recipientName} error={formik.errors.recipientName} required={true}>
                        <ControlInput
                            name="recipientName"
                            value={formik.values.recipientName}
                            onChange={formik.handleChange}
                        />
                    </FormListItem>
                    <FormListItem label={labels.recipientEmail} error={formik.errors.recipientEmail} required={true}>
                        <ControlInput
                            name="recipientEmail"
                            value={formik.values.recipientEmail}
                            onChange={formik.handleChange}
                        />
                    </FormListItem>
                    <FormListItem label={labels.message} error={formik.errors.message}>
                        <ControlTextarea
                            name="message"
                            value={formik.values.message}
                            onChange={formik.handleChange}
                            minRows={3}
                        />
                    </FormListItem>
                </FormList>
                <Buttons layout="vertical-wide" className="align-center">
                    <Button type="submit" intent="primary" title={labels.send} />
                </Buttons>
            </form>
        </div>
    );
};

export default CartShareEmailForm;
