import { QueryConfig, RequestBody } from 'redux-query';
import { getHeaders } from '../../helpers/request/getHeaders';

export interface IRequestData {
    type: string;
    url: string;
    method?: string;
    data?: RequestBody;
    useStoreCode?: boolean;
    absoluteUrl?: boolean;
}

export const postRequest = (data: IRequestData): QueryConfig => {
    const url = window.API_URL;
    const storeString = data.useStoreCode ? `${window.STORE_CODE}/` : '';

    return {
        url: data.absoluteUrl ? data.url : `${url}/rest/${storeString}V1/${data.url}`,
        options: {
            method: data.method ?? 'POST',
            headers: getHeaders(),
        },
        body: data.data ?? {},
        transform: (response: any) => ({
            [`${data.type}`]: response as any,
        }),
        update: {
            [`${data.type}`]: (prev: any, next: any) => {
                return next;
            },
        },
    };
};
