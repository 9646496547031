import * as React from 'react';
import SimplePayment from 'components/Checkout/Payment/Methods/SimplePayment';
import { IPaymentMethodProps } from 'components/Checkout/Payment/PaymentBlock';
import { IPaymentMethodResponse } from '../../interfaces/payment/IPaymentMethodResponse';

interface IProps {
    paymentMethodProps: IPaymentMethodProps;
    method: IPaymentMethodResponse;
    email: string | undefined;
    setAllowQuery: (allowQuery: boolean) => void;
    extensionAttributes?: () => {
        risks_awareness?: string;
        agreement_ids?: string[];
    };
}

const ModenaPaymentInstallment = (props: IProps) => {
    const { method } = props;

    return (
        <SimplePayment
            method={method.code}
            key={method.code}
            onClick={() => {
                props.paymentMethodProps.onClick({ redirect: undefined });
            }}
            label={method.title}
            paymentMethodProps={props.paymentMethodProps}
        />
    );
};

export default ModenaPaymentInstallment;
