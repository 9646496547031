import React, { useState } from 'react';
import { useMutation } from 'redux-query-react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import ControlStars from 'andbeauty-ui/lib/Applications/eBeauty/Components/ControlStars';
import reviewPostRequest from 'components/Review/requests/reviewPostRequest';
import FormListItem from 'andbeauty-ui/lib/Applications/eBeauty/Components/FormListItem';
import ControlInput from 'andbeauty-ui/lib/Components/ControlInput';
import ControlTextarea from 'andbeauty-ui/lib/Components/ControlTextarea';
import FormList from 'andbeauty-ui/lib/Applications/eBeauty/Components/FormList';
import Buttons from 'andbeauty-ui/lib/Components/Buttons';
import Button from 'andbeauty-ui/lib/Components/Button';
import ErrorRow from 'components/ui/Error/ErrorRow';
import { setCaptchaToken } from 'components/GoogleRecaptcha/Index';
import MessageManager, { IMessage } from 'components/ui/MessageManager';

export interface IRating {
    rating_id: string;
    option_id: string;
    code: string;
}

export interface IFormLabels {
    writeOwn: string;
    requiredFields: string;
    yourRating: string;
    nickname: string;
    summary: string;
    review: string;
    submit: string;
    required: string;
    maxLengthLabel: string;
    invalidCharLabel: string;
}

interface IProps {
    url: string;
    labels: IFormLabels;
    ratings: IRating[];
}

interface IForm {
    score?: number;
    nickname?: string;
    title?: string;
    detail?: string;
}

const ReviewForm = (props: IProps): JSX.Element => {
    const { url, labels, ratings } = props;
    const [{}, postRequest] = useMutation((data) => reviewPostRequest(url, data));
    const [messages, setMessages] = useState<IMessage[]>([]);
    const [isSuccessful, setIsSuccessful] = useState(false);
    const formik = useFormik({
        initialValues: { score: 0, nickname: '', title: '', detail: '' },
        validationSchema: Yup.object().shape({
            score: Yup.string().nullable().required(labels.required),
            nickname: Yup.string().nullable().required(labels.required).max(13, labels.maxLengthLabel),
            title: Yup.string().nullable().required(labels.required),
            detail: Yup.string()
                .nullable()
                .required(labels.required)
                .max(512)
                .matches(/[A-Za-z\d _.,!"'/$]*/, labels.invalidCharLabel),
        }),
        validateOnChange: false,
        validateOnBlur: true,
        onSubmit: async (values) => submit(values),
    });

    const submit = async (data: IForm) => {
        setMessages([]);
        await setCaptchaToken(data);

        const rating = ratings.find((rating) => parseInt(rating.code) === data.score);
        const response = await postRequest({ ...data, optionId: rating?.option_id, ratingId: rating?.rating_id });

        if (response && !response.body.error) {
            setMessages([{ intent: 'success', content: response.body.message }]);
            setIsSuccessful(true);
        } else if (response.body.messages) {
            if (response.body.message) {
                setMessages([{ intent: 'danger', content: response.body.message }]);
            }

            response.body.messages.forEach((message) => {
                formik.setFieldError(message.field, message.message);
            });
        }
    };

    return (
        <React.Fragment>
            <MessageManager messages={messages} labels={{ close: '' }} />
            <form className="product-reviews__form" onSubmit={formik.handleSubmit}>
                <h3>{labels.yourRating}</h3>
                <p>{labels.requiredFields}</p>
                <fieldset>
                    <ControlStars
                        label={labels.yourRating}
                        allowZero={true}
                        required={true}
                        maxScore={5}
                        score={formik.values.score}
                        setScore={(score) => {
                            formik.setFieldValue('score', score);
                        }}
                    />
                    <ErrorRow msg={formik.errors.score} />
                </fieldset>
                <FormList>
                    <FormListItem label={labels.nickname} required={true} error={formik.errors.nickname}>
                        <ControlInput name="nickname" value={formik.values.nickname} onChange={formik.handleChange} />
                    </FormListItem>
                    <FormListItem label={labels.summary} required={true} error={formik.errors.title}>
                        <ControlInput name="title" value={formik.values.title} onChange={formik.handleChange} />
                    </FormListItem>
                    <FormListItem label={labels.review} required={true} error={formik.errors.detail}>
                        <ControlTextarea
                            minRows={5}
                            name="detail"
                            value={formik.values.detail}
                            onChange={formik.handleChange}
                        />
                    </FormListItem>
                </FormList>
                <Buttons>
                    <Button
                        type="submit"
                        intent="secondary"
                        disabled={formik.isSubmitting || isSuccessful}
                        title={labels.submit}
                    />
                </Buttons>
            </form>
        </React.Fragment>
    );
};
export default ReviewForm;
