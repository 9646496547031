import { request } from 'data/requests/request';
import { IProduct } from 'components/Catalog/interfaces/IProduct';
import { IGalleryConfig } from 'components/Catalog/interfaces/IGalleryConfig';

const ProductViewRequest = (url: string) =>
    request({
        type: 'productViewRequest',
        url,
        method: 'GET',
        notApi: true,
        absolute: true,
        force: true,
    });

export default ProductViewRequest;

export interface IProductViewResponse {
    product?: IProduct;
    galleryConfig?: IGalleryConfig;
}
