import { postRequest } from 'data/requests/postRequest';

interface IProduct {
    sku: string;
    typeId: string;
}

export interface IProductConfiguration {
    option_id: string;
    option_value: string;
}

const AddToCartRequest = (
    url: string,
    quoteId: string,
    product: IProduct,
    qty = 1,
    configurations: IProductConfiguration[] = [],
) =>
    postRequest({
        method: 'POST',
        type: 'AddToCartRequest',
        url,
        data: {
            cartItem: {
                sku: product.sku,
                product_type: product.typeId,
                qty,
                quote_id: quoteId,
                product_option: {
                    extension_attributes: {
                        configurable_item_options: configurations,
                    },
                },
            },
        },
    });

export default AddToCartRequest;
