import { IMessageType } from 'components/Checkout/requests/CartSummaryRequest';

export default function convertTypeToIntent(type: IMessageType) {
    switch (type) {
        case IMessageType.ERROR:
            return 'danger';
        case IMessageType.NOTICE:
            return 'info';
        default:
            return 'discrete';
    }
}
