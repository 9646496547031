import { postRequest } from 'data/requests/postRequest';

const applyCouponRequest = (url: string, coupon: string) =>
    postRequest({
        method: 'PUT',
        type: 'applyCouponRequest',
        url: `${url}/${coupon}`,
    });

export default applyCouponRequest;
