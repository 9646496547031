import React from 'react';
import { useDispatch } from 'react-redux';
import Img from 'components/Image/Img';
import Buttons from 'andbeauty-ui/lib/Components/Buttons';
import Button from 'andbeauty-ui/lib/Components/Button/index';
import { closeOverlay, openOverlay } from 'data/overlays/overlayHandler';

export interface IProps {
    config: {
        labels: {
            buttonLabel: string;
        };
    };
}

const CartShareButton = (props: IProps) => {
    const dispatch = useDispatch();
    const { config } = props;

    const openCartSharePopup = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        dispatch(closeOverlay({ name: 'all' }));
        dispatch(openOverlay({ name: 'shareCartPopup' }));
    };

    return (
        <Buttons>
            <Button
                type="button"
                intent="secondary-muted"
                size="small"
                onClick={(e) => openCartSharePopup(e)}
                icon="ebeauty-share"
                title={config.labels.buttonLabel}
            />
        </Buttons>
    );
};

export default CartShareButton;
