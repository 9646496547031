import * as React from 'react';
import classNames from 'classnames';

import 'andbeauty-ui/lib/Applications/Checkout/Components/Banklinks/styles.scss';
import { empty } from '../../../helpers/empty';

interface IBanklinksProps {
    defaultComponents: React.Component[]; // sorted components that have isDefault true
    verticalComponents: React.Component[]; // sorted components that have isDefault false
    components: React.Component[]; // all the components
}

/**
 * Basic list element with hover background. You should wrap this inside ListBasic component.
 */
const Banklinks = (props: IBanklinksProps) => {
    if (props.components.length === 0) {
        return null;
    }

    if (!empty(window.detailedDefaultSortEnabled)) {
        return (
            <>
                {props.defaultComponents && !empty(props.defaultComponents.length) && (
                    <>
                        <ul className={`banklinks layout-default`}>{props.defaultComponents}</ul>
                        <hr />
                    </>
                )}
                <ul className={`banklinks layout-vertical`}>{props.verticalComponents}</ul>
            </>
        );
    }

    return (
        <ul
            className={classNames('banklinks', {
                'layout-default': !window.detailedPaymentEnabled,
                'layout-vertical': window.detailedPaymentEnabled,
            })}
        >
            {props.components}
        </ul>
    );
};

export default Banklinks;
