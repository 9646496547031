import { Dispatch } from 'redux';
import { dispatchType } from 'components/Catalog/store/variantProductReducer';
import { IProduct } from 'components/Catalog/interfaces/IProduct';
import { IGalleryConfig } from 'components/Catalog/interfaces/IGalleryConfig';

export function setSelectedProduct(payload: { product: IProduct; galleryConfig: IGalleryConfig }) {
    return (dispatch: Dispatch) => {
        return dispatch({
            type: dispatchType.PRODUCT_SET,
            payload: {
                ...payload,
            },
        });
    };
}
