import { RefObject } from 'react';
import { IGtmDataContext } from 'components/SeoSuite/interfaces/IGtmDataContext';

export default async function getTagManagerContext(
    htmlElement: RefObject<HTMLElement>,
    localDataContext?: IGtmDataContext,
): Promise<IGtmDataContext> {
    if (localDataContext) {
        console.log({
            'got local context, returning': localDataContext,
        });
        return localDataContext;
    }

    const context = {};

    if (htmlElement.current) {
        const parent = htmlElement.current.closest('[data-gtm-context]');

        if (parent) {
            try {
                const gtmContext = parent.getAttribute('data-gtm-context');

                if (gtmContext) {
                    await Object.assign(context, JSON.parse(gtmContext));
                }
            } catch (e) {
                console.error('Failed to decode gtm context', e);
            }
        }
    }

    return context;
}
