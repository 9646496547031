import { IVariantProductState } from 'components/Catalog/store/variantProductSelector';
import { cloneDeep } from 'lodash';
import { IProduct } from 'components/Catalog/interfaces/IProduct';
import { IGalleryConfig } from 'components/Catalog/interfaces/IGalleryConfig';

const initialState: IVariantProductState = {
    variantProduct: {},
};

export enum dispatchType {
    PRODUCT_SET = 'PRODUCT_SET',
}

interface IVariantProductAction {
    type: dispatchType;
    payload: any;
}

const variantProductReducer = (
    state: IVariantProductState = cloneDeep(initialState),
    action: IVariantProductAction,
): IVariantProductState => {
    const handleSetProduct = (payload: { product: IProduct; galleryConfig: IGalleryConfig }) => {
        return {
            ...state,
            variantProduct: {
                product: payload.product,
                galleryConfig: payload.galleryConfig,
            },
        };
    };

    switch (action.type) {
        case dispatchType.PRODUCT_SET: {
            return handleSetProduct(action.payload);
        }

        default: {
            return state;
        }
    }
};

export default variantProductReducer;
