import React from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import GoogleLoginButton from 'components/SocialLogin/GoogleLoginButton';

interface IProps {
    config: {
        clientId: string;
        clientSecret: string;
    };
}

const GoogleLoginButtonProvider = (props: IProps) => {
    const { config } = props;

    return (
        <GoogleOAuthProvider clientId={config.clientId}>
            <GoogleLoginButton config={config} />
        </GoogleOAuthProvider>
    );
};

export default GoogleLoginButtonProvider;
