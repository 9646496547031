import React from 'react';
import { IAutocompleteItem } from 'components/CatalogSearch/requests/AutocompleteRequest';

const ProductAttribute = (props: IAutocompleteItem) => {
    return (
        <li className="search__results__result">
            <a href={props.url}>
                <div className="text">
                    <div className="name">{props.title}</div>
                    <div className="addon">{props.label}</div>
                </div>
            </a>
        </li>
    );
};

export default ProductAttribute;
