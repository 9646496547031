import React, { useCallback, useEffect, useState } from 'react';
import { intersection, findKey } from 'lodash';
import AttributeRenderer from 'components/Catalog/Product/Configurator/AttributeRenderer';
import {
    IAttribute,
    IAttributeOption,
    IOptionsConfig,
    ISwatchConfig,
} from 'components/Catalog/interfaces/IProductConfigurator';

interface IProps {
    optionsConfig: IOptionsConfig;
    swatchConfig: ISwatchConfig;
    swatchPlaceholder: string;
    onProductSelect: (productId: string, selectedOptions: ISelectedOption) => void;
}

export interface ISelectedOption {
    [key: number]: IAttributeOption;
}

const ProductConfigurator = (props: IProps) => {
    const { optionsConfig, swatchConfig, swatchPlaceholder, onProductSelect } = props;
    const [attributes, setAttributes] = useState<IAttribute[]>([]);
    const [selectedOptions, setSelectedOptions] = useState<ISelectedOption>({});

    const onOptionSelect = useCallback(
        (attribute: IAttribute, option: IAttributeOption) => {
            setSelectedOptions((currentValue) => {
                return { ...currentValue, [attribute.id]: option };
            });
        },
        [selectedOptions],
    );

    const getAvailableProducts = (skipAttributeId?: string) => {
        let products: string[] = [];

        Object.keys(selectedOptions).forEach((attributeId) => {
            if (skipAttributeId !== undefined && attributeId === skipAttributeId) {
                return; // continue
            }

            if (products.length === 0) {
                products = selectedOptions[attributeId].products;
            } else {
                products = intersection(products, selectedOptions[attributeId].products);
            }
        });

        return products;
    };

    useEffect(() => {
        setAttributes(Object.values(optionsConfig.attributes));
    }, [optionsConfig]);

    useEffect(() => {
        if (attributes.length > 0 && attributes.length === Object.keys(selectedOptions).length) {
            const productFilters = {};

            Object.keys(selectedOptions).forEach((attributeId) => {
                const option = selectedOptions[attributeId];

                productFilters[attributeId] = option.id;
            });

            const productId = findKey(optionsConfig.index, productFilters);

            if (productId) {
                onProductSelect(productId, selectedOptions);
            }
        }
    }, [selectedOptions]);

    return (
        <div className="product-configurator">
            <div className="product-configurator__param wide">
                {attributes.map((attribute) => (
                    <AttributeRenderer
                        key={attribute.id}
                        attribute={attribute}
                        swatchPlaceholder={swatchPlaceholder}
                        swatchOptions={swatchConfig[attribute.id]}
                        availableProducts={getAvailableProducts(attribute.id)}
                        onOptionSelect={onOptionSelect}
                    />
                ))}
            </div>
        </div>
    );
};

export default ProductConfigurator;
