import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { IStoreState } from '../../../helpers/rootReducer';
import { IProduct } from 'components/Catalog/interfaces/IProduct';
import { IOptionsConfig, ISwatchConfig } from 'components/Catalog/interfaces/IProductConfigurator';
import PriceRenderer from 'components/Catalog/Product/Price/PriceRenderer';
import ReviewSummary from 'components/Catalog/Product/List/BoxProduct/ReviewSummary';
import ProductConfigurator, { ISelectedOption } from 'components/Catalog/Product/ProductConfigurator';
import ProductVariantResolver, {
    IVariantProductInformation,
} from 'components/Catalog/Product/Configurator/ProductVariantResolver';
import ProductActions from 'components/Catalog/Product/View/ProductActions';
import MessageManager, { IMessage } from 'components/ui/MessageManager';
import useAuth from '../../../hooks/useAuth';
import getTagManagerContext from 'components/SeoSuite/helpers/tagManagerContext';
import ProductCampaign from 'andbeauty-ui/lib/Applications/eBeauty/Components/ProductCampaign';
import RenderHTML from 'andbeauty-ui/lib/Components/RenderHTML';

interface IProps {
    config: {
        product: IProduct;
        ajaxUrl: string;
        swatchPlaceholder: string;
        optionsConfig?: IOptionsConfig;
        swatchConfig?: ISwatchConfig;
        labels: {
            close: string;
            outOfStock: string;
            startingFrom: string;
            addToCart: string;
            addToCartSuccess: string;
            addToCartFailed: string;
            lessLabel: string;
            moreLabel: string;
            qtyLabel: string;
        };
        bannerData?: {
            bannerUrl?: string;
            bannerText: string;
        };
    };
}

const ProductView = (props: IProps) => {
    const { config } = props;
    const { product, ajaxUrl, optionsConfig, swatchConfig, swatchPlaceholder, labels, bannerData } = config;

    const productViewElement = useRef(null);
    const { customer, hasAuthStatus } = useAuth();
    const [variantProductInformation, setVariantProductInformation] = useState<IVariantProductInformation>();
    const [messages, setMessages] = useState<IMessage[]>([]);
    const [priceData, setPriceData] = useState({
        price: product.price,
        specialPrice: product.specialPrice,
        minimalPrice: product.minimalPrice,
        pricePrefix: product.typeId === 'configurable' ? labels.startingFrom : undefined,
        unitPriceData: product.unitPriceData,
    });
    const [stockData, setStockData] = useState({
        isSaleable: product.isSaleable,
    });
    const { variantProduct } = useSelector((state: IStoreState) => state.variantProductReducer);

    const onAddToCart = (result: { qty: number }) => {
        setMessages([
            {
                intent: 'success',
                content: labels.addToCartSuccess,
                closable: true,
                hideIcon: true,
            },
        ]);

        const tagManagerContext = getTagManagerContext(productViewElement);

        tagManagerContext.then((context) => {
            window.dispatchEvent(
                new CustomEvent('cartUpdated', {
                    detail: {
                        action: 'add-to-cart',
                        userId: customer?.id,
                        sku: product.sku,
                        name: product.name,
                        price: product.price,
                        qty: result.qty,
                        ...context,
                    },
                }),
            );
        });
    };

    const onError = (error: string) => {
        setMessages([
            {
                intent: 'danger',
                content: error,
                closable: true,
                hideIcon: true,
            },
        ]);
    };

    const onProductSelect = (productId: string, selectedOptions: ISelectedOption) => {
        setVariantProductInformation({
            productId,
            selectedOptions,
        });
    };

    useEffect(() => {
        if (variantProduct.product) {
            const variant = variantProduct.product;

            setPriceData({
                price: variant.price,
                specialPrice: variant.specialPrice,
                minimalPrice: undefined,
                pricePrefix: undefined,
                unitPriceData: variant.unitPriceData,
            });
            setStockData({
                isSaleable: variant.isSaleable,
            });
        }
    }, [variantProduct]);

    useEffect(() => {
        if (!stockData.isSaleable) {
            setMessages([
                {
                    intent: 'warn',
                    content: labels.outOfStock,
                    closable: true,
                    hideIcon: true,
                },
            ]);
        } else {
            setMessages([]);
        }
    }, [stockData]);

    useEffect(() => {
        if (hasAuthStatus) {
            window.dispatchEvent(
                new CustomEvent('view-item', {
                    detail: {
                        userId: customer?.id,
                        item: {
                            sku: product.sku,
                            name: product.name,
                            price: product.specialPrice ?? product.price,
                        },
                    },
                }),
            );
        }
    }, [hasAuthStatus]);

    return (
        <React.Fragment>
            <div ref={productViewElement} />
            <ReviewSummary rating={product.rating ?? 0} />
            {optionsConfig && swatchConfig && (
                <ProductConfigurator
                    optionsConfig={optionsConfig}
                    swatchConfig={swatchConfig}
                    swatchPlaceholder={swatchPlaceholder}
                    onProductSelect={onProductSelect}
                />
            )}
            <PriceRenderer
                price={priceData.price}
                specialPrice={priceData.specialPrice}
                minimalPrice={priceData.minimalPrice}
                pricePrefix={priceData.pricePrefix}
                unitPriceData={priceData.unitPriceData}
            />

            <MessageManager messages={messages} labels={{ close: labels.close }} />
            {stockData.isSaleable && (
                <ProductActions
                    product={product}
                    onAddToCart={onAddToCart}
                    onError={onError}
                    variantProductInformation={variantProductInformation}
                    labels={labels}
                />
            )}
            {bannerData && (
                <ProductCampaign img={bannerData.bannerUrl}>
                    <RenderHTML html={bannerData.bannerText} nowrapper />
                </ProductCampaign>
            )}
            {variantProductInformation && (
                <ProductVariantResolver ajaxUrl={ajaxUrl} variantProductInformation={variantProductInformation} />
            )}
        </React.Fragment>
    );
};

export default ProductView;
