import { request } from 'data/requests/request';
import { QueryConfig } from 'redux-query';

const AddressRequest = (url: string, value: string): QueryConfig =>
    request({
        type: 'inAdsRequest',
        url: url.replace('{value}', value),
        absolute: true,
        clearJwtToken: true,
        clearContentType: true,
        clearRequestedWith: true,
    });

export default AddressRequest;
