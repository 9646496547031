import React from 'react';
import { IProduct } from 'components/Catalog/interfaces/IProduct';
import BoxProduct from 'components/Catalog/Product/List/BoxProduct';
import { IProductListLabels } from 'components/Catalog/Product/ProductList';

interface IProps {
    config: {
        products: IProduct[];
        labels: {
            recommendedLabel: string;
        } & IProductListLabels;
    };
}

const RelatedProducts = (props: IProps) => {
    const { config } = props;
    const { products, labels } = config;

    return (
        <React.Fragment>
            <h2>{labels.recommendedLabel}</h2>
            <div className="layout-products">
                <div
                    className="layout-products__list light"
                    data-gtm-context={JSON.stringify({ itemListName: 'Related products', itemListId: '' })}
                >
                    {products.map((product) => (
                        <BoxProduct product={product} labels={labels} key={product.sku} />
                    ))}
                </div>
            </div>
        </React.Fragment>
    );
};

export default RelatedProducts;
