import React, { useCallback, useRef } from 'react';
import { useMutation } from 'redux-query-react';
import Toaster from 'andbeauty-ui/lib/Components/Toaster';
import { ICartItem } from 'components/Checkout/requests/CartSummaryRequest';
import PriceRenderer from 'components/Catalog/Product/Price/PriceRenderer';
import { formatPriceLocale } from '../../../../helpers/priceUtils';
import useCartSummary from '../../../../hooks/useCartSummary';
import Img from 'components/Image/Img';
import ControlQuantity from 'components/GeneralForm/Field/ControlQuantity';
import UpdateProductRequest from 'components/Checkout/requests/UpdateProductRequest';
import RemoveProductRequest from 'components/Checkout/requests/RemoveProductRequest';
import SystemNotifications from 'andbeauty-ui/lib/Applications/eBeauty/Components/SystemNotifications';
import SystemNotification from 'andbeauty-ui/lib/Applications/eBeauty/Components/SystemNotification';
import RenderHTML from 'andbeauty-ui/lib/Components/RenderHTML';
import convertTypeToIntent from 'components/Checkout/helpers/cartItemMessageTypeConverter';
import getTagManagerContext from 'components/SeoSuite/helpers/tagManagerContext';
import useAuth from '../../../../hooks/useAuth';
import { IGtmDataContext } from 'components/SeoSuite/interfaces/IGtmDataContext';

interface IProps {
    item: ICartItem;
    gtmDataContext?: IGtmDataContext;
    labels: {
        subTotal: string;
        removeItem: string;
        lessLabel: string;
        moreLabel: string;
        qtyLabel: string;
    };
}

const Item = (props: IProps) => {
    const { item, labels } = props;

    const cartItemElement = useRef(null);
    const { customer } = useAuth();
    const { quoteId, addToCartUri } = useCartSummary();
    const [{}, updateProductRequest] = useMutation((url, quoteId, qty) =>
        UpdateProductRequest(url, quoteId, item, qty),
    );
    const [{}, removeProductRequest] = useMutation((url, quoteId) => RemoveProductRequest(url, quoteId, item));

    const updateProduct = useCallback(
        async (qty: number) => {
            const response = await updateProductRequest(addToCartUri, quoteId, qty);

            if (response.status === 200) {
                const tagManagerContext = getTagManagerContext(cartItemElement, props.gtmDataContext);

                tagManagerContext.then((context) => {
                    window.dispatchEvent(
                        new CustomEvent('cartUpdated', {
                            detail: {
                                action: qty > item.qty ? 'add-to-cart' : 'remove-from-cart',
                                userId: customer?.id,
                                sku: item.sku,
                                name: item.name,
                                price: item.price,
                                qty,
                                ...context,
                            },
                        }),
                    );
                });
            } else {
                if (response.body.message) {
                    Toaster.addToast({
                        intent: 'danger',
                        text: response.body.message,
                        asHtml: true,
                    });
                }
            }
        },
        [quoteId],
    );

    const removeProduct = useCallback(async () => {
        const response = await removeProductRequest(addToCartUri, quoteId);

        if (response.status === 200) {
            const tagManagerContext = getTagManagerContext(cartItemElement);

            tagManagerContext.then((context) => {
                window.dispatchEvent(
                    new CustomEvent('cartUpdated', {
                        detail: {
                            action: 'remove-from-cart',
                            userId: customer?.id,
                            sku: item.sku,
                            name: item.name,
                            price: item.price,
                            qty: item.qty,
                            ...context,
                        },
                    }),
                );
            });
        } else {
            if (response.body.message) {
                Toaster.addToast({
                    intent: 'danger',
                    text: response.body.message,
                    asHtml: true,
                });
            }
        }
    }, [quoteId]);

    const onQtyChange = (newQty: number) => {
        if (newQty === 0) {
            removeProduct();
        } else {
            updateProduct(newQty);
        }
    };

    return (
        <div className="cart-products__item" ref={cartItemElement}>
            <div className="box-product">
                <div className="box-product__main">
                    <a href={item.url} className="box-product__link">
                        {item.name}
                    </a>
                    <div className="box-product__image">
                        <span style={{ backgroundImage: `url('${item.image}')` }} />
                    </div>
                    <div className="box-product__content">
                        <div className="box-product__text">
                            {item.brand && <div className="box-product__brand">{item.brand}</div>}
                            <div className="box-product__title">
                                {item.name}{' '}
                                {item.discountPercent && (
                                    <span className="tag success">{`-${item.discountPercent}%`}</span>
                                )}
                            </div>
                            {item.options && (
                                <div className="box-product__description">
                                    {item.options.map((option) => (
                                        <p className="box-product__description-primary" key={option.option_id}>
                                            {option.label}: {option.value}
                                        </p>
                                    ))}
                                </div>
                            )}
                            {!!item.messages.length && (
                                <SystemNotifications theme="full" inline compact>
                                    {item.messages.map((message, i) => (
                                        <SystemNotification intent={convertTypeToIntent(message.type)} key={i}>
                                            <RenderHTML html={message.text} nowrapper />
                                        </SystemNotification>
                                    ))}
                                </SystemNotifications>
                            )}
                        </div>
                    </div>
                </div>
                <div className="box-product__actions">
                    <div className="box-product__actions-group">
                        <div className="box-product__prices">
                            <PriceRenderer price={item.price} specialPrice={item.specialPrice} />
                        </div>
                        <div className="box-product__quantity">
                            <ControlQuantity
                                labels={labels}
                                qty={item.qty}
                                onChange={onQtyChange}
                                disabled={item.isDisableQtyChange}
                            />
                        </div>
                    </div>
                    <div className="box-product__actions-group">
                        <div className="box-product__total">
                            <p>
                                {labels.subTotal}:
                                <span className="box-product__total-prices">
                                    {item.rowTotalSpecial && (
                                        <React.Fragment>
                                            <span className="discount">{formatPriceLocale(item.rowTotal)}</span>
                                            <span className="text-accented">
                                                {formatPriceLocale(item.rowTotalSpecial)}
                                            </span>
                                        </React.Fragment>
                                    )}
                                    {!item.rowTotalSpecial && <span>{formatPriceLocale(item.rowTotal)}</span>}
                                </span>
                            </p>
                        </div>
                        <button className="box-product__remove" onClick={removeProduct}>
                            <Img iconName="icon_trash" alt={labels.removeItem} />
                            {labels.removeItem}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Item;
