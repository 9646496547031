import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeOverlay } from 'data/overlays/overlayHandler';
import Overlay from 'andbeauty-ui/lib/Components/Overlay';
import { includes } from 'lodash';
import Icon from 'andbeauty-ui/lib/Components/Icon/index';
import * as Yup from 'yup';
import { useMutation } from 'redux-query-react';
import FormList from 'andbeauty-ui/lib/Applications/eBeauty/Components/FormList';
import FormListItem from 'andbeauty-ui/lib/Applications/eBeauty/Components/FormListItem';
import ControlInput from 'andbeauty-ui/lib/Components/ControlInput';
import ControlPassword from 'components/GeneralForm/Field/ControlPassword';
import Buttons from 'andbeauty-ui/lib/Components/Buttons';
import Button from 'andbeauty-ui/lib/Components/Button';
import { useFormik } from 'formik';
import loginRequest from 'components/Customer/requests/LoginRequest';
import { IStoreState } from '../../../helpers/rootReducer';

export interface IProps {
    config: {
        formConfig: {
            loginUlr: string;
            forgotPasswordUrl: string;
            registerUrl: string;
        };
        additionalLoginMethods: Array<{ [key: string]: any }>;
        labels: {
            login: string;
            orUseForLogin: string;
            registeredClient: string;
            forgotPassword: string;
            register: string;
            email: string;
            password: string;
            showPassword: string;
            invalidEmail: string;
            invalidPassword: string;
            invalidCredentials: string;
            requiredField: string;
        };
    };
    componentMapper: (maybeComponent: string, maybeContent: string, maybeConfig) => React.Component;
}

const AuthenticationPopup: React.FunctionComponent<IProps> = (props: IProps) => {
    const { config, componentMapper } = props;
    const { labels, additionalLoginMethods } = config;
    const [{}, userLoginRequest] = useMutation((email: string, password: string) => loginRequest(email, password));
    const dispatch = useDispatch();
    const { openOverlays } = useSelector((state: IStoreState) => state.overlaysReducer);

    const loginUser = (email, password) => {
        userLoginRequest(email, password).then((response) => {
            if (response.status === 400 || response.body.errors) {
                formik.setFieldError('email', labels.invalidCredentials);
            } else {
                window.location.reload();
            }
        });
    };

    const FormDataValidationSchema = Yup.object().shape({
        email: Yup.string().email(labels.invalidEmail).nullable().required(labels.requiredField),
        password: Yup.string().nullable().required(labels.requiredField),
    });

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: FormDataValidationSchema,
        validateOnBlur: true,
        validateOnChange: false,
        onSubmit: (formValues) => {
            loginUser(formValues.email, formValues.password);
        },
    });

    const closeLogin = () => {
        dispatch(closeOverlay({ name: 'all' }));
    };

    const renderAdditionalLoginMethod = (maybeComponent: string) => {
        const maybeConfig = additionalLoginMethods[maybeComponent];
        const mappedComponent = componentMapper(maybeComponent, '', maybeConfig);

        return mappedComponent ?? null;
    };

    return (
        <Overlay
            isOpen={includes(openOverlays, 'login')}
            doClose={closeLogin}
            layout="dialog"
            className="ebeauty-dialog"
            customHeader={
                <div className="ebeauty-dialog-heading">
                    <h2>{labels.login}</h2>
                    <button onClick={closeLogin}>
                        <Icon kind="close02" width={16} height={16} />
                    </button>
                </div>
            }
        >
            <div className="ebeauty-dialog-content">
                <div className="layout-grid">
                    <div className="layout-grid__column">
                        <h3>{labels.registeredClient}</h3>
                        <form className="mt20" onSubmit={formik.handleSubmit}>
                            <FormList size="wide" className="form-list">
                                <FormListItem label={labels.email} required={true} error={formik.errors.email}>
                                    <ControlInput
                                        name="email"
                                        value={formik.values.email ?? ''}
                                        onChange={formik.handleChange}
                                    />
                                </FormListItem>
                                <React.Fragment>
                                    <FormListItem
                                        label={labels.password}
                                        required={true}
                                        error={formik.errors.password}
                                    >
                                        <ControlPassword
                                            name="password"
                                            value={formik.values.password ?? ''}
                                            onChange={formik.handleChange}
                                            showPasswordButtonLabel={labels.showPassword}
                                        />
                                    </FormListItem>
                                </React.Fragment>
                            </FormList>
                            <Buttons className="mobile-inline">
                                <Button type="submit" intent="primary" title={labels.login} />
                                <Button
                                    layout="link"
                                    type="anchor"
                                    intent="discrete"
                                    title={labels.forgotPassword}
                                    href={config.formConfig.forgotPasswordUrl}
                                />
                                <Button
                                    layout="link"
                                    type="anchor"
                                    intent="discrete"
                                    title={labels.register}
                                    href={config.formConfig.registerUrl}
                                />
                            </Buttons>
                        </form>
                    </div>
                    <div className="layout-grid__column separated">
                        {!!Object.keys(additionalLoginMethods).length && (
                            <React.Fragment>
                                <h3>{labels.orUseForLogin}</h3>
                                <ul className="buttons vertical margt">
                                    {Object.keys(additionalLoginMethods).map((componentName) => (
                                        <li key={componentName}>{renderAdditionalLoginMethod(componentName)}</li>
                                    ))}
                                </ul>
                            </React.Fragment>
                        )}
                    </div>
                </div>
            </div>
        </Overlay>
    );
};

AuthenticationPopup.displayName = 'LoginModal';

export default AuthenticationPopup;
