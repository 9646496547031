import React, { useEffect, useState } from 'react';
import * as zxcvbn from 'zxcvbn';
import Img from 'components/Image/Img';
import IPasswordStrengthConfig from 'components/GeneralForm/Interfaces/IPasswordStrengthConfig';
import useScript from '../../../helpers/useScript';

export interface IControlPasswordProps {
    id?: string;
    name?: string;
    value: string;
    onChange?: (event) => void;
    showPasswordButtonLabel: string;
    passwordStrengthConfig?: IPasswordStrengthConfig;
}

declare global {
    interface Window {
        zxcvbn?: (password: string, userInputs?: string[]) => zxcvbn.ZXCVBNResult;
    }
}

export default function ControlPassword(props: IControlPasswordProps): JSX.Element {
    useScript('https://cdnjs.cloudflare.com/ajax/libs/zxcvbn/4.4.2/zxcvbn.js');

    const [isShowPassword, setIsShowPassword] = useState(false);
    const [password, setPassword] = useState('');
    const [passwordStrength, setPasswordStrength] = useState({
        score: 0,
        label: '',
        className: '',
    });

    useEffect(() => {
        if (
            !props.passwordStrengthConfig?.isEnabled ||
            !window.zxcvbn ||
            password.length < props.passwordStrengthConfig?.minLength
        ) {
            return;
        }

        const score = window.zxcvbn(password).score;
        let className = '';
        let label = '';

        if (score <= 1) {
            label = props.passwordStrengthConfig.labels.passwordStrengthWeakLabel;
            className = 'status01';
        } else if (score >= 2 && score <= 3) {
            label = props.passwordStrengthConfig.labels.passwordStrengthMediumLabel;
            className = 'status02';
        } else {
            label = props.passwordStrengthConfig.labels.passwordStrengthStrongLabel;
            className = 'status03';
        }

        setPasswordStrength((passwordStrength) => {
            return { ...passwordStrength, label, className };
        });
    }, [password]);

    return (
        <React.Fragment>
            <div className="form-control-password">
                <input
                    type={isShowPassword ? 'text' : 'password'}
                    id={props.id}
                    name={props.name}
                    value={props.value}
                    onChange={(e) => {
                        setPassword(e.target.value);

                        if (props.onChange) {
                            props.onChange(e);
                        }
                    }}
                />
                <button type="button" onClick={() => setIsShowPassword(!isShowPassword)}>
                    <Img iconName="icon_eye" alt={props.showPasswordButtonLabel} />
                    {props.showPasswordButtonLabel}
                </button>
            </div>
            {passwordStrength.className && passwordStrength.label && (
                <p className={'password-strength ' + passwordStrength.className}>
                    {props.passwordStrengthConfig?.labels.passwordStrengthLabel}:
                    <span className="bold">{passwordStrength.label}</span>
                </p>
            )}
        </React.Fragment>
    );
}
