import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import SystemNotifications from 'andbeauty-ui/lib/Applications/eBeauty/Components/SystemNotifications';
import SystemNotification from 'andbeauty-ui/lib/Applications/eBeauty/Components/SystemNotification';
import RenderHTML from 'andbeauty-ui/lib/Components/RenderHTML/index';
import { mapStateToProps } from 'components/SampleProduct/SampleProductPopup';

interface IProps {
    config: {
        giftIconPath: string;
        labels: {
            callout: string;
        };
    };
}

const SampleProductHeaderCallout = (props: IProps) => {
    const { config } = props;
    const { giftIconPath, labels } = config;
    const [calloutMsg, setCalloutMsg] = useState<string>();
    const { sampleProductsDataConfig } = useSelector(mapStateToProps);

    useEffect(() => {
        if (sampleProductsDataConfig?.availableSamplesQty) {
            setCalloutMsg(labels.callout.replace('%2', sampleProductsDataConfig.availableSamplesQty.toString()));
        }
    }, [sampleProductsDataConfig]);

    return (
        <React.Fragment>
            {sampleProductsDataConfig?.availableSamplesQty > 0 && (
                <SystemNotifications theme="full">
                    <SystemNotification iconPath={giftIconPath} iconSize="large">
                        {calloutMsg && (
                            <p>
                                <RenderHTML html={calloutMsg} nowrapper />
                            </p>
                        )}
                    </SystemNotification>
                </SystemNotifications>
            )}
        </React.Fragment>
    );
};

export default SampleProductHeaderCallout;
