import * as React from 'react';
import { IShippingMethod } from '../../interfaces/checkout/shipping/IShippingMethod';
import RenderHTML from 'andbeauty-ui/lib/Components/RenderHTML';
import { useContext, useEffect, useRef, useState } from 'react';
import { CartContext, SHIPPING_DATA_STEP, SHIPPING_METHOD_STEP } from 'components/Checkout/Shipping/ShippingContent';
import { useMutation } from 'redux-query-react';
import { postRequest } from 'data/requests/postRequest';
import { IShippingAssignment } from '../../interfaces/checkout/quote/IQuote';
import { ResponseStatus } from '../../../../enums/ResponseStatus';
import StorePickup, { IAdditionalStorePickup } from 'components/Checkout/Shipping/Methods/StorePickup';
import Courier from 'components/Checkout/Shipping/Methods/Courier';
import { useTranslation } from 'react-i18next';
import PlaceDelivery from 'components/Checkout/Shipping/Methods/PlaceDelivery';
import { empty } from '../../../../helpers/empty';
import Simple from 'components/Checkout/Shipping/Methods/Simple';
import FormRow from 'andbeauty-ui/lib/Components/FormRow/index';
import ControlTextarea from 'andbeauty-ui/lib/Components/ControlTextarea/index';
import ShipitDelivery from 'components/Checkout/Shipping/Methods/ShipitDelivery';

interface IProps {
    selectedMethod?: IShippingMethod;
    setCurrentStep: (step: number) => void;
    resetAction: () => void;
    quoteId: number | null;
    selectedAssignment: IShippingAssignment | undefined;
    currentStep: number;
}
export interface IAdditional {
    courier_info: string | undefined;
}
export interface IAdditionalComponents {
    courierInfo: {
        component: React.ReactNode;
        exportField: string | undefined;
    };
    additionalConfig?: {
        showHandoverUnderageCheckbox?: boolean;
        showHandoverContactCheckbox?: boolean;
    };
}
export enum SHIPPING_TYPES {
    PICKUP = 'pickup',
    COURIER = 'courier',
    PLACE = 'place',
    TYPE_SHIPIT = 'TYPE_SHIPIT',
    SIMPLE = 'simple',
    LP_API = 'lp_api',
}

const ShippingMethodAdditional = (props: IProps) => {
    const { selectedMethod, setCurrentStep, resetAction, quoteId, selectedAssignment, currentStep } = props;
    const { t } = useTranslation();
    const cart = useContext(CartContext);
    const isSuccessful = useRef(false);
    const allowQuery = useRef(true);
    const [courierInfo, setCourierInfo] = useState('');
    const [updateState, setUpdateState] = useState(false);
    const additionalData = useRef({});
    const [{ isFinished }, postData] = useMutation((data) =>
        postRequest({ type: 'response', url: 'shipping/additional', data }),
    );
    const [additional, setAdditional] = useState([]);
    const [continueDisabled, setContinueDisabled] = useState(currentStep !== SHIPPING_METHOD_STEP);
    const shippingAdditional: IAdditionalStorePickup =
        selectedAssignment?.shipping.extension_attributes?.additional_shipping_data &&
        JSON.parse(selectedAssignment?.shipping.extension_attributes?.additional_shipping_data);

    const setAllowQuery = (state: boolean) => {
        allowQuery.current = state;
        setUpdateState(!updateState);
    };

    const setAdditionalData = (newAdditional) => {
        if (!empty(newAdditional.shipit_agent_id)) {
            additionalData.current = {
                place_id: newAdditional.shipit_agent_id,
                place_name: newAdditional.shipit_agent_data.name,
                shippingDescription:
                    newAdditional.shipit_agent_data.name + ' ' + newAdditional.shipit_agent_data.street_address,
                courier_info: additionalComponents.courierInfo.exportField,
            };
            localStorage.setItem('extension_attributes', JSON.stringify(newAdditional));
        } else {
            localStorage.removeItem('extension_attributes');
            additionalData.current = newAdditional;
        }
    };
    const proceedAction = () => {
        if (allowQuery.current && quoteId && selectedMethod && !continueDisabled) {
            allowQuery.current = false;
            postData({
                data: {
                    quoteId,
                    additionalData: additionalData.current,
                    method: selectedMethod.methodCode,
                },
            }).then((response) => {
                isSuccessful.current = response && response.status === ResponseStatus.ok;
                setAllowQuery(true);

                window.dispatchEvent(
                    new CustomEvent('checkout-step-proceed', {
                        detail: {
                            action: 'add-shipping-info',
                            method: selectedMethod,
                            items: cart.cartItems,
                            customer: cart.customer,
                        },
                    }),
                );
            });
        }
        setDisableContinue(true);
    };

    const resetState = () => {
        isSuccessful.current = false;
        resetAction();
    };

    useEffect(() => {
        if (selectedAssignment?.shipping && empty(additional[selectedAssignment.shipping.method])) {
            additional[selectedAssignment.shipping.method] = shippingAdditional;
            setAdditional(additional);
        }
    });

    const setDisableContinue = (disable: boolean) => {
        if (continueDisabled !== disable) {
            setContinueDisabled(disable);
        }
    };

    useEffect(() => {
        if (isFinished && isSuccessful.current) {
            isSuccessful.current = false;
            setCurrentStep(SHIPPING_DATA_STEP);
        }
        setDisableContinue(currentStep !== SHIPPING_METHOD_STEP);
    });

    const courierInfoComponent = selectedMethod?.extra_data?.courier_info ? (
        <FormRow label={t('checkout.Info for courier')}>
            <ControlTextarea
                value={courierInfo}
                placeholder={t('checkout.Additional info about your address')}
                onChange={(e) => {
                    setCourierInfo(e.target.value);
                    resetAction();
                }}
                minRows={1}
                maxRows={4}
            />
        </FormRow>
    ) : (
        <React.Fragment />
    );
    const additionalComponents: IAdditionalComponents = {
        courierInfo: {
            component: courierInfoComponent,
            exportField: ((selectedMethod?.extra_data?.courier_info || false) && courierInfo) || undefined,
        },
        additionalConfig: {
            showHandoverUnderageCheckbox: selectedMethod?.extra_data?.is_show_handover_underage ?? true,
            showHandoverContactCheckbox: selectedMethod?.extra_data?.is_show_handover_contact ?? true,
        },
    };
    return (
        <React.Fragment>
            <RenderHTML nowrapper={true} html={`<p>${selectedMethod?.extra_data?.info_text || ''}</p>`} />
            {selectedMethod?.type === SHIPPING_TYPES.PICKUP && (
                <StorePickup
                    selectedMethod={selectedMethod}
                    proceedAction={proceedAction}
                    resetAction={resetState}
                    setAdditionalData={setAdditionalData}
                    shippingAdditional={additional}
                    setShippingAdditional={setAdditional}
                    continueDisabled={continueDisabled}
                    additionalComponents={additionalComponents}
                />
            )}
            {selectedMethod?.type === SHIPPING_TYPES.COURIER && (
                <Courier
                    selectedMethod={selectedMethod}
                    proceedAction={proceedAction}
                    resetAction={resetState}
                    setAdditionalData={setAdditionalData}
                    shippingAdditional={additional}
                    setShippingAdditional={setAdditional}
                    continueDisabled={continueDisabled}
                    additionalComponents={additionalComponents}
                />
            )}
            {selectedMethod?.type === SHIPPING_TYPES.PLACE && (
                <PlaceDelivery
                    key={selectedMethod.methodCode}
                    selectedMethod={selectedMethod}
                    dropDownTitle={t('Choose a Place')}
                    proceedAction={proceedAction}
                    resetAction={resetState}
                    setAdditionalData={setAdditionalData}
                    shippingAdditional={additional}
                    setShippingAdditional={setAdditional}
                    continueDisabled={continueDisabled}
                    additionalComponents={additionalComponents}
                />
            )}
            {selectedMethod?.type === SHIPPING_TYPES.TYPE_SHIPIT && (
                <ShipitDelivery
                    selectedMethod={selectedMethod}
                    resetAction={resetState}
                    dropDownTitle={t('Choose a Place')}
                    proceedAction={proceedAction}
                    setAdditionalData={setAdditionalData}
                    shippingAdditional={additional}
                    setCurrentStep={setCurrentStep}
                    setShippingAdditional={setAdditional}
                    continueDisabled={continueDisabled}
                    additionalComponents={additionalComponents}
                />
            )}
            {selectedMethod?.type === SHIPPING_TYPES.SIMPLE && (
                <Simple
                    key={selectedMethod.methodCode}
                    proceedAction={proceedAction}
                    continueDisabled={continueDisabled}
                    setAdditionalData={setAdditionalData}
                    additionalComponents={additionalComponents}
                />
            )}
        </React.Fragment>
    );
};
export default ShippingMethodAdditional;
