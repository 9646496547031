import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import Icon from 'andbeauty-ui/lib/Components/Icon';
import Overlay from 'andbeauty-ui/lib/Components/Overlay';
import { Carousel } from 'react-responsive-carousel';
import 'andbeauty-ui/lib/Components/RenderGallery/styles.scss';
import { IGalleryConfig } from 'components/Catalog/interfaces/IGalleryConfig';
import { useSelector } from 'react-redux';
import { IStoreState } from '../../../helpers/rootReducer';

interface IProps {
    config: IGalleryConfig;
}

const Gallery = (props: IProps) => {
    const { config } = props;
    let galleryImages = config.galleryImages;

    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [overlayOpen, setOverlayOpen] = useState(false);
    const { variantProduct } = useSelector((state: IStoreState) => state.variantProductReducer);

    if (variantProduct.galleryConfig) {
        galleryImages = variantProduct.galleryConfig.galleryImages;
    }

    useEffect(() => {
        setCurrentImageIndex(0);
    }, [galleryImages]);

    return (
        <React.Fragment>
            <div
                className={classNames('gallery', {
                    'has-thumbs': config.showThumbs,
                    'has-overlay': config.hasOverlay,
                })}
            >
                <Carousel
                    showThumbs={config.showThumbs}
                    showArrows={config.showArrows}
                    showIndicators={config.showIndicators}
                    autoPlay={false}
                    showStatus={false}
                    swipeable
                    emulateTouch
                    useKeyboardArrows
                    selectedItem={currentImageIndex}
                    renderArrowNext={(onClick) => {
                        if (config.showArrows) {
                            return (
                                <button onClick={onClick} className="control-arrow control-next">
                                    <Icon kind="arrow2-right" width={12} height={12} />
                                </button>
                            );
                        }
                        return <React.Fragment />;
                    }}
                    renderArrowPrev={(onClick) => {
                        if (config.showArrows) {
                            return (
                                <button onClick={onClick} className="control-arrow control-prev">
                                    <Icon kind="arrow2-left" width={12} height={12} />
                                </button>
                            );
                        }
                        return <React.Fragment />;
                    }}
                    onClickItem={(index) => {
                        setOverlayOpen(true);
                        setCurrentImageIndex(index);
                    }}
                    onClickThumb={(index) => {
                        setCurrentImageIndex(index);
                    }}
                >
                    {galleryImages.map((image, i) => (
                        <img className="gallery__slide" src={image.img} alt={image.caption} key={i} />
                    ))}
                </Carousel>
            </div>

            {config.hasOverlay && (
                <Overlay
                    className="gallery-overlay"
                    layout="focusview"
                    size="max"
                    isOpen={overlayOpen}
                    doClose={() => setOverlayOpen(false)}
                    canEscapeKeyClose
                    customHeader={
                        <button className="gallery-overlay__close" onClick={() => setOverlayOpen(false)}>
                            <Icon kind="close" width={16} height={16} />
                        </button>
                    }
                    zIndex={16000100}
                >
                    <div className="gallery-overlay__content">
                        <Carousel
                            showThumbs={config.showThumbs}
                            showArrows={config.showArrows}
                            showIndicators={config.showIndicators}
                            selectedItem={currentImageIndex}
                            autoPlay={false}
                            showStatus={false}
                            swipeable
                            emulateTouch
                            useKeyboardArrows
                            renderArrowNext={(onClick) => {
                                if (config.showArrows) {
                                    return (
                                        <button onClick={onClick} className="control-arrow control-next">
                                            <Icon kind="arrow2-right" width={12} height={12} />
                                        </button>
                                    );
                                }
                                return <React.Fragment />;
                            }}
                            renderArrowPrev={(onClick) => {
                                if (config.showArrows) {
                                    return (
                                        <button onClick={onClick} className="control-arrow control-prev">
                                            <Icon kind="arrow2-left" width={12} height={12} />
                                        </button>
                                    );
                                }
                                return <React.Fragment />;
                            }}
                        >
                            {galleryImages.map((image, i) => (
                                <img src={image.full} className="gallery__slide" alt={image.caption} key={i} />
                            ))}
                        </Carousel>
                    </div>
                </Overlay>
            )}
        </React.Fragment>
    );
};

export default Gallery;
