import { getHeaders } from '../../helpers/request/getHeaders';
import { HttpMethods, QueryConfig, RequestBody } from 'redux-query';

export interface IRequestData {
    type: string;
    url: string;
    method?: HttpMethods;
    data?: RequestBody;
    absolute?: boolean;
    notApi?: boolean;
    force?: boolean;
    clearContentType?: boolean;
    clearJwtToken?: boolean;
    clearRequestedWith?: boolean;
}

export const request = (data: IRequestData): QueryConfig => {
    const url = window.API_URL;
    const apiUri = data.notApi ? '/' : '/rest/V1/';

    const headers = getHeaders();
    if (data.clearContentType) {
        delete headers['Content-Type'];
    }

    if (data.clearJwtToken) {
        delete headers['Authorization'];
    }

    if (data.clearRequestedWith) {
        delete headers['X-Requested-With'];
    }

    return {
        url: data.absolute ? data.url : `${url}${apiUri}${data.url}`,
        options: {
            method: data.method || 'GET',
            headers,
        },
        force: data.force ?? false,
        body: data.data,
        transform: (response: any) => ({
            [`${data.type}`]: response as any,
        }),
        update: {
            [`${data.type}`]: (prev: any, next: any) => {
                return next;
            },
        },
    };
};
