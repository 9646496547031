import React from 'react';
import classNames from 'classnames';
import RenderHTML from 'andbeauty-ui/lib/Components/RenderHTML';

interface IProps {
    intent: CalloutIntent;
    content: string;
}

export enum CalloutIntent {
    DEFAULT = 'default',
    SUCCESS = 'success',
    WARNING = 'warning',
    DANGER = 'danger',
}

const classNameByIntent = (intent: string): string => {
    switch (intent) {
        case CalloutIntent.DEFAULT:
            return 'intent-promotion';
        case CalloutIntent.SUCCESS:
            return 'intent-success';
        case CalloutIntent.WARNING:
            return 'intent-warning';
        case CalloutIntent.DANGER:
            return 'intent-danger';
        default:
            return '';
    }
};

const GenericCallout = (props: IProps) => {
    const { intent, content } = props;

    return (
        <div className={classNames('generic-callout', classNameByIntent(intent))}>
            <div className="generic-callout__limiter">
                <RenderHTML html={content} nowrapper />
            </div>
        </div>
    );
};

export default GenericCallout;
