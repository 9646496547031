import * as React from 'react';
import SimplePayment from 'components/Checkout/Payment/Methods/SimplePayment';
import { IPaymentMethodProps } from 'components/Checkout/Payment/PaymentBlock';

interface IProps {
    paymentMethodProps: IPaymentMethodProps;
    method: string;
    title: string;
    disabled?: boolean;
}

const Checkmo = (props: IProps) => {
    const { method, title, disabled } = props;
    const redirect = { to: `checkout/onepage/success` };

    return (
        <SimplePayment
            method={method}
            key={method}
            onClick={() => props.paymentMethodProps.onClick({ redirect })}
            disabled={disabled}
            label={title}
            paymentMethodProps={props.paymentMethodProps}
        />
    );
};

export default Checkmo;
