import { request } from 'data/requests/request';

const ReservationExtendRequest = () =>
    request({
        type: 'quoteReservationExtendRequest',
        url: 'quotereservation/ajax/extend',
        method: 'POST',
        notApi: true,
    });

export default ReservationExtendRequest;
