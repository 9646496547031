import React from 'react';
import useCartSummary from '../../../hooks/useCartSummary';
import Item from 'components/Checkout/Cart/Items/Item';

interface IProps {
    config: {
        labels: {
            subTotal: string;
            removeItem: string;
            lessLabel: string;
            moreLabel: string;
            qtyLabel: string;
        };
    };
}

const Form = (props: IProps) => {
    const { config } = props;
    const { labels } = config;
    const { cartItems } = useCartSummary();

    return (
        <div className="cart-products">
            {cartItems.map((item) => (
                <Item key={item.sku} item={item} labels={labels} />
            ))}
        </div>
    );
};

export default Form;
