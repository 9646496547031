import * as React from 'react';
import Img from 'components/Image/Img';

interface IProps {
    msg?: string;
}

const ErrorRow = (props: IProps) => {
    const { msg } = props;

    return (
        <React.Fragment>
            {msg && (
                <p className="form-row-instructions">
                    <Img iconName={'icon_error'} alt="!" />
                    {msg}
                </p>
            )}
        </React.Fragment>
    );
};
export default ErrorRow;
