import * as React from 'react';

import Block from 'andbeauty-ui/lib/Components/Block';
import Button from 'andbeauty-ui/lib/Components/Button';
import Buttons from 'andbeauty-ui/lib/Components/Buttons';
import Overlay from 'andbeauty-ui/lib/Components/Overlay';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import useOverlays from 'components/overlay/Overlay';

export interface IProps {
    title: string;
    newAddressForm: any;
    proceedAction: any;
}

const NewAddressOverlay = (props: IProps) => {
    const { title, newAddressForm, proceedAction } = props;
    const { t } = useTranslation();
    const { overlays, closeOverlay } = useOverlays();
    const history = useHistory();
    const isOpen = overlays.indexOf('add-address') !== -1;

    const close = (e) => {
        e.preventDefault();
        history.goBack();
        closeOverlay('add-address');
    };

    return (
        <Overlay isOpen={isOpen} layout="focusview" title={title} size="small" doClose={(e) => close(e)}>
            <Block>
                {newAddressForm}
                <Buttons layout="vertical-wide">
                    <Button title={t('checkout.Ship Here')} intent="primary" onClick={(e) => proceedAction()} />
                    <Button title={t('checkout.Cancel')} intent="primary" layout="link" onClick={(e) => close(e)} />
                </Buttons>
            </Block>
        </Overlay>
    );
};
export default NewAddressOverlay;
