import React, { useCallback, useEffect, useState } from 'react';
import DataConfigRequest, {
    IQuoteReservationDataConfigResponse,
} from 'components/QuoteReservation/requests/DataConfigRequest';
import { useMutation, useRequest } from 'redux-query-react';
import ReservationExtendRequest from 'components/QuoteReservation/requests/ReservationExtendRequest';
import { useSelector } from 'react-redux';
import ConfirmationDialog from 'components/ui/Overlay/ConfirmationDialog';
import QuoteReservationTimer from 'components/QuoteReservation/QuoteReservationTimer';
import Toaster from 'andbeauty-ui/lib/Components/Toaster';

interface IProps {
    config: {
        labels: {
            callout: string;
            expireCallout: string;
            confirmExtend: string;
            cancelExtend: string;
        };
    };
}

export const mapStateToProps = (state) => {
    return {
        quoteReservationDataConfigRequest: state.entities
            .quoteReservationDataConfigRequest as IQuoteReservationDataConfigResponse,
    };
};

const QuoteReservationTimerWrapper = (props: IProps) => {
    const { config } = props;
    const { labels } = config;

    const [{ isFinished }, dataConfigRequest] = useRequest(DataConfigRequest());
    const [{}, extendRequest] = useMutation(() => ReservationExtendRequest());
    const { quoteReservationDataConfigRequest: dataConfig } = useSelector(mapStateToProps);
    const reloadDataConfig = () => dataConfigRequest();

    const [extendAvailable, setExtendAvailable] = useState(false);
    const [extendRefused, setExtendRefused] = useState(false);
    const effect = useCallback(() => reloadDataConfig(), [reloadDataConfig]);

    const onTimeChange = (timeRemaining: number) => {
        if (dataConfig.extendEnabled && timeRemaining < dataConfig.extendThreshold) {
            setExtendAvailable(true);
        }

        if (timeRemaining === 0) {
            window.location.reload();
        }
    };

    const onExtendConfirm = async () => {
        const response = await extendRequest();
        const intent = response.status === 200 ? 'success' : 'danger';
        const message = response.body.message;

        Toaster.addToast({
            intent: intent,
            text: message,
        });

        setExtendAvailable(false);
        await reloadDataConfig();
    };

    const onExtendCancel = () => {
        setExtendRefused(true);
    };

    useEffect(() => {
        window.addEventListener('cartUpdated', effect);

        return function cleanup() {
            window.removeEventListener('cartUpdated', effect);
        };
    }, []);

    return (
        <React.Fragment>
            {isFinished && dataConfig?.timeRemaining && (
                <QuoteReservationTimer
                    timeRemaining={dataConfig.timeRemaining}
                    callout={labels.callout}
                    onTimeChange={onTimeChange}
                />
            )}
            {extendAvailable && !extendRefused && (
                <ConfirmationDialog
                    content={labels.expireCallout}
                    confirmButtonLabel={labels.confirmExtend}
                    cancelButtonLabel={labels.cancelExtend}
                    onConfirm={onExtendConfirm}
                    onCancel={onExtendCancel}
                />
            )}
        </React.Fragment>
    );
};

export default QuoteReservationTimerWrapper;
