import * as React from 'react';
import { useRequest } from 'redux-query-react';
import { useSelector } from 'react-redux';
import activeReviewRequest from 'components/Review/requests/activeReviewRequest';
import { useState } from 'react';
import Buttons from 'andbeauty-ui/lib/Components/Buttons';
import Button from 'andbeauty-ui/lib/Components/Button';
import Img from 'components/Image/Img';
import TextTruncate from 'components/Typography/TextTruncate';
import { format, parse } from 'date-fns';

export interface IListLabels {
    addReviewLabel: string;
    noReviewsLabel: string;
    reviewsLabel: string;
    readMoreLabel: string;
    readLessLabel: string;
    loadMoreLabel: string;
    showLessLabel: string;
    postedAt: string;
}

interface IReviewResponse {
    nickname: string;
    title: string;
    detail: string;
    created_at: string;
    score: number;
}

interface IProps {
    url: string;
    labels: IListLabels;
    isLoggedIn: boolean;
    setPostReviewMode: (setToReviewMode: boolean) => void;
}

const mapStateToProps = (state) => {
    return {
        reviews: state.entities.reviews as IReviewResponse[],
    };
};
const ReviewList = (props: IProps) => {
    const { url, labels, isLoggedIn, setPostReviewMode } = props;

    const [{}] = useRequest(activeReviewRequest(url));
    const [loadMore, setLoadMore] = useState(false);
    const { reviews } = useSelector(mapStateToProps);

    return (
        <React.Fragment>
            {reviews && (
                <React.Fragment>
                    {reviews.length === 0 && <h3>{labels.noReviewsLabel}</h3>}
                    {reviews.length > 0 && (
                        <h3>
                            {labels.reviewsLabel}
                            <span className="text-intent-danger"> ({reviews.length})</span>
                        </h3>
                    )}
                    {isLoggedIn && (
                        <Buttons>
                            <Button onClick={() => setPostReviewMode(true)} title={labels.addReviewLabel} />
                        </Buttons>
                    )}
                    {reviews.map((review, key) => {
                        return (
                            <React.Fragment key={key}>
                                {(loadMore || key < 5) && (
                                    <div className="product-reviews__item">
                                        <h4>{review.title}</h4>
                                        <div
                                            className={`product-reviews__score product-reviews__score-${review.score}`}
                                        >
                                            <span>
                                                <Img iconName={'sprite_rating'} alt={`${review.score ?? 0}/5`} />
                                            </span>
                                            {`${review.score}/5`}
                                        </div>
                                        <div className="product-reviews__comment">
                                            <div className="product-reviews__author">
                                                <p>{review.nickname}</p>
                                                <p className="product-reviews__meta">
                                                    {labels.postedAt} {review.created_at}
                                                </p>
                                            </div>
                                            <div className="product-reviews__text">
                                                <TextTruncate
                                                    config={{
                                                        fullText: review.detail,
                                                        contentLength: 256,
                                                        filteredText: review.detail.substr(0, 256),
                                                        labels: {
                                                            readMoreLabel: labels.readMoreLabel,
                                                            readLessLabel: labels.readLessLabel,
                                                        },
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </React.Fragment>
                        );
                    })}
                    {reviews.length > 5 && (
                        <Buttons>
                            <Button
                                title={loadMore ? labels.showLessLabel : labels.loadMoreLabel}
                                type={'submit'}
                                onClick={() => setLoadMore(!loadMore)}
                                intent={'secondary'}
                            />
                        </Buttons>
                    )}
                </React.Fragment>
            )}
        </React.Fragment>
    );
};
export default ReviewList;
