import { ILoginResponse } from 'components/SocialLogin/requests/LoginRequest';

export function handleLoginResponse(response: ILoginResponse) {
    if (!response.error) {
        if (response.action === 'register') {
            window.location.href = '/customer/account/create';
        } else {
            window.location.reload();
        }
    }
}
