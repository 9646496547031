import React, { useRef, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import validatePasswordMinCharacterSets from '../../../helpers/passwordValidator';
import IRegisterForm from 'components/Customer/interfaces/IRegisterForm';
import FormList from 'andbeauty-ui/lib/Applications/eBeauty/Components/FormList/index';
import FormListItem from 'andbeauty-ui/lib/Applications/eBeauty/Components/FormListItem/index';
import ControlInput from 'andbeauty-ui/lib/Components/ControlInput/index';
import CheckboxList from 'andbeauty-ui/lib/Applications/eBeauty/Components/CheckboxList/index';
import ControlPassword from 'components/GeneralForm/Field/ControlPassword';
import Buttons from 'andbeauty-ui/lib/Components/Buttons/index';
import Button from 'andbeauty-ui/lib/Components/Button/index';
import CheckboxWithLabel from 'andbeauty-ui/lib/Components/CheckboxWithLabel/index';
import FormKey from 'components/GeneralForm/Field/FormKey';
import { getCaptchaToken } from 'components/GoogleRecaptcha/Index';
import RenderHTML from 'andbeauty-ui/lib/Components/RenderHTML/index';

interface IProps {
    config: IRegisterForm;
}

const CustomerRegisterForm: React.FunctionComponent<IProps> = (props) => {
    const { config } = props;
    const { passwordConfig } = config;
    const formElement = useRef<HTMLFormElement>(null);
    const [captchaToken, setCaptchaToken] = useState('');
    const FormDataValidationSchema = {
        firstname: Yup.string().nullable().required(config.labels.requiredField),
        lastname: Yup.string().nullable().required(config.labels.requiredField),
        email: Yup.string()
            .nullable()
            .email(config.emailConfig.emailMustBeValidErrorMessage)
            .required(config.labels.requiredField),
        agreedTerms: Yup.bool().oneOf([true], config.labels.requiredField),
    };

    if (passwordConfig.isEnabled) {
        Object.assign(FormDataValidationSchema, {
            password: Yup.string()
                .nullable()
                .required(config.labels.requiredField)
                .min(config.passwordConfig.minLength, passwordConfig.labels.minLengthLabel)
                .test('password-min-character-sets', passwordConfig.labels.minCharacterSetLabel, (value) =>
                    value ? validatePasswordMinCharacterSets(value, passwordConfig.requiredCharacterClasses) : true,
                ),
            password_confirmation: Yup.string()
                .nullable()
                .required(config.labels.requiredField)
                .when('password', {
                    is: (password: string | undefined) => (password ? password.length > 0 : false),
                    then: Yup.string().nullable().required(config.labels.requiredField),
                })
                .oneOf([Yup.ref('password'), null], passwordConfig.labels.passwordMatchLabel),
        });
    }

    const formik = useFormik({
        initialValues: config.formData,
        validationSchema: Yup.object().shape(FormDataValidationSchema),
        validateOnChange: false,
        validateOnBlur: true,
        onSubmit: () => {
            void getCaptchaToken().then((token) => {
                setCaptchaToken(token);

                if (formElement.current) {
                    formElement.current.submit();
                }
            });
        },
    });

    return (
        <form ref={formElement} action={config.formConfig.actionUrl} method="post" onSubmit={formik.handleSubmit}>
            <FormKey />
            <input type="hidden" name="success_url" value={config.formConfig.successUrl ?? ''} />
            <input type="hidden" name="error_url" value={config.formConfig.errorUrl ?? ''} />
            <input type="hidden" name="g-recaptcha-response" value={captchaToken} />
            <FormList size="wide" className="margt">
                <FormListItem label={config.labels.firstNameLabel} required={true} error={formik.errors.firstname}>
                    <ControlInput
                        name="firstname"
                        value={formik.values.firstname ?? ''}
                        onChange={formik.handleChange}
                        readonly={config.nameConfig.isReadOnly}
                    />
                </FormListItem>
                <FormListItem label={config.labels.lastNameLabel} required={true} error={formik.errors.lastname}>
                    <ControlInput
                        name="lastname"
                        value={formik.values.lastname ?? ''}
                        onChange={formik.handleChange}
                        readonly={config.nameConfig.isReadOnly}
                    />
                </FormListItem>
                <FormListItem label={config.labels.emailLabel} required={true} error={formik.errors.email}>
                    <ControlInput name="email" value={formik.values.email ?? ''} onChange={formik.handleChange} />
                </FormListItem>
                {config.passwordConfig.isEnabled && (
                    <React.Fragment>
                        <FormListItem
                            label={passwordConfig.labels.passwordLabel}
                            required={true}
                            error={formik.errors.password}
                        >
                            <ControlPassword
                                name="password"
                                value={formik.values.password ?? ''}
                                onChange={formik.handleChange}
                                showPasswordButtonLabel={passwordConfig.labels.showPasswordLabel}
                                passwordStrengthConfig={config.passwordStrengthConfig}
                            />
                        </FormListItem>
                        <FormListItem
                            label={passwordConfig.labels.confirmPasswordLabel}
                            required={true}
                            error={formik.errors.password_confirmation}
                        >
                            <ControlPassword
                                name="password_confirmation"
                                value={formik.values.password_confirmation ?? ''}
                                onChange={formik.handleChange}
                                showPasswordButtonLabel={passwordConfig.labels.showPasswordLabel}
                            />
                        </FormListItem>
                    </React.Fragment>
                )}

                <React.Fragment>
                    <FormListItem
                        label={
                            <CheckboxList>
                                <CheckboxWithLabel
                                    label={config.labels.newsletterSignup}
                                    checked={formik.values.isSubscribed}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        formik.setFieldValue('isSubscribed', e.target.checked)
                                    }
                                />
                            </CheckboxList>
                        }
                    />
                    <input type="hidden" name="is_subscribed" value={formik.values.isSubscribed ? 1 : 0} />
                </React.Fragment>

                <React.Fragment>
                    <FormListItem
                        label={
                            <CheckboxList>
                                <CheckboxWithLabel
                                    label={
                                        <React.Fragment>
                                            <span className="text-intent-danger">*</span>
                                            <RenderHTML html={config.labels.termsAndConditions} nowrapper={true} />
                                        </React.Fragment>
                                    }
                                    checked={formik.values.agreedTerms}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        formik.setFieldValue('agreedTerms', e.target.checked)
                                    }
                                />
                            </CheckboxList>
                        }
                        error={formik.errors.agreedTerms}
                    />
                    <input type="hidden" name="terms" value={formik.values.agreedTerms ? 1 : 0} />
                </React.Fragment>
            </FormList>

            <Buttons layout="vertical-wide">
                <Button type="submit" intent="primary" title={config.labels.formSubmitLabel} />
                <Button
                    type="anchor"
                    intent="discrete"
                    title={config.labels.backLabel}
                    onClick={(e) => {
                        history.back();
                    }}
                />
            </Buttons>
        </form>
    );
};

export default CustomerRegisterForm;
