import { request } from 'data/requests/request';
import { AccountIdentifier } from 'components/SocialLogin/EnumAccountIdentifier';

const disconnectAccountRequest = (account: AccountIdentifier) =>
    request({
        type: 'socialAccountDisconnect',
        url: 'customer/ajax/socialdetach',
        method: 'POST',
        data: { account },
        notApi: true,
        absolute: false,
        force: true,
    });

export default disconnectAccountRequest;
