import React, { useState } from 'react';
import CheckboxList from 'andbeauty-ui/lib/Applications/eBeauty/Components/CheckboxList/index';
import CheckboxWithLabel from 'andbeauty-ui/lib/Components/CheckboxWithLabel/index';
import FormListItem from 'andbeauty-ui/lib/Applications/eBeauty/Components/FormListItem/index';

interface IProps {
    config: INewsletterForm;
}

export interface INewsletterForm {
    isNewsletterEnabled: boolean;
    generalSubscription: boolean;
    labels: {
        newslettersLabel: string;
        generalSubscriptionLabel: string;
    };
}

const Newsletter = (props: IProps): JSX.Element => {
    const { config } = props;
    const { labels } = config;

    const [generalSubscription, setGeneralSubscription] = useState(config.generalSubscription);

    return (
        <FormListItem label={labels.newslettersLabel} isFieldSet={true} isCheckboxes={true} isSeparated={true}>
            <CheckboxList>
                <React.Fragment>
                    <input type="hidden" name="is_subscribed" value={generalSubscription ? 1 : 0} />
                    <CheckboxWithLabel
                        label={labels.generalSubscriptionLabel}
                        checked={generalSubscription}
                        onChange={() => setGeneralSubscription(!generalSubscription)}
                    />
                </React.Fragment>
            </CheckboxList>
        </FormListItem>
    );
};

export default Newsletter;
