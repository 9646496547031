import * as React from 'react';
import ReviewForm, { IFormLabels, IRating } from 'components/Review/ReviewForm';
import ReviewList, { IListLabels } from 'components/Review/ReviewList';
import useAuth from '../../hooks/useAuth';
import { useState } from 'react';

interface IProps {
    config: {
        listUrl: string;
        listLabels: IListLabels;
        formUrl: string;
        formLabels: IFormLabels;
        ratings: IRating[];
    };
}

const Review = (props: IProps) => {
    const { config } = props;
    const { isLoggedIn } = useAuth();
    const [postReviewMode, setPostReviewMode] = useState<boolean>(false);

    return (
        <React.Fragment>
            {!postReviewMode && (
                <ReviewList
                    url={config.listUrl}
                    labels={config.listLabels}
                    isLoggedIn={isLoggedIn}
                    setPostReviewMode={setPostReviewMode}
                />
            )}
            {isLoggedIn && postReviewMode && (
                <ReviewForm url={config.formUrl} labels={config.formLabels} ratings={config.ratings} />
            )}
        </React.Fragment>
    );
};
export default Review;
