import React, { useState } from 'react';
import Overlay from 'andbeauty-ui/lib/Components/Overlay';
import RenderHTML from 'andbeauty-ui/lib/Components/RenderHTML';
import Icon from 'andbeauty-ui/lib/Components/Icon';

interface IProps {
    dom: string;
    config: {
        instanceId: number;
    };
}

const CtaPopup = (props: IProps) => {
    const { config } = props;
    const cacheKey = `cta-popup-${config.instanceId}`;
    const [visible, setVisible] = useState(!window.sessionStorage.getItem(cacheKey));

    const handleCloseOverlay = () => {
        setVisible(false);
        window.sessionStorage.setItem(cacheKey, 'true');
    };

    return (
        <Overlay
            isOpen={visible}
            canOutsideClickClose={true}
            doClose={handleCloseOverlay}
            layout="dialog"
            className="ebeauty-dialog ebeauty-dialog-fullsize"
            customHeader={
                <div className="ebeauty-dialog-heading">
                    <button onClick={handleCloseOverlay}>
                        <Icon kind="close02" width={16} height={16} />
                    </button>
                </div>
            }
        >
            <RenderHTML html={props.dom} nowrapper />
        </Overlay>
    );
};
export default CtaPopup;
