import * as React from 'react';
import SimplePayment from 'components/Checkout/Payment/Methods/SimplePayment';
import { IPaymentMethodProps } from 'components/Checkout/Payment/PaymentBlock';
import { useMutation } from 'redux-query-react';
import { IPaymentMethodResponse } from '../../interfaces/payment/IPaymentMethodResponse';
import { postRequest } from 'data/requests/postRequest';
import isLoggedIn from '../../../../helpers/auth/isLoggedIn';

interface IProps {
    paymentMethodProps: IPaymentMethodProps;
    method: IPaymentMethodResponse;
    email: string | undefined;
    setAllowQuery: (allowQuery: boolean) => void;
    extensionAttributes?: () => {
        risks_awareness?: string;
        agreement_ids?: string[];
    };
}

const EveryPay = (props: IProps) => {
    const { method, email, extensionAttributes, setAllowQuery } = props;

    const [{}, postData] = useMutation((data) =>
        postRequest({
            type: 'everyPayPayment',
            url: isLoggedIn() ? 'everypay/payment-adapter/mine' : `everypay/payment-adapter/${window.quoteIdMask}`,
            data,
            useStoreCode: true,
        }),
    );

    return (
        <SimplePayment
            method={method.code}
            key={method.code}
            onClick={() => {
                setAllowQuery(true);
                postData({
                    cartId: window.quoteIdMask,
                    email,
                    methodId: 'card', // only one method is chosen by customer. Additional query is needed for other methods
                    tokenId: 0, // card selection query needs to be implemented, not in scope at the moment
                    paymentMethod: {
                        method: method.code,
                        extension_attributes: extensionAttributes ? extensionAttributes() : [],
                    },
                }).then((response) => {
                    window.dispatchEvent(new CustomEvent('subscription-save'));
                    setAllowQuery(false);
                    if (response.status === 200 && response.body) {
                        window.location.href = response.body;
                    }
                });
            }}
            label={method.title}
            paymentMethodProps={props.paymentMethodProps}
        />
    );
};

export default EveryPay;
