import React, { useRef, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import FormKey from 'components/GeneralForm/Field/FormKey';
import FormList from 'andbeauty-ui/lib/Applications/eBeauty/Components/FormList/index';
import FormListItem from 'andbeauty-ui/lib/Applications/eBeauty/Components/FormListItem/index';
import Button from 'andbeauty-ui/lib/Components/Button/index';
import Buttons from 'andbeauty-ui/lib/Components/Buttons/index';
import ControlInput from 'andbeauty-ui/lib/Components/ControlInput/index';
import { getCaptchaToken } from 'components/GoogleRecaptcha/Index';

interface IProps {
    config: {
        formConfig: {
            actionUrl: string;
        };
        labels: {
            requiredField: string;
            yourEmail: string;
            resetMyPassword: string;
        };
    };
}

const ForgotPassword = (props: IProps): JSX.Element => {
    const { config } = props;

    const [captchaToken, setCaptchaToken] = useState('');
    const formElement = useRef<HTMLFormElement>(null);
    const formik = useFormik({
        initialValues: { email: '' },
        validationSchema: Yup.object().shape({
            email: Yup.string().nullable().required(config.labels.requiredField),
        }),
        onSubmit: () => {
            void getCaptchaToken().then((token) => {
                setCaptchaToken(token);

                if (formElement.current) {
                    formElement.current.submit();
                }
            });
        },
    });

    return (
        <form action={config.formConfig.actionUrl} method="post" ref={formElement} onSubmit={formik.handleSubmit}>
            <FormKey />
            <input type="hidden" name="g-recaptcha-response" value={captchaToken} />

            <FormList separated>
                <FormListItem label={config.labels.yourEmail} required={true} error={formik.errors.email}>
                    <ControlInput name="email" value={formik.values.email} onChange={formik.handleChange} />
                </FormListItem>
            </FormList>

            <Buttons layout={'vertical-wide'}>
                <Button type="submit" intent="primary" title={config.labels.resetMyPassword} />
            </Buttons>
        </form>
    );
};

export default ForgotPassword;
