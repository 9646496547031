import * as React from 'react';
import { IPaymentMethodProps } from 'components/Checkout/Payment/PaymentBlock';
import { useMutation } from 'redux-query-react';
import { IPaymentMethodResponse } from '../../interfaces/payment/IPaymentMethodResponse';
import { useEffect } from 'react';
import Toaster from 'andbeauty-ui/lib/Components/Toaster/index';
import { PaymentMethodEnum } from 'components/Checkout/Payment/Methods/MethodHandler';
import { request } from 'data/requests/request';
import ModenaPaymentDirect from 'components/Checkout/Payment/Methods/ModenaPaymentDirect';
import ModenaPaymentInstallment from 'components/Checkout/Payment/Methods/ModenaPaymentInstallment';

interface IProps {
    paymentMethodProps: IPaymentMethodProps;
    method: IPaymentMethodResponse;
    email: string | undefined;
    setAllowQuery: (allowQuery: boolean) => void;
    extensionAttributes?: () => {
        risks_awareness?: string;
        agreement_ids?: string[];
    };
}

const ModenaPaymentBase = (props: IProps) => {
    const { method, paymentMethodProps, email, setAllowQuery, extensionAttributes } = props;

    let redirectUrl;

    switch (method.code) {
        case PaymentMethodEnum.modena_credit:
            redirectUrl = 'credit/request/redirect';
            break;
        case PaymentMethodEnum.modena_slice:
            redirectUrl = 'slice/request/redirect';
            break;
        default:
            redirectUrl = 'direct/request/redirect';
    }

    const [{}, queryRedirect] = useMutation(() => request({ type: 'modena_redirect', url: redirectUrl, notApi: true }));

    const effect = (event) => {
        if (event.detail?.setRedirectUrl && method.code === event.detail.methodCode) {
            queryRedirect().then((response) => {
                if (response.body.error) {
                    Toaster.addToast({
                        intent: 'danger',
                        text: response.body.error,
                    });
                }
                if (response.body.redirect_url) {
                    event.detail.setRedirectUrl({ to: response.body.redirect_url, absolute: true });
                }
            });
        }
    };

    useEffect(() => {
        window.addEventListener('payment-response-receive', effect);
        return function cleanup() {
            window.removeEventListener('payment-response-receive', effect);
        };
    }, []);

    return method.code === PaymentMethodEnum.modena_direct ? (
        <ModenaPaymentDirect
            paymentMethodProps={paymentMethodProps}
            method={method}
            email={email}
            setAllowQuery={setAllowQuery}
            extensionAttributes={extensionAttributes}
        />
    ) : (
        <ModenaPaymentInstallment
            paymentMethodProps={paymentMethodProps}
            method={method}
            email={email}
            setAllowQuery={setAllowQuery}
            extensionAttributes={extensionAttributes}
        />
    );
};

export default ModenaPaymentBase;
