import React from 'react';
import RenderTabs from 'andbeauty-ui/lib/Applications/eBeauty/Components/RenderTabs';
import RenderHTML from 'andbeauty-ui/lib/Components/RenderHTML/index';

interface ITab {
    label: string;
    content: string;
}

interface IProps {
    config: {
        tabs: ITab[];
    };
}

const Tabs = (props: IProps) => {
    const { config } = props;
    const { tabs } = config;

    const tabData = {};
    tabs.forEach((tab) =>
        Object.assign(tabData, { [tab.label]: { title: tab.label, content: <RenderHTML html={tab.content} /> } }),
    );

    return (
        <React.Fragment>{tabs.length > 0 && <RenderTabs activeTab={tabs[0].label} tabs={tabData} />}</React.Fragment>
    );
};

export default Tabs;
