import React from 'react';
import Img from 'components/Image/Img';

interface IProps {
    config: {
        isConnected: boolean;
        iconName: string;
        iconLabel: string;
        handleClick: () => void;
        labels: {
            connect: string;
            connected: string;
            disconnect: string;
            disconnected: string;
        };
    };
}

const DefaultAttachButton = (props: IProps): JSX.Element => {
    const { config } = props;
    const { isConnected, handleClick, labels } = config;

    return (
        <li className="hasico">
            <Img iconName={config.iconName} className="icon" alt={config.iconLabel} />
            <div className="a">
                <p className="subtitle">{isConnected ? labels.connected : labels.disconnected}</p>
            </div>
            <div className="b">
                <button className="button intent-secondary muted size-small" onClick={handleClick}>
                    <Img iconName={isConnected ? 'icon_disconnect' : 'icon_connect'} />
                    <span>{!isConnected ? labels.connect : config.labels.disconnect}</span>
                </button>
            </div>
        </li>
    );
};

export default DefaultAttachButton;
