import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useMutation } from 'redux-query-react';
import { find } from 'lodash';
import Toaster from 'andbeauty-ui/lib/Components/Toaster/index';
import Button from 'andbeauty-ui/lib/Components/Button';
import { IProductListLabels } from 'components/Catalog/Product/ProductList';
import useCartSummary from '../../../../../hooks/useCartSummary';
import AddToCartRequest from 'components/Checkout/requests/AddToCartRequest';
import { IProduct } from 'components/Catalog/interfaces/IProduct';
import getTagManagerContext from 'components/SeoSuite/helpers/tagManagerContext';
import useAuth from '../../../../../hooks/useAuth';

interface IProps {
    product: IProduct;
    labels: IProductListLabels;
}

const ProductActions = (props: IProps) => {
    const { product, labels } = props;

    const { customer } = useAuth();
    const productActionsElement = useRef(null);
    const { quoteId, addToCartUri, cartItems } = useCartSummary();
    const [addedToCart, setAddedToCart] = useState(false);
    const [{ isPending }, addToCartRequest] = useMutation((url, quoteId) => AddToCartRequest(url, quoteId, product));

    const addToCart = useCallback(async () => {
        const response = await addToCartRequest(addToCartUri, quoteId);

        if (response.status === 200) {
            const tagManagerContext = getTagManagerContext(productActionsElement);

            tagManagerContext.then((context) => {
                window.dispatchEvent(
                    new CustomEvent('cartUpdated', {
                        detail: {
                            action: 'add-to-cart',
                            userId: customer?.id,
                            sku: product.sku,
                            name: product.name,
                            price: product.price,
                            qty: 1,
                            ...context,
                        },
                    }),
                );
            });
        } else {
            if (response.body.message) {
                Toaster.addToast({
                    intent: 'danger',
                    text: response.body.message,
                    asHtml: true,
                });
            }
        }
    }, [quoteId]);

    useEffect(() => {
        const inCart = find(cartItems, (item) => item.sku === product.sku);

        setAddedToCart(!!inCart);
    }, [cartItems]);

    return (
        <div ref={productActionsElement}>
            {(product.typeId === 'configurable' || !product.isSaleable) && (
                <Button
                    type="anchor"
                    href={product.url}
                    title={
                        !product.isSaleable ? labels.outOfStock : `${labels.choose} ${product.configurableAttribute}`
                    }
                    intent="secondary"
                />
            )}
            {product.typeId === 'simple' && product.isSaleable && (
                <Button
                    loading={isPending}
                    disabled={isPending}
                    intent={addedToCart ? 'success' : 'primary'}
                    onClick={addToCart}
                    title={addedToCart ? labels.addedToCart : labels.addToCart}
                    icon={addedToCart ? 'check' : 'cart'}
                />
            )}
        </div>
    );
};

export default ProductActions;
