import React from 'react';
import { IAutocompleteCategory, IAutocompleteItem } from 'components/CatalogSearch/requests/AutocompleteRequest';

const Category = (props: IAutocompleteCategory) => {
    return (
        <li className="search__results__result">
            <a href={props.url}>
                <div className="text">
                    <div className="name">
                        {props.title}
                        {!!props.categories.length && (
                            <React.Fragment>
                                <br />
                                <small>
                                    {props.categories.map((category, i) => (
                                        <span key={i} className="secondary">
                                            {category}
                                        </span>
                                    ))}
                                </small>
                            </React.Fragment>
                        )}
                    </div>
                    <div className="addon">{props.label}</div>
                </div>
            </a>
        </li>
    );
};

export default Category;
