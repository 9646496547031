import React from 'react';
import useAuth from '../../hooks/useAuth';
import LoginButton from 'components/Customer/Auth/LoginButton';

interface Flag {
    href: string;
    img: string;
    alt: string;
    label: string;
}

interface IProps {
    config: {
        labels: {
            login: string;
            logout: string;
            register: string;
            overview: string;
            myOrders: string;
            myAccount: string;
        };
        flagData: Flag[];
    };
}

const UserHubMobile = (props: IProps) => {
    const { labels, flagData } = props.config;
    const { isLoggedIn } = useAuth();

    return (
        <React.Fragment>
            <ul className="user-menu">
                <h3>{labels.myAccount}</h3>
                {isLoggedIn && (
                    <React.Fragment>
                        <li>
                            <a href="/customer/account">{labels.overview}</a>
                        </li>
                        <li>
                            <a href="/sales/order/history">{labels.myOrders}</a>
                        </li>
                        <li>
                            <a href="/customer/account/logout">{labels.logout}</a>
                        </li>
                    </React.Fragment>
                )}
                {!isLoggedIn && (
                    <React.Fragment>
                        <li>
                            <LoginButton config={{ loginLabel: labels.login }} />
                        </li>
                        <li>
                            <a href="/customer/account/create">{labels.register}</a>
                        </li>
                    </React.Fragment>
                )}
            </ul>
            <div className="static">
                <ul className="mobile-languages">
                    {flagData.map((flag) => (
                        <li key={flag.href}>
                            <a href={flag.href}>
                                <span className="flag">
                                    <img src={flag.img} alt={flag.alt} />
                                </span>
                                <span className="label">{flag.label}</span>
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        </React.Fragment>
    );
};

export default UserHubMobile;
