import { postRequest } from 'data/requests/postRequest';

interface IProduct {
    id: number;
    sku: string;
}

const UpdateProductRequest = (url: string, quoteId: string, product: IProduct, qty = 1) =>
    postRequest({
        method: 'PUT',
        type: 'UpdateProductRequest',
        url: `${url}/${product.id}`,
        data: {
            cartItem: {
                sku: product.sku,
                qty,
                quote_id: quoteId,
            },
        },
    });

export default UpdateProductRequest;
