import { request } from 'data/requests/request';

const DataConfigRequest = (categoryId: number) =>
    request({
        type: 'brandMenuDataConfig',
        url: 'brandmenu/ajax/dataconfig',
        data: {
            categoryId,
        },
        method: 'GET',
        notApi: true,
    });

export default DataConfigRequest;

export interface IDataConfigResponse {
    categories: {
        [key: string]: Array<{
            name: string;
            url: string;
        }>;
    };
}
