import React, { useCallback, useState } from 'react';
import { useMutation } from 'redux-query-react';
import { useGoogleLogin } from '@react-oauth/google';
import { AccountIdentifier } from 'components/SocialLogin/EnumAccountIdentifier';
import connectAccountRequest from 'components/SocialLogin/requests/ConnectAccountRequest';
import disconnectAccountRequest from 'components/SocialLogin/requests/DisconnectAccountRequest';
import DefaultAttachButton from 'components/SocialLogin/AttachButtons/DefaultAttachButton';
import Toaster from 'andbeauty-ui/lib/Components/Toaster/index';

interface IProps {
    config: IGoogleAttachButtonConfig;
}

export interface IGoogleAttachButtonConfig {
    isEnabled: boolean;
    accountIdentifier: string;
    clientId: string;
    userId?: string;
    labels: {
        connectedLabel: string;
        disconnectedLabel: string;
        connectLabel: string;
        disconnectLabel: string;
    };
}

const GoogleAttachButton = (props: IProps): JSX.Element => {
    const { config } = props;
    const [{}, connectRequest] = useMutation((account, userId) => connectAccountRequest(account, userId));
    const [{}, disConnectRequest] = useMutation((account) => disconnectAccountRequest(account));
    const [isConnected, setIsConnected] = useState(!!config.userId);

    const handleClick = useCallback(() => {
        if (!isConnected) {
            connectAccount();
        } else {
            disconnectAccount();
        }
    }, [isConnected]);

    const connectAccount = useGoogleLogin({
        onSuccess: (tokenResponse) => {
            void connectRequest(AccountIdentifier.GOOGLE, tokenResponse.code)
                .then((response) => {
                    if (response.body.error === 1) {
                        Toaster.addToast({
                            intent: 'danger',
                            text: response.body.message,
                        });
                    } else {
                        setIsConnected(true);
                    }
                })
                .catch(() => {
                    window.location.reload();
                });
        },
        onError: () => {
            window.location.reload();
        },
        flow: 'auth-code',
    });

    const disconnectAccount = () => {
        void disConnectRequest(AccountIdentifier.GOOGLE)
            .then(() => {
                setIsConnected(false);
            })
            .catch(() => {
                window.location.reload();
            });
    };

    return (
        <DefaultAttachButton
            config={{
                iconName: 'icon_google',
                iconLabel: 'Google',
                isConnected,
                handleClick,
                labels: {
                    connect: config.labels.connectLabel,
                    connected: config.labels.connectedLabel,
                    disconnect: config.labels.disconnectLabel,
                    disconnected: config.labels.disconnectedLabel,
                },
            }}
        />
    );
};

export default GoogleAttachButton;
