import React, { useState } from 'react';
import classNames from 'classnames';
import { Classes, Popover } from '@blueprintjs/core';
import { useTimeout } from 'andbeauty-ui/lib/Utilities/Generic';

interface IProps {
    target: string | JSX.Element;
    content: string | JSX.Element;
}
const PopOverMenuItem = (props: IProps) => {
    const { target, content } = props;
    const [headerHeight, setHeaderHeight] = useState<number>(0);
    const [activeElementTimer, setActiveElementTimer] = useState<number | null>(null);
    const setActiveElements = (val) => {
        const el = document.body;
        if (el) {
            if (val) {
                setActiveElementTimer(null);
                el.classList.add('has-menu');
            } else {
                setActiveElementTimer(100);
            }
        }
    };
    useTimeout(() => {
        const el = document.body;
        setActiveElementTimer(null);
        el.classList.remove('has-menu');
    }, activeElementTimer);

    return (
        <Popover
            interactionKind="hover"
            position="bottom-left"
            boundary="window"
            modifiers={{
                offset: {
                    offset: '0, 0',
                },
                flip: {
                    enabled: false,
                },
            }}
            portalClassName={classNames('site-menu-portal', 'main-nav')}
            popoverClassName={classNames('site-menu-popover')}
            portalContainer={document.getElementById('main-header') || undefined}
            onOpened={(el) => {
                const menu = document.getElementsByClassName('site-menu__drop__nav')[0];
                if (menu) {
                    const item = menu.getElementsByTagName('a')[0];
                    if (item) {
                        item.click();
                    }
                }
                const header = document.getElementById('main-header');
                if (header) {
                    const headerRect = header.getBoundingClientRect();
                    let h = headerRect.top + headerRect.height;
                    const cookie = document.getElementById('cookiemessage');
                    if (cookie) {
                        const cookieRect = cookie.getBoundingClientRect();
                        h += cookieRect.height;
                    }
                    setHeaderHeight(h);
                }
                setActiveElements(true);
            }}
            onClosed={() => {
                setActiveElements(false);
            }}
            content={
                <div
                    className={classNames('site-menu__drop', Classes.POPOVER_DISMISS)}
                    style={{ height: `calc(100vh - ${headerHeight}px )` }}
                >
                    <div className={classNames('site-menu__drop__limiter', Classes.POPOVER_DISMISS_OVERRIDE)}>
                        <div className="site-menu__drop__content">{content}</div>
                    </div>
                </div>
            }
            target={target}
            enforceFocus
            usePortal
            minimal
            autoFocus
            captureDismiss
            transitionDuration={0}
            hoverOpenDelay={0}
            hoverCloseDelay={100}
        />
    );
};

export default PopOverMenuItem;
