import { request } from 'data/requests/request';
import { IFilter } from 'components/LayeredNavigation/LayeredNavigation';

const FiltersRequest = (url: string) =>
    request({
        type: 'layeredNavigationFiltersRequest',
        url,
        method: 'GET',
        notApi: true,
        absolute: true,
    });

export default FiltersRequest;

export interface IFiltersResponse {
    productsCount: number;
    minPrice: number;
    maxPrice: number;
    filters: IFilter[];
}
