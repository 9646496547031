import Buttons from 'andbeauty-ui/lib/Components/Buttons';
import Button from 'andbeauty-ui/lib/Components/Button';
import RenderHTML from 'andbeauty-ui/lib/Components/RenderHTML';
import React from 'react';
import { closeOverlay, openOverlay } from 'data/overlays/overlayHandler';
import { useDispatch } from 'react-redux';

interface IProps {
    callout: string;
}

const CustomerBenefitCallout = (props: IProps) => {
    const { callout } = props;
    const dispatch = useDispatch();

    return (
        <Buttons>
            <Button
                className="mt10"
                intent="secondary"
                title={<RenderHTML html={callout} nowrapper />}
                size="xsmall"
                type="anchor"
                onClick={(e) => {
                    e.preventDefault();
                    dispatch(closeOverlay({ name: 'all' }));
                    dispatch(openOverlay({ name: 'login' }));
                }}
            />
        </Buttons>
    );
};

export default CustomerBenefitCallout;
