import React from 'react';
import { formatPriceLocale } from '../../../helpers/priceUtils';
import { IAutocompleteProduct } from 'components/CatalogSearch/requests/AutocompleteRequest';

interface IProps {
    item: IAutocompleteProduct;
    labels: {
        startingFrom: string;
    };
}
const Product = (props: IProps) => {
    const { item, labels } = props;
    const { title, url, image, price, specialPrice, minimalPrice } = item;

    return (
        <li className="search__results__result">
            <a href={url}>
                {image && <div className="image" style={{ backgroundImage: `url(${image})` }} />}
                <div className="text">
                    <div className="name">{title}</div>
                    <div className="addon">
                        <span className="price">
                            {!minimalPrice && (
                                <React.Fragment>
                                    {specialPrice && (
                                        <React.Fragment>
                                            <del>{formatPriceLocale(price)}</del>
                                            <strong>{formatPriceLocale(specialPrice)}</strong>
                                        </React.Fragment>
                                    )}
                                    {!specialPrice && formatPriceLocale(price)}
                                </React.Fragment>
                            )}
                            {minimalPrice && `${labels.startingFrom} ${formatPriceLocale(minimalPrice)}`}
                        </span>
                    </div>
                </div>
            </a>
        </li>
    );
};

export default Product;
