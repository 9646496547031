import React from 'react';
import Button from 'andbeauty-ui/lib/Components/Button';
import Img from 'components/Image/Img';
import Overlay from 'andbeauty-ui/lib/Components/Overlay';
import { includes } from 'lodash';
import Icon from 'andbeauty-ui/lib/Components/Icon';
import FiltersWrapper from 'components/LayeredNavigation/FiltersWrapper';
import SelectedFiltersWrapper from 'components/LayeredNavigation/SelectedFiltersWrapper';
import Buttons from 'andbeauty-ui/lib/Components/Buttons';
import { useDispatch, useSelector } from 'react-redux';
import { overlaysSelector } from 'data/overlays/overlaysSelector';
import { closeOverlay, openOverlay } from 'data/overlays/overlayHandler';
import { IFilter } from 'components/LayeredNavigation/LayeredNavigation';
import { ISelectedFilters } from 'components/LayeredNavigation/store/selectedFiltersSelector';

interface IProps {
    config: {
        minPrice: number;
        maxPrice: number;
        clearAllUrl: string;
        labels: {
            remove: string;
            showAll: string;
            showLess: string;
            minimum: string;
            maximum: string;
            clearAll: string;
            applyFilters: string;
            closeFilters: string;
            selectedFilters: string;
        };
    };
    filters: IFilter[];
    selectedFilters: ISelectedFilters;
    addFilter: (attributeCode: string, item: { label: string; value: string }, replace?: boolean) => void;
    removeFilter: (attributeCode: string, item: { label: string; value: string }) => void;
}

const LayeredNavigationMobile = (props: IProps) => {
    const { config, filters, selectedFilters, addFilter, removeFilter } = props;
    const { labels, clearAllUrl, minPrice, maxPrice } = config;
    const dispatch = useDispatch();
    const { openOverlays } = useSelector(overlaysSelector);

    const openFilters = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        dispatch(openOverlay({ name: 'filters' }));
    };

    const closeFilters = () => {
        dispatch(closeOverlay({ name: 'filters' }));
    };

    return (
        <React.Fragment>
            <Button
                size="small"
                title={
                    <span>
                        <Img iconName="icon_filter" />
                        {labels.applyFilters}
                    </span>
                }
                className="desktop-hide tablet-hide"
                onClick={openFilters}
            />
            <Overlay
                isOpen={includes(openOverlays, 'filters')}
                doClose={closeFilters}
                layout="dialog"
                className="ebeauty-dialog"
                customHeader={
                    <div className="ebeauty-dialog-heading">
                        <h2>{labels.applyFilters}</h2>
                        <button onClick={closeFilters}>
                            <Icon kind="close02" width={16} height={16} />
                        </button>
                    </div>
                }
            >
                <div className="ebeauty-dialog-content">
                    <div className="product-filters" id="product-filters-overlay-wrapper">
                        <FiltersWrapper
                            config={{
                                isOverlay: true,
                                maxPrice,
                                minPrice,
                                labels,
                            }}
                            filters={filters}
                            selectedFilters={selectedFilters}
                            addFilter={addFilter}
                            removeFilter={removeFilter}
                        />

                        {!!Object.keys(selectedFilters).length && (
                            <SelectedFiltersWrapper
                                config={{
                                    clearAllUrl,
                                    labels,
                                }}
                                showClearAllButton={false}
                                selectedFilters={selectedFilters}
                                removeFilter={removeFilter}
                            />
                        )}
                        <Buttons>
                            <Button title={labels.closeFilters} intent="primary" onClick={closeFilters} />
                            <Button
                                type="anchor"
                                intent="secondary"
                                size="xsmall"
                                href={clearAllUrl}
                                title={labels.clearAll}
                                icon="remove"
                            />
                        </Buttons>
                    </div>
                </div>
            </Overlay>
        </React.Fragment>
    );
};

export default LayeredNavigationMobile;
