import React from 'react';
import Img from 'components/Image/Img';

interface IProps {
    rating: number;
}

const ReviewSummary = (props: IProps) => {
    const { rating } = props;
    const ratingLabel = `${rating}/5`;

    return (
        <div className={`product-reviews__score product-reviews__score-${rating}`}>
            <span>
                <Img iconName="sprite_rating" alt={ratingLabel} />
            </span>
            {ratingLabel}
        </div>
    );
};

export default ReviewSummary;
