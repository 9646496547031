import React from 'react';
import { includes } from 'lodash';
import Overlay from 'andbeauty-ui/lib/Components/Overlay';
import Buttons from 'andbeauty-ui/lib/Components/Buttons';
import Button from 'andbeauty-ui/lib/Components/Button';
import Icon from 'andbeauty-ui/lib/Components/Icon/index';
import SystemNotifications from 'andbeauty-ui/lib/Applications/eBeauty/Components/SystemNotifications/index';
import SystemNotification from 'andbeauty-ui/lib/Applications/eBeauty/Components/SystemNotification/index';
import { closeOverlay } from 'data/overlays/overlayHandler';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreState } from '../../helpers/rootReducer';
import useCartSummary from '../../hooks/useCartSummary';
import { formatPriceLocale } from '../../helpers/priceUtils';
import Item from 'components/Checkout/Cart/Items/Item';
import SampleProductPopupTriggerButton from 'components/SampleProduct/SampleProductPopupTriggerButton';

interface IProps {
    config: {
        cartUrl: string;
        checkoutUrl: string;
        productSamplesConfig: {
            isEnabled: boolean;
            labels: {
                addFreeSamples: string;
            };
        };
        labels: {
            shoppingCart: string;
            viewCart: string;
            toCheckout: string;
            orderTotal: string;
            subTotal: string;
            removeItem: string;
            lessLabel: string;
            moreLabel: string;
            qtyLabel: string;
            noItems: string;
        };
    };
}

const MiniCartPopup = (props: IProps) => {
    const { config } = props;
    const { labels, productSamplesConfig } = config;
    const dispatch = useDispatch();
    const { subTotal, cartItems } = useCartSummary();
    const { openOverlays } = useSelector((state: IStoreState) => state.overlaysReducer);

    const closeMiniCart = () => {
        dispatch(closeOverlay({ name: 'all' }));
    };

    return (
        <Overlay
            isOpen={includes(openOverlays, 'minicart')}
            doClose={closeMiniCart}
            layout="category-tree"
            className="ebeauty-dialog ebeauty-dialog-minicart"
            canOutsideClickClose={true}
            canEscapeKeyClose={true}
            customHeader={
                <div className="ebeauty-dialog-heading">
                    <h2>{labels.shoppingCart}</h2>
                    <button onClick={closeMiniCart}>
                        <Icon kind="close02" width={16} height={16} />
                    </button>
                </div>
            }
        >
            <div className="ebeauty-dialog-content">
                {!cartItems.length && (
                    <SystemNotifications theme="full" separated={true}>
                        <SystemNotification intent="warn">
                            <p>{labels.noItems}</p>
                        </SystemNotification>
                    </SystemNotifications>
                )}
                {!!cartItems.length && (
                    <React.Fragment>
                        <div className="ebeauty-dialog-minicart__main">
                            {cartItems.map((item) => (
                                <Item
                                    key={item.sku}
                                    item={item}
                                    labels={labels}
                                    gtmDataContext={{ itemListId: '', itemListName: 'Mini Cart Popup' }}
                                />
                            ))}
                        </div>
                        <div className="ebeauty-dialog-minicart__footer">
                            <p className="layout-cart__sidebar-total">
                                {labels.orderTotal}:{' '}
                                <span className="text-accented">{formatPriceLocale(subTotal)}</span>
                            </p>
                            <Buttons layout="vertical-wide">
                                {productSamplesConfig.isEnabled && (
                                    <SampleProductPopupTriggerButton
                                        labels={productSamplesConfig.labels}
                                        intent="success"
                                    />
                                )}
                                <Button
                                    intent="secondary"
                                    title={labels.viewCart}
                                    type="anchor"
                                    href={config.cartUrl}
                                />
                                <Button
                                    intent="primary"
                                    title={labels.toCheckout}
                                    type="anchor"
                                    href={config.checkoutUrl}
                                />
                            </Buttons>
                        </div>
                    </React.Fragment>
                )}
            </div>
        </Overlay>
    );
};

export default MiniCartPopup;
