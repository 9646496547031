import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ICartItem, ICartSummaryResponse, ICartTotalsRow } from 'components/Checkout/requests/CartSummaryRequest';

const mapStateToProps = (state) => {
    return {
        cartSummary: state.entities.cartSummaryRequest as ICartSummaryResponse,
    };
};

const useCartSummary = () => {
    const { cartSummary } = useSelector(mapStateToProps);
    const [quoteId, setQuoteId] = useState<string>();
    const [addToCartUri, setAddToCartUri] = useState<string>();
    const [itemsQty, setItemsQty] = useState(0);
    const [subTotal, setSubTotal] = useState(0);
    const [cartTotals, setCartTotals] = useState<ICartTotalsRow[]>();
    const [cartItems, setCartItems] = useState<ICartItem[]>([]);

    useEffect(() => {
        if (cartSummary) {
            setQuoteId(cartSummary.quoteId);
            setAddToCartUri(cartSummary.addToCartUri);
            setCartItems(cartSummary.cartItems);
            setItemsQty(cartSummary.summaryCount);
            setSubTotal(cartSummary.subTotal);
            setCartTotals(cartSummary.cartTotals);
        }
    }, [cartSummary]);

    return {
        addToCartUri,
        quoteId,
        cartItems,
        itemsQty,
        cartTotals,
        subTotal,
    };
};

export default useCartSummary;
