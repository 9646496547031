import React from 'react';
import { IProductListLabels } from 'components/Catalog/Product/ProductList';
import ReviewSummary from 'components/Catalog/Product/List/BoxProduct/ReviewSummary';
import PriceRenderer from 'components/Catalog/Product/Price/PriceRenderer';
import ProductActions from 'components/Catalog/Product/List/BoxProduct/ProductActions';
import ProductBadges from 'components/Catalog/ProductBadge/ProductBadges';
import { IProduct } from 'components/Catalog/interfaces/IProduct';

export interface IProps {
    product: IProduct;
    labels: IProductListLabels;
}

const BoxProduct = (props: IProps) => {
    const { product, labels } = props;
    const pricePrefix = product.typeId === 'configurable' ? labels.startingFrom : '';

    return (
        <div className="layout-products__container">
            <div className="box-product">
                <div className="box-product__main">
                    <a href={product.url} className="box-product__link">
                        {product.name}
                    </a>
                    <div className="box-product__image">
                        <ProductBadges badges={product.badges} />
                        <span style={{ backgroundImage: `url('${product.image}')` }} />
                    </div>
                    <div className="box-product__content">
                        <div className="box-product__text">
                            {product.brand && <div className="box-product__brand">{product.brand}</div>}
                            <div className="box-product__title">{product.name}</div>
                            {product.childrenSummary && (
                                <div className="box-product__meta">{product.childrenSummary}</div>
                            )}
                            {product.rating > 0 && <ReviewSummary rating={product.rating} />}
                            {product.shortDescription && (
                                <div className="box-product__description">{product.shortDescription}</div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="box-product__actions">
                    <div className="box-product__prices">
                        <PriceRenderer
                            price={product.price}
                            specialPrice={product.specialPrice}
                            minimalPrice={product.minimalPrice}
                            pricePrefix={pricePrefix}
                        />
                    </div>
                    <ProductActions product={product} labels={labels} />
                </div>
            </div>
        </div>
    );
};

export default BoxProduct;
