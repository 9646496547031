import React from 'react';
import classNames from 'classnames';
import { formatPriceLocale } from '../../../../helpers/priceUtils';

interface IProps {
    type?: 'special' | 'discount';
    price: number;
    pricePrefix?: string;
    unitPrice?: number;
    referenceUnit?: string;
}

const PriceValue = (props: IProps) => {
    const { type, price, pricePrefix, unitPrice, referenceUnit } = props;

    return (
        <div className={classNames('product-pricing__price', type)}>
            <p>
                <span className="product-pricing__price-value">
                    {pricePrefix && <span className="product-pricing__price-prefix">{pricePrefix}</span>}
                    <span className="product-pricing__price-number">{formatPriceLocale(price)}</span>
                </span>
            </p>
            {unitPrice && (
                <p className="product-pricing__price-perunit">
                    {formatPriceLocale(unitPrice)}/{referenceUnit}
                </p>
            )}
        </div>
    );
};

export default PriceValue;
