import React from 'react';
import FacebookAttachButton, { IFacebookAttachButtonConfig } from 'components/SocialLogin/AttachButtons/Facebook';
import GoogleAttachButton, { IGoogleAttachButtonConfig } from 'components/SocialLogin/AttachButtons/Google';
import { GoogleOAuthProvider } from '@react-oauth/google';

interface IProps {
    config: {
        facebookLogin: IFacebookAttachButtonConfig;
        googleLogin: IGoogleAttachButtonConfig;
    };
}

const SocialLoginAttachButtons = (props: IProps): JSX.Element => {
    const { config } = props;

    return (
        <ul className="account-items">
            {config.facebookLogin.isEnabled && <FacebookAttachButton config={config.facebookLogin} />}
            {config.googleLogin.isEnabled && (
                <GoogleOAuthProvider clientId={config.googleLogin.clientId ?? ''}>
                    <GoogleAttachButton config={config.googleLogin} />
                </GoogleOAuthProvider>
            )}
        </ul>
    );
};

export default SocialLoginAttachButtons;
