import { request } from 'data/requests/request';

const DataConfigRequest = () =>
    request({
        type: 'quoteReservationDataConfigRequest',
        url: 'quotereservation/ajax/dataconfig',
        method: 'GET',
        notApi: true,
    });

export default DataConfigRequest;

export interface IQuoteReservationDataConfigResponse {
    extendEnabled: boolean;
    extendThreshold: number;
    timeRemaining?: number;
}
