import { ContactPhoneAreaItems } from 'components/Checkout/Address/defaults/ContactPhoneAreaItems';

export function extractCodeFromPhone(telephone: string, phoneCode?: string) {
    const contactPhoneAreaItems = ContactPhoneAreaItems();
    contactPhoneAreaItems.forEach((item) => {
        const hasPlusSign = telephone.startsWith('+');
        // Add plus for easier matching
        if (!hasPlusSign) {
            telephone = `+${telephone}`;
        }
        if (telephone.startsWith(item)) {
            phoneCode = item;
            telephone = telephone.replace(phoneCode, '');
        }
        // Remove plus again
        if (!hasPlusSign) {
            telephone = telephone.replace('+', '');
        }
    });

    if (!phoneCode) {
        phoneCode = contactPhoneAreaItems.shift();
    }

    return {
        phoneCode,
        telephone,
    };
}
