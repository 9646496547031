import React from 'react';
import ControlInput from 'andbeauty-ui/lib/Components/ControlInput/index';
import ControlSelect from 'andbeauty-ui/lib/Components/ControlSelect/index';

interface IProps {
    onChange?: (output: { phoneNoPrefix?: string; phoneNo?: string }) => void;
    phoneNoPrefix?: string;
    setPhoneNoPrefix: (prefix: string) => void;
    phoneNo?: string;
    setPhoneNo: (phoneNo: string) => void;
    phonePrefixes: string[];
}

const ControlPhone = (props: IProps): JSX.Element => {
    const { phoneNoPrefix, setPhoneNoPrefix, phoneNo, setPhoneNo, phonePrefixes } = props;

    return (
        <div className="form-control-phone">
            <ControlSelect
                value={phoneNoPrefix ?? ''}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    setPhoneNoPrefix(e.target.value);

                    if (props.onChange && phoneNo) {
                        props.onChange({ phoneNoPrefix: e.target.value, phoneNo });
                    }
                }}
            >
                {phonePrefixes.map((prefix) => (
                    <option key={prefix} value={prefix}>
                        {prefix}
                    </option>
                ))}
            </ControlSelect>
            <ControlInput
                value={phoneNo ?? ''}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setPhoneNo(e.target.value);

                    if (props.onChange && phoneNoPrefix) {
                        props.onChange({ phoneNoPrefix, phoneNo: e.target.value });
                    }
                }}
            />
        </div>
    );
};

export default ControlPhone;
