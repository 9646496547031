import React, { useRef } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import FormList from 'andbeauty-ui/lib/Applications/eBeauty/Components/FormList/index';
import FormListItem from 'andbeauty-ui/lib/Applications/eBeauty/Components/FormListItem/index';
import ControlInput from 'andbeauty-ui/lib/Components/ControlInput/index';
import IAccountForm from 'components/Customer/interfaces/IAccountForm';
import Buttons from 'andbeauty-ui/lib/Components/Buttons/index';
import Button from 'andbeauty-ui/lib/Components/Button/index';
import validatePasswordMinCharacterSets from '../../../helpers/passwordValidator';
import Newsletter from 'components/Newsletter/Account/Form/Newsletter';
import ControlSelect from 'andbeauty-ui/lib/Components/ControlSelect/index';
import ControlPassword from 'components/GeneralForm/Field/ControlPassword';
import CookieConsent from 'components/Gdpr/Account/Form/CookieConsent';
import FormKey from 'components/GeneralForm/Field/FormKey';

interface IProps {
    config: IAccountForm;
}

const CustomerEditForm = (props: IProps): JSX.Element => {
    const { config } = props;
    const { passwordConfig, labels } = config;
    const { labels: passwordLabels } = passwordConfig;
    const formElement = useRef<HTMLFormElement>(null);

    const FormDataValidationSchema = {
        firstname: Yup.string().nullable().required(labels.requiredField),
        lastname: Yup.string().nullable().required(labels.requiredField),
        email: Yup.string().nullable().email().required(labels.requiredField),
        is_dob_enabled: Yup.boolean(),
        dob: Yup.string()
            .nullable()
            .when('is_dob_enabled', {
                is: true,
                then: Yup.string().nullable().required(labels.requiredField),
            }),
        is_gender_enabled: Yup.boolean(),
        gender: Yup.string().when('is_gender_enabled', {
            is: true,
            then: Yup.string().nullable().required(labels.requiredField),
        }),
    };

    if (passwordConfig.isEnabled) {
        Object.assign(FormDataValidationSchema, {
            password: Yup.string()
                .nullable()
                .min(config.passwordConfig.minLength, passwordLabels.minLengthLabel)
                .test('password-min-character-sets', passwordLabels.minCharacterSetLabel, (value) =>
                    value ? validatePasswordMinCharacterSets(value, passwordConfig.requiredCharacterClasses) : true,
                ),
            password_confirmation: Yup.string()
                .nullable()
                .when('password', {
                    is: (password: string | undefined) => (password ? password.length > 0 : false),
                    then: Yup.string().nullable().required(labels.requiredField),
                })
                .oneOf([Yup.ref('password'), null], passwordLabels.passwordMatchLabel),
        });
    }

    const formik = useFormik({
        initialValues: config.initialValues,
        validationSchema: Yup.object().shape(FormDataValidationSchema),
        validateOnChange: false,
        validateOnBlur: true,
        onSubmit: () => {
            if (formElement.current) {
                formElement.current.submit();
            }
        },
    });

    return (
        <form
            ref={formElement}
            action={config.formConfig.actionUrl}
            method="post"
            className="margt"
            onSubmit={formik.handleSubmit}
        >
            <FormKey />

            <FormList size="wide">
                {formik.values.personal_id && (
                    <FormListItem label={labels.personalIdLabel} required={true} error={formik.errors.personal_id}>
                        <ControlInput
                            name="personal_id"
                            value={formik.values.personal_id}
                            disabled={formik.values.has_personal_id}
                        />
                    </FormListItem>
                )}
                <FormListItem label={labels.firstNameLabel} required={true} error={formik.errors.firstname}>
                    <ControlInput
                        name="firstname"
                        value={formik.values.firstname}
                        onChange={formik.handleChange}
                        readonly={config.nameConfig.isReadOnly}
                    />
                </FormListItem>
                <FormListItem label={labels.lastNameLabel} required={true} error={formik.errors.lastname}>
                    <ControlInput
                        name="lastname"
                        value={formik.values.lastname}
                        onChange={formik.handleChange}
                        readonly={config.nameConfig.isReadOnly}
                    />
                </FormListItem>
                <FormListItem label={labels.emailLabel} required={true} error={formik.errors.email}>
                    <ControlInput name="email" value={formik.values.email} onChange={formik.handleChange} />
                </FormListItem>
                {formik.values.is_dob_enabled && (
                    <FormListItem label={labels.dobLabel} required={true} error={formik.errors.dob}>
                        <ControlInput
                            name="dob"
                            value={formik.values.dob ?? ''}
                            readonly={formik.values.has_dob}
                            onChange={formik.handleChange}
                        />
                    </FormListItem>
                )}
                {formik.values.is_gender_enabled && (
                    <FormListItem label={labels.genderLabel} required={true} error={formik.errors.gender}>
                        <ControlSelect
                            disableClear={true}
                            disableSearch={true}
                            disabled={formik.values.has_gender}
                            value={formik.values.gender ?? ''}
                            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                void formik.setFieldValue('gender', e.target.value);
                            }}
                        >
                            <option />
                            {config.genderSelectConfig.options.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </ControlSelect>
                        <input type="hidden" name="gender" value={formik.values.gender ?? ''} />
                    </FormListItem>
                )}

                {config.cookieConsentConfig?.isGdprEnabled && <CookieConsent config={config.cookieConsentConfig} />}
                {config.newsletterConfig?.isNewsletterEnabled && <Newsletter config={config.newsletterConfig} />}
                {config.passwordConfig.isEnabled && (
                    <React.Fragment>
                        <FormListItem label={passwordLabels.changePasswordLabel} isSeparated={true} isFieldSet={true} />
                        <FormListItem label={passwordLabels.newPasswordLabel} error={formik.errors.password}>
                            <ControlPassword
                                name="password"
                                value={formik.values.password ?? ''}
                                onChange={formik.handleChange}
                                showPasswordButtonLabel={passwordLabels.showPasswordLabel}
                                passwordStrengthConfig={config.passwordStrengthConfig}
                            />
                        </FormListItem>
                        <FormListItem
                            label={passwordLabels.confirmPasswordLabel}
                            error={formik.errors.password_confirmation}
                        >
                            <ControlPassword
                                name="password_confirmation"
                                value={formik.values.password_confirmation ?? ''}
                                onChange={formik.handleChange}
                                showPasswordButtonLabel={passwordLabels.showPasswordLabel}
                            />
                        </FormListItem>
                    </React.Fragment>
                )}
            </FormList>

            <Buttons>
                <Button type="submit" intent="primary" title={labels.formSubmitLabel} />
                <Button
                    type="anchor"
                    intent="secondary"
                    title={labels.cancelButtonLabel}
                    onClick={() => {
                        window.location.href = config.formConfig.backUrl;
                    }}
                />
            </Buttons>
        </form>
    );
};

export default CustomerEditForm;
