import React from 'react';
import Pagination from 'components/ui/TableTools/Toolbar/Pagination';
import Limiter from 'components/ui/TableTools/Toolbar/Limiter';
import IToolbarConfig from 'components/ui/TableTools/interfaces/IToolbarConfig';

interface IProps {
    config: IToolbarConfig;
}

const Toolbar = (props: IProps): JSX.Element => {
    const { config } = props;
    const { limiterConfig, paginationConfig } = config;

    return (
        <React.Fragment>
            {config.showAmounts && <p className="table-tools__amount">{config.labels.amountsLabel}</p>}

            {paginationConfig.lastPageNum > 0 && <Pagination config={paginationConfig} />}

            {config.showLimiter && <Limiter config={limiterConfig} />}
        </React.Fragment>
    );
};

export default Toolbar;
