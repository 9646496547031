import React from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import Img from 'components/Image/Img';
import { useMutation } from 'redux-query-react';
import loginRequest from 'components/SocialLogin/requests/LoginRequest';
import { handleLoginResponse } from 'components/SocialLogin/helpers/LoginHandler';

interface IProps {
    config: {
        clientId: string;
        clientSecret: string;
    };
}

const GoogleLoginButton = (props: IProps) => {
    const [{}, socialLoginRequest] = useMutation((accessToken: string) => loginRequest('google', accessToken));
    const handleLogin = useGoogleLogin({
        onSuccess: (tokenResponse) => {
            socialLoginRequest(tokenResponse.code).then((response) => {
                handleLoginResponse(response.body);
            });
        },
        flow: 'auth-code',
    });
    return (
        <button type="button" className="button button-google" onClick={handleLogin}>
            <Img iconName="icon_google2" alt="Google" />
            <span>Google</span>
        </button>
    );
};

export default GoogleLoginButton;
