import React from 'react';
import BrandMenu from 'components/BrandMenu/BrandMenu';
import PopOverMenuItem from 'components/ui/MainMenu/PopOverMenuItem';

interface IProps {
    config: {
        url: string;
        label: string;
        categoryId: number;
    };
}

const BrandMenuButton = (props: IProps) => {
    const { config } = props;
    const { url, label, categoryId } = config;

    return (
        <PopOverMenuItem
            target={
                <a href={url}>
                    <span>{label}</span>
                </a>
            }
            content={<BrandMenu categoryId={categoryId} />}
        />
    );
};

export default BrandMenuButton;
