import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useRequest } from 'redux-query-react';
import Button from 'andbeauty-ui/lib/Components/Button';
import AutocompleteRequest, {
    IAutocompleteCategory,
    IAutocompleteItem,
    IAutocompleteProduct,
} from 'components/CatalogSearch/requests/AutocompleteRequest';
import Product from 'components/CatalogSearch/Autocomplete/Product';
import ProductAttribute from 'components/CatalogSearch/Autocomplete/ProductAttribute';
import Category from 'components/CatalogSearch/Autocomplete/Category';

interface IProps {
    isFocused: boolean;
    actionUrl: string;
    autocompleteUrl: string;
    queryParamName: string;
    queryText: string;
    labels: {
        startingFrom: string;
        viewAllResults: string;
    };
}

const mapStateToProps = (state) => {
    return {
        searchResults: state.entities.productAjaxSuggestRequest as IAutocompleteItem[],
    };
};

const SearchResults = (props: IProps) => {
    const { actionUrl, autocompleteUrl, queryParamName, queryText, labels, isFocused } = props;
    const [{ isFinished }] = useRequest(AutocompleteRequest(autocompleteUrl, queryParamName, queryText));
    const { searchResults } = useSelector(mapStateToProps);
    const [searchResultsGrouped, setSearchResultsGrouped] = useState<{ [key: string]: { items: IAutocompleteItem[] } }>(
        {},
    );

    const viewAllUrl = new URL(actionUrl);
    viewAllUrl.searchParams.append(queryParamName, queryText);

    useEffect(() => {
        if (isFinished && searchResults.length) {
            const grouped = {};

            searchResults.forEach((item) => {
                if (!grouped[item.type]) {
                    grouped[item.type] = {
                        items: [],
                    };
                }

                grouped[item.type]['items'].push(item);
            });

            setSearchResultsGrouped(grouped);
        }
    }, [searchResults, isFinished]);

    return (
        <div className={classNames('search__results', { open: isFocused })}>
            <ul className="search__results__list">
                {Object.keys(searchResultsGrouped).map((type) => (
                    <React.Fragment key={type}>
                        {searchResultsGrouped[type]['items'].map((item) => {
                            if (type === 'product-attribute') {
                                return <ProductAttribute key={item.title} {...item} />;
                            } else if (type === 'category') {
                                return <Category key={item.title} {...(item as IAutocompleteCategory)} />;
                            } else if (type === 'product') {
                                return (
                                    <Product
                                        key={item.title}
                                        item={item as IAutocompleteProduct}
                                        labels={{ startingFrom: labels.startingFrom }}
                                    />
                                );
                            } else {
                                return <React.Fragment />;
                            }
                        })}
                        <li className="search__results__separator" />
                    </React.Fragment>
                ))}
                <li className="search__results__actions">
                    <Button title={labels.viewAllResults} intent="secondary" type="anchor" href={viewAllUrl.href} />
                </li>
            </ul>
        </div>
    );
};

export default SearchResults;
