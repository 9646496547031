import { combineReducers } from 'redux';
import { entitiesReducer, queriesReducer } from 'redux-query';
import { OverlaysState } from 'data/overlays/interfaces';
import overlaysReducer from 'data/overlays/overlaysReducer';
import selectedFiltersReducer from 'components/LayeredNavigation/store/selectedFiltersReducer';
import { ISelectedFiltersState } from 'components/LayeredNavigation/store/selectedFiltersSelector';
import variantProductReducer from 'components/Catalog/store/variantProductReducer';
import { IVariantProductState } from 'components/Catalog/store/variantProductSelector';

export interface IStoreState {
    overlaysReducer: OverlaysState;
    selectedFiltersReducer: ISelectedFiltersState;
    variantProductReducer: IVariantProductState;
}

const combineRootReducer = combineReducers({
    entities: entitiesReducer,
    queries: queriesReducer,
    overlaysReducer,
    selectedFiltersReducer,
    variantProductReducer: variantProductReducer,
});

export default combineRootReducer;
