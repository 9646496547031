import React from 'react';
import SystemNotifications from 'andbeauty-ui/lib/Applications/eBeauty/Components/SystemNotifications';
import SystemNotification from 'andbeauty-ui/lib/Applications/eBeauty/Components/SystemNotification';
import RenderHTML from 'andbeauty-ui/lib/Components/RenderHTML';

interface IProps {
    messages: IMessage[];
    labels: {
        close: string;
    };
}

export interface IMessage {
    intent: 'success' | 'warn' | 'danger';
    content: string;
    hideIcon?: boolean;
    closable?: boolean;
}

const MessageManager = (props: IProps) => {
    const { messages, labels } = props;

    return (
        <SystemNotifications inline compact>
            {messages.map((message, i) => (
                <SystemNotification
                    intent={message.intent}
                    key={i}
                    closable={message.closable}
                    hideIcon={message.hideIcon}
                    labelClose={labels.close}
                >
                    <RenderHTML html={message.content} nowrapper />
                </SystemNotification>
            ))}
        </SystemNotifications>
    );
};

export default MessageManager;
