import { request } from 'data/requests/request';

const AutocompleteRequest = (url: string, queryParamName: string, queryText: string) =>
    request({
        type: 'productAjaxSuggestRequest',
        url,
        data: {
            [queryParamName]: queryText,
        },
        method: 'GET',
        notApi: true,
        absolute: true,
        force: true,
    });

export default AutocompleteRequest;

export interface IAutocompleteItem {
    type: 'product' | 'product-attribute' | 'category';
    title: string;
    label: string;
    url: string;
}

export interface IAutocompleteProduct extends IAutocompleteItem {
    id: number;
    attributeSetId: number;
    image: string;
    price: number;
    specialPrice?: number;
    minimalPrice?: number;
}

export interface IAutocompleteCategory extends IAutocompleteItem {
    categories: string[];
}
