import React, { useCallback, useState } from 'react';
import { debounce } from 'lodash';
import classNames from 'classnames';
import Img from 'components/Image/Img';
import SearchResults from 'components/CatalogSearch/SearchResults';
import { ITopSearch } from 'components/CatalogSearch/interfaces/ITopSearch';
import Icon from 'andbeauty-ui/lib/Components/Icon/index';

interface IProps {
    config: ITopSearch;
    autoFocus?: boolean;
}

const TopSearch = (props: IProps) => {
    const { config, autoFocus } = props;
    const { actionUrl, autocompleteUrl, queryParamName, labels } = config;
    const [queryText, setQueryText] = useState(config.queryText ?? '');
    const [queryInput, setQueryInput] = useState(config.queryText ?? '');
    const [isFocused, setIsFocused] = useState(false);

    const debounceQuery = useCallback(
        debounce((query: string) => {
            if (query.length >= config.minSearchLength && query.length <= config.maxSearchLength) {
                setQueryText(query);
            }
        }, 500),
        [],
    );

    return (
        <form className="search" action={config.actionUrl} method="GET">
            <div
                className={classNames('search__inner', { active: isFocused, open: isFocused })}
                tabIndex={0}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
            >
                <label>
                    <input
                        type="text"
                        autoComplete="off"
                        autoFocus={autoFocus}
                        name={config.queryParamName}
                        placeholder={labels.placeholder}
                        value={queryInput}
                        onChange={(e) => {
                            setQueryInput(e.target.value);
                            debounceQuery(e.target.value);
                        }}
                    />
                    {queryInput.length > 1 && (
                        <button
                            type="reset"
                            className="search__clear"
                            onClick={() => {
                                setQueryInput('');
                                setQueryText('');
                            }}
                            aria-label={labels.clearSearch}
                        >
                            <Icon kind="close" width={12} height={12} />
                        </button>
                    )}
                    <span className="label">{labels.search}</span>
                    <button type="submit" className="icon search__submit">
                        <Img iconName="sprite_search" alt={labels.search} />
                    </button>
                </label>
                {queryText && (
                    <SearchResults {...{ actionUrl, autocompleteUrl, queryParamName, queryText, labels, isFocused }} />
                )}
            </div>
        </form>
    );
};

export default TopSearch;
