import React from 'react';
import Img from 'components/Image/Img';
import Button from 'andbeauty-ui/lib/Components/Button';
import { ISelectedFilters } from 'components/LayeredNavigation/store/selectedFiltersSelector';

interface IProps {
    config: {
        clearAllUrl: string;
        labels: {
            remove: string;
            clearAll: string;
            selectedFilters: string;
        };
    };
    selectedFilters: ISelectedFilters;
    removeFilter: (attributeCode: string, item: { label: string; value: string }) => void;
    showClearAllButton: boolean;
}

const SelectedFiltersWrapper = (props: IProps) => {
    const { config, selectedFilters, showClearAllButton, removeFilter } = props;
    const { clearAllUrl, labels } = config;

    return (
        <div className="product-filters__applied">
            <p>
                <span className="product-filters__applied-title">{labels.selectedFilters}: </span>
                {Object.keys(selectedFilters).map((attributeCode) =>
                    selectedFilters[attributeCode].map((selectedFilterItem) => (
                        <span className="product-filters__tag" key={`${attributeCode}-${selectedFilterItem.value}`}>
                            {selectedFilterItem.label}
                            <button onClick={() => removeFilter(attributeCode, selectedFilterItem)}>
                                <span className="icon">
                                    <Img iconName="sprite_remove" />
                                </span>
                                {labels.remove}
                            </button>
                        </span>
                    )),
                )}
            </p>
            {showClearAllButton && (
                <Button
                    type="anchor"
                    intent="secondary"
                    size="xsmall"
                    href={clearAllUrl}
                    title={labels.clearAll}
                    icon="remove"
                />
            )}
        </div>
    );
};

export default SelectedFiltersWrapper;
