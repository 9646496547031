import * as React from 'react';
import SimplePayment from 'components/Checkout/Payment/Methods/SimplePayment';
import { IPaymentMethodProps } from 'components/Checkout/Payment/PaymentBlock';
import { useMutation } from 'redux-query-react';
import Toaster from 'andbeauty-ui/lib/Components/Toaster/index';
import { IPaymentMethodResponse } from '../../interfaces/payment/IPaymentMethodResponse';
import { postRequest } from 'data/requests/postRequest';
import isLoggedIn from '../../../../helpers/auth/isLoggedIn';

interface IProps {
    paymentMethodProps: IPaymentMethodProps;
    method: IPaymentMethodResponse;
    email: string | undefined;
    setAllowQuery: (allowQuery: boolean) => void;
    extensionAttributes?: () => {
        risks_awareness?: string;
        agreement_ids?: string[];
    };
}

const Montonio = (props: IProps) => {
    const { method, email, extensionAttributes, setAllowQuery } = props;
    const [{}, postData] = useMutation((data) =>
        postRequest({
            type: 'setPayment',
            url: isLoggedIn
                ? 'carts/mine/set-payment-information'
                : `guest-carts/${window.quoteIdMask}/set-payment-information`,
            data,
            useStoreCode: true,
        }),
    );
    const redirect = { to: `checkout/onepage/success` };
    return (
        <SimplePayment
            method={method.code}
            key={method.code}
            onClick={() => {
                setAllowQuery(true);
                postData({
                    cartId: window.quoteIdMask,
                    email,
                    paymentMethod: {
                        method: method.code,
                        extension_attributes: extensionAttributes ? extensionAttributes() : [],
                    },
                }).then((response) => {
                    setAllowQuery(false);
                    if (response.body === true || response.body === 'true') {
                        props.paymentMethodProps.onClick({ redirect });
                    }
                });
            }}
            label={method.title}
            paymentMethodProps={props.paymentMethodProps}
        />
    );
};

export default Montonio;
