import React from 'react';
import { parse } from 'date-fns';
import GenericCallout, { CalloutIntent } from 'components/ui/Callouts/GenericCallout';
import isBetween from '../../helpers/date/isBetween';

interface IProps {
    config: {
        type: NotificationType;
        content: string;
        displayFrom?: string;
        displayTo?: string;
    };
}

enum NotificationType {
    GRAY = 'gray',
    RED = 'red',
    YELLOW = 'yellow',
    GREEN = 'green',
}

const intentByType = (type: NotificationType): CalloutIntent => {
    switch (type) {
        case NotificationType.RED:
            return CalloutIntent.DANGER;
        case NotificationType.YELLOW:
            return CalloutIntent.WARNING;
        case NotificationType.GREEN:
            return CalloutIntent.SUCCESS;
        default:
            return CalloutIntent.DEFAULT;
    }
};

const NotificationWidget = (props: IProps) => {
    const { config } = props;
    const { type, content, displayFrom, displayTo } = config;
    const currentTime = new Date();
    let fromTime: Date | null = null;
    let untilTime: Date | null = null;

    if (displayFrom) {
        fromTime = parse(displayFrom, 'yyyy-MM-dd HH:mm:ss', new Date());
    }

    if (displayTo) {
        untilTime = parse(displayTo, 'yyyy-MM-dd HH:mm:ss', new Date());
    }

    if (!isBetween(currentTime, fromTime, untilTime)) {
        return <React.Fragment />;
    }

    return <GenericCallout intent={intentByType(type)} content={content} />;
};

export default NotificationWidget;
