import { request } from 'data/requests/request';

const DataConfigRequest = () =>
    request({
        type: 'sampleProductsDataConfigRequest',
        url: 'sampleproduct/ajax/dataconfig',
        method: 'GET',
        notApi: true,
    });

export default DataConfigRequest;

export interface ISampleProductDataConfigResponse {
    sampleProducts: ISampleProduct[];
    availableSamplesQty: number;
    labels: {
        addToCart: string;
        noThanks: string;
    };
}

export interface ISampleProduct {
    sku: string;
    name: string;
    image: string;
    brand: string;
}
