import 'custom-event-polyfill';
import { ThemeProvider } from 'react-jss';

import * as React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { Provider as ReduxQueryProvider } from 'redux-query-react';
import configureStore from './helpers/reduxStore';
import LocaleRouter from './routers/localeRouter';

export const store = configureStore();

// Theme is inlined by webpack
declare const window: {
    THEME: { [key: string]: any };
    rootElement: string;
};

if (document.documentElement) {
    document.documentElement.classList.remove('is-loading');
}

const CheckoutProvider = () => {
    return (
        <Provider store={store}>
            <ThemeProvider theme={{ ...window.THEME }}>
                <ReduxQueryProvider queriesSelector={(state) => state.queries}>
                    <BrowserRouter>
                        <LocaleRouter />
                    </BrowserRouter>
                </ReduxQueryProvider>
            </ThemeProvider>
        </Provider>
    );
};

export default CheckoutProvider;
