import React, { useState } from 'react';
import Buttons from 'andbeauty-ui/lib/Components/Buttons/index';
import Button from 'andbeauty-ui/lib/Components/Button/index';
import Img from 'components/Image/Img';
import RenderHTML from 'andbeauty-ui/lib/Components/RenderHTML';

interface IProps {
    config: {
        fullText: string;
        contentLength?: number;
        filteredText?: string;
        labels: {
            readMoreLabel: string;
            readLessLabel?: string;
        };
        asHtml?: boolean;
        buttonProps?: {
            intent?:
                | 'default'
                | 'primary'
                | 'success'
                | 'danger'
                | 'discrete'
                | 'secondary'
                | 'secondary-muted'
                | 'tertiary';
            size?: 'default' | 'small' | 'xsmall' | 'large';
            className?: string;
            disableIcon?: boolean;
        };
    };
}

const TextTruncate = (props: IProps): JSX.Element => {
    const { config } = props;
    const [isFiltered, setIsFiltered] = useState(!!config.filteredText);
    const hasToShowComponent =
        (config.filteredText?.length && !config.contentLength) ||
        (!!config.contentLength && config.fullText.length > config.contentLength);

    return (
        <React.Fragment>
            {hasToShowComponent && (
                <React.Fragment>
                    <div className={'plain-html content'}>
                        {!isFiltered && <RenderHTML html={config.fullText} nowrapper={true} />}
                        {isFiltered && <RenderHTML html={config.filteredText} nowrapper={true} />}
                    </div>
                    {((config.labels.readLessLabel === undefined && isFiltered) ||
                        config.labels.readLessLabel !== undefined) && (
                        <Buttons>
                            <Button
                                intent={config.buttonProps?.intent || 'secondary-muted'}
                                size={config.buttonProps?.size || 'small'}
                                className={config.buttonProps?.className || ''}
                                title={
                                    <React.Fragment>
                                        {!config?.buttonProps?.disableIcon && (
                                            <React.Fragment>
                                                <Img iconName={isFiltered ? 'icon_arrow_down02' : 'icon_arrow_up02'} />{' '}
                                            </React.Fragment>
                                        )}
                                        {!config.asHtml && isFiltered && config.labels.readMoreLabel}
                                        {!config.asHtml && !isFiltered && config.labels.readLessLabel}
                                        {config.asHtml && (
                                            <RenderHTML
                                                html={
                                                    isFiltered
                                                        ? config.labels.readMoreLabel
                                                        : config.labels.readLessLabel
                                                }
                                                nowrapper={true}
                                            />
                                        )}
                                    </React.Fragment>
                                }
                                onClick={(event) => {
                                    event.preventDefault();
                                    setIsFiltered(!isFiltered);
                                }}
                            />
                        </Buttons>
                    )}
                </React.Fragment>
            )}

            {!hasToShowComponent && <RenderHTML html={config.fullText} nowrapper={true} />}
        </React.Fragment>
    );
};

export default TextTruncate;
