import React from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import Img from 'components/Image/Img';
import { useMutation } from 'redux-query-react';
import loginRequest from 'components/SocialLogin/requests/LoginRequest';
import { handleLoginResponse } from 'components/SocialLogin/helpers/LoginHandler';

interface IProps {
    config: {
        appId: string;
    };
}

const FacebookLoginButton = (props: IProps) => {
    const { config } = props;
    const [{}, socialLoginRequest] = useMutation((accessToken: string) => loginRequest('facebook', accessToken));

    const handleLogin = (tokenResponse) => {
        if (tokenResponse.accessToken) {
            socialLoginRequest(tokenResponse.accessToken).then((response) => {
                handleLoginResponse(response.body);
            });
        }
    };

    return (
        <FacebookLogin
            appId={config.appId}
            fields="name,email"
            scope="public_profile,email,user_birthday"
            callback={handleLogin}
            render={(renderProps) => (
                <button type="button" className="button button-facebook" onClick={renderProps.onClick}>
                    <Img iconName="icon_facebook3" alt="Facebook" />
                    <span>Facebook</span>
                </button>
            )}
        />
    );
};

export default FacebookLoginButton;
