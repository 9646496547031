import React from 'react';
import { ILimiterConfig } from 'components/ui/TableTools/interfaces/IToolbarConfig';

interface IProps {
    config: ILimiterConfig;
}

const Limiter = (props: IProps): JSX.Element => {
    const { config } = props;

    return (
        <label className="table-tools__per-page">
            {config.labels.showLabel}
            <select
                className="form-control"
                value={config.currentLimit}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    window.location.href = config.availableLimits[e.target.value];
                }}
            >
                {Object.keys(config.availableLimits).map((limit) => (
                    <option key={limit} value={limit}>
                        {limit}
                    </option>
                ))}
            </select>
            {config.labels.perPageLabel}
        </label>
    );
};

export default Limiter;
