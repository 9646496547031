import { postRequest } from 'data/requests/postRequest';

interface IProduct {
    id: number;
    sku: string;
}

const RemoveProductRequest = (url: string, quoteId: string, product: IProduct) =>
    postRequest({
        method: 'DELETE',
        type: 'RemoveProductRequest',
        url: `${url}/${product.id}`,
        data: {
            cartItem: {
                sku: product.sku,
                quote_id: quoteId,
            },
        },
    });

export default RemoveProductRequest;
