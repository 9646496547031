import React from 'react';
import { useRequest } from 'redux-query-react';
import { useSelector } from 'react-redux';
import InstagramFeedRequest, {
    IInstagramFeedResponse,
    IInstagramPost,
} from 'components/InstagramFeed/requests/InstagramFeedRequest';

interface IProps {
    config: {
        title: string;
    };
}

const mapStateToProps = (state) => {
    return {
        instagramFeedRequest: state.entities.instagramFeedRequest as IInstagramFeedResponse,
    };
};

const InstagramFeed = (props: IProps) => {
    const { config } = props;
    const [{ isFinished }] = useRequest(InstagramFeedRequest());
    const { instagramFeedRequest } = useSelector(mapStateToProps);

    return (
        <React.Fragment>
            {instagramFeedRequest && (
                <React.Fragment>
                    <header className="frame-ebeauty__section__header">
                        <h2 className={'center'}>{config.title}</h2>
                    </header>
                    <div className="photogrid">
                        {instagramFeedRequest.data.map((post: IInstagramPost) => (
                            <div className="photogrid__item" key={post.permalink}>
                                <a href={post.permalink}>
                                    <img src={post.media_url} alt={post.caption} />
                                </a>
                            </div>
                        ))}
                    </div>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default InstagramFeed;
