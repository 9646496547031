import React, { useEffect, useState } from 'react';
import TimeRemaining from 'andbeauty-ui/lib/Applications/Checkout/Components/TimeRemaining';
import RenderHTML from 'andbeauty-ui/lib/Components/RenderHTML/index';

interface IProps {
    timeRemaining: number;
    callout: string;
    onTimeChange: (timeRemaining: number) => void;
}

const QuoteReservationTimer = (props: IProps) => {
    const { callout, onTimeChange } = props;

    const [timeRemaining, setTimeRemaining] = useState(props.timeRemaining);

    const getFormattedCallout = (timestamp: number) => {
        const formattedTime = getFormattedTime(timestamp);

        return `<p>${callout.replace('%1', formattedTime)}</p>`;
    };

    const getFormattedTime = (timestamp: number) => {
        const minutesLeft = Math.floor(timestamp / 60);
        const secondsLeft = (timestamp % 60).toString().padStart(2, '0');

        return `${minutesLeft}:${secondsLeft}`;
    };

    useEffect(() => {
        onTimeChange(timeRemaining);

        if (timeRemaining === 0) {
            return;
        }

        const timer = setTimeout(() => {
            setTimeRemaining(timeRemaining - 1);
        }, 1000);

        return () => clearTimeout(timer);
    }, [timeRemaining]);

    return (
        <React.Fragment>
            {timeRemaining > 0 && (
                <TimeRemaining>
                    <RenderHTML html={getFormattedCallout(timeRemaining)} nowrapper />
                </TimeRemaining>
            )}
        </React.Fragment>
    );
};

export default QuoteReservationTimer;
