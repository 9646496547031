import { applyMiddleware, compose, createStore } from 'redux';
import { queryMiddleware } from 'redux-query';

import thunk from 'redux-thunk';
import { exceptionHandler } from '../middlewares/exceptionHandler';
import rootReducer from './rootReducer';
import superagentInterface from 'redux-query-interface-superagent';

export const getQueries = (state) => state.queries;
export const getEntities = (state) => state.entities;

export default function configureStore() {
    const queryMiddlewareHandler = queryMiddleware(superagentInterface, getQueries, getEntities);

    const devToolsCompose =
        Env.NODE_ENV === 'development' ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose;

    return createStore(rootReducer, devToolsCompose(applyMiddleware(thunk, queryMiddlewareHandler, exceptionHandler)));
}
