import React, { useEffect, useRef, useState } from 'react';
import BoxProduct from 'components/Catalog/Product/List/BoxProduct';
import Pager, { IPagerProps } from 'components/ui/Pager';
import { useMutation } from 'redux-query-react';
import ProductListRequest, { IProductListResponse } from 'components/Catalog/requests/ProductListRequest';
import { IProduct } from 'components/Catalog/interfaces/IProduct';
import useScrollRestoration from '../../../hooks/useScrollRestoration';
import useAuth from '../../../hooks/useAuth';
import getTagManagerContext from 'components/SeoSuite/helpers/tagManagerContext';
import LoadingContent from 'andbeauty-ui/lib/Components/LoadingContent';

export interface IProductListLabels {
    addedToCart: string;
    outOfStock: string;
    startingFrom: string;
    addToCart: string;
    readMore: string;
    choose: string;
    new: string;
    vegan: string;
}

interface IProps {
    config: {
        products: IProduct[];
        currentCategoryId?: number;
        ajaxUrl: string;
        paginationConfig: IPagerProps;
        labels: IProductListLabels;
    };
}

const ProductList = (props: IProps) => {
    const { config } = props;
    const { currentCategoryId, labels } = config;

    useScrollRestoration();
    const { customer, hasAuthStatus } = useAuth();
    const productListElement = useRef(null);
    const [{ isPending }, productListRequest] = useMutation((url: string) => ProductListRequest(url));
    const [products, setProducts] = useState<IProduct[]>(config.products);
    const [paginationConfig, setPaginationConfig] = useState<IPagerProps>(config.paginationConfig);

    const onPageChange = async (pageNum: number) => {
        await handlePageHistoryChange(pageNum);

        window.scrollTo({ top: 0, behavior: 'smooth' });
        reloadProducts();
    };

    const handlePageHistoryChange = async (pageNum: number) => {
        const pagerUrl = new URL(window.location.href);
        pagerUrl.searchParams.set(config.paginationConfig.pageParam, pageNum.toString());

        window.history.pushState({}, '', pagerUrl.href);
    };

    const reloadProducts = () => {
        const ajaxUrl = new URL(config.ajaxUrl);
        ajaxUrl.search = window.location.search;

        if (currentCategoryId) {
            ajaxUrl.searchParams.set('categoryId', currentCategoryId.toString());
        }

        productListRequest(ajaxUrl).then((result) => {
            const response: IProductListResponse = result.body;

            setProducts(response.products);
            setPaginationConfig(response.paginationConfig);
        });
    };

    useEffect(() => {
        window.addEventListener('layered-navigation:filtersUpdated', reloadProducts);
        return function cleanup() {
            window.removeEventListener('layered-navigation:filtersUpdated', reloadProducts);
        };
    }, [productListRequest]);

    useEffect(() => {
        if (hasAuthStatus) {
            const tagManagerContext = getTagManagerContext(productListElement);

            tagManagerContext.then((context) => {
                window.dispatchEvent(
                    new CustomEvent('view-item-list', {
                        detail: {
                            ...context,
                            userId: customer?.id,
                            items: products.map((product) => {
                                return {
                                    sku: product.sku,
                                    name: product.name,
                                    price: product.specialPrice ?? product.price,
                                };
                            }),
                        },
                    }),
                );
            });
        }
    }, [hasAuthStatus]);

    return (
        <React.Fragment>
            {isPending && <LoadingContent layout="flex" size="large" />}
            {!isPending && (
                <div className="layout-products" ref={productListElement}>
                    <div className="layout-products__list light">
                        {products.map((product) => (
                            <BoxProduct key={product.id} product={product} labels={labels} />
                        ))}
                    </div>
                </div>
            )}
            {paginationConfig.lastPage > 1 && <Pager config={paginationConfig} onChange={onPageChange} />}
        </React.Fragment>
    );
};

export default ProductList;
