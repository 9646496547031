import React, { useEffect, useState } from 'react';
import { useMutation } from 'redux-query-react';
import ControlAutocomplete, { IAutocompleteOption } from 'andbeauty-ui/lib/Components/ControlAutocomplete/index';
import ControlSelect from 'andbeauty-ui/lib/Components/ControlSelect/index';
import FormListItem from 'andbeauty-ui/lib/Applications/eBeauty/Components/FormListItem/index';
import { IAdsAppartment, IAdsResponse, IAdsResponseAddress } from 'components/AdsSearch/interfaces/IAdsResponse';
import { IAdsAddress } from 'components/AdsSearch/interfaces/IAdsAddress';
import { IAdsConfig } from 'components/AdsSearch/interfaces/IAdsConfig';
import AddressRequest from 'components/AdsSearch/requests/AddressRequest';

interface IProps {
    config: IAdsConfig;
}

declare global {
    interface HTMLElementEventMap {
        'ads-address-select': CustomEvent<IAdsAddress>;
    }
}

const AdsSearch = (props: IProps): JSX.Element => {
    const { config } = props;
    const [{}, addressRequest] = useMutation<IAdsResponse>(() => AddressRequest(config.url ?? '', value));
    const [address, setAddress] = useState<IAdsResponseAddress>();
    const [value, setValue] = useState('');
    const [aptValue, setAptValue] = useState('');
    const initialOptionList: IAutocompleteOption[] = [{ label: '', value: '' }];
    const [optionList, setOptionList] = useState<IAutocompleteOption[]>(initialOptionList);
    const [aptOptionList, setAptOptionList] = useState<IAutocompleteOption[]>();

    useEffect(() => {
        if (address) {
            const aptString = aptValue ? `-${aptValue}` : '';
            const detail: IAdsAddress = {
                region: address.maakond,
                region_id: config.countyIdMap?.find((item) => item.adsCode === address.ehakmk)?.id || 0,
                street: [`${address.aadresstekst}${aptString}`],
                street_wo_house_no: address.liikluspind,
                house_no: address.aadress_nr,
                apartment_no: aptValue,
                postcode: address.sihtnumber,
                city: address.omavalitsus,
            };
            window.dispatchEvent(new CustomEvent('ads-address-select', { detail }));
        }
    }, [address, aptValue]);

    useEffect(() => {
        if (value === '') {
            setAptValue('');
            setAptOptionList([]);
            setAddress(undefined);
        }
    }, [value]);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (value.length > 2) {
                void addressRequest().then((response) => {
                    if (response.body && response.body.addresses) {
                        setOptionList(
                            response.body.addresses.map((addr: IAdsResponseAddress) => {
                                return {
                                    label: addr.ipikkaadress,
                                    value: addr.ipikkaadress,
                                    data: addr,
                                };
                            }),
                        );
                    }
                });
            }
        }, 500);
        return () => clearTimeout(timer);
    }, [value]);

    return (
        <React.Fragment>
            <FormListItem label={config.labels.addressSearchLabel} required={false}>
                <ControlAutocomplete
                    value={value}
                    data={optionList}
                    onChange={(e) => setValue(e)}
                    onRemove={() => window.dispatchEvent(new CustomEvent('ads-address-clear'))}
                    onSelect={(e) => {
                        setValue(e.label);
                        setAptValue('');

                        if ((e.data as IAdsResponseAddress).appartments?.length) {
                            setAptOptionList(
                                e.data.appartments.map((apt: IAdsAppartment) => {
                                    return (
                                        <option key={apt.tahis} value={apt.tahis}>
                                            {apt.tahis}
                                        </option>
                                    );
                                }),
                            );
                        } else {
                            setAptOptionList(undefined);
                        }
                        setAddress(e.data);
                    }}
                    minChars={3}
                />
            </FormListItem>
            {!!aptOptionList?.length && (
                <FormListItem label={config.labels.selectApartmentLabel} required={false}>
                    <ControlSelect
                        value={aptValue}
                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setAptValue(e.target.value)}
                        size={'small'}
                    >
                        {aptOptionList}
                    </ControlSelect>
                </FormListItem>
            )}
        </React.Fragment>
    );
};

export default AdsSearch;
