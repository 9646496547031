import React, { useCallback, useEffect, useState } from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { useMutation } from 'redux-query-react';
import { AccountIdentifier } from 'components/SocialLogin/EnumAccountIdentifier';
import connectAccountRequest from 'components/SocialLogin/requests/ConnectAccountRequest';
import disconnectAccountRequest from 'components/SocialLogin/requests/DisconnectAccountRequest';
import DefaultAttachButton from 'components/SocialLogin/AttachButtons/DefaultAttachButton';
import Toaster from 'andbeauty-ui/lib/Components/Toaster/index';

interface IProps {
    config: IFacebookAttachButtonConfig;
}

export interface IFacebookAttachButtonConfig {
    isEnabled: boolean;
    accountIdentifier: string;
    appId: string;
    userId?: string;
    labels: {
        connectedLabel: string;
        disconnectedLabel: string;
        connectLabel: string;
        disconnectLabel: string;
    };
}

const FacebookAttachButton = (props: IProps): JSX.Element => {
    const { config } = props;
    const [{}, connectRequest] = useMutation((account, accessToken) => connectAccountRequest(account, accessToken));
    const [{}, disConnectRequest] = useMutation((account) => disconnectAccountRequest(account));
    const [isConnected, setIsConnected] = useState(!!config.userId);

    const handleClick = useCallback(
        (tokenResponse) => {
            if (!isConnected) {
                connectAccount(tokenResponse);
            } else {
                disconnectAccount();
            }
        },
        [isConnected],
    );
    //
    const connectAccount = (tokenResponse) => {
        void connectRequest(AccountIdentifier.FACEBOOK, tokenResponse.accessToken)
            .then((response) => {
                if (response.body.error === 1) {
                    Toaster.addToast({
                        intent: 'danger',
                        text: response.body.message,
                    });
                } else {
                    setIsConnected(true);
                }
            })
            .catch(() => {
                window.location.reload();
            });
    };

    const disconnectAccount = () => {
        void disConnectRequest(AccountIdentifier.FACEBOOK)
            .then(() => {
                setIsConnected(false);
            })
            .catch(() => {
                window.location.reload();
            });
    };

    return (
        <FacebookLogin
            appId={config.appId}
            fields="name,email"
            scope="public_profile,email,user_birthday"
            callback={handleClick}
            render={(renderProps) => (
                <DefaultAttachButton
                    config={{
                        iconName: 'icon_facebook2',
                        iconLabel: 'Facebook',
                        isConnected,
                        handleClick: renderProps.onClick,
                        labels: {
                            connect: config.labels.connectLabel,
                            connected: config.labels.connectedLabel,
                            disconnect: config.labels.disconnectLabel,
                            disconnected: config.labels.disconnectedLabel,
                        },
                    }}
                />
            )}
        />
    );
};

export default FacebookAttachButton;
