import { IPaymentMethodResponse } from '../../interfaces/payment/IPaymentMethodResponse';
import { empty } from '../../../../helpers/empty';

export function sortPaymentMethods(paymentMethods: IPaymentMethodResponse[] | null, paymentLogos) {
    const payments = paymentLogos?.payment;

    if (empty(payments) || !paymentMethods || empty(paymentMethods)) {
        return [[], []];
    }

    const defaultLayoutPayments: IPaymentMethodResponse[] = [];
    const verticalLayoutPayments: IPaymentMethodResponse[] = [];
    paymentMethods.forEach((method: IPaymentMethodResponse) => {
        const isDefaultLayoutMethod = payments[method.code] && !empty(payments[method.code].isDefaultLayoutMethod);

        if (isDefaultLayoutMethod) {
            defaultLayoutPayments.push(method);
        } else {
            verticalLayoutPayments.push(method);
        }
    });

    return [defaultLayoutPayments, verticalLayoutPayments];
}
