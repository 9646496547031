import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Overlay from 'andbeauty-ui/lib/Components/Overlay';
import Buttons from 'andbeauty-ui/lib/Components/Buttons';
import Button from 'andbeauty-ui/lib/Components/Button';
import Icon from 'andbeauty-ui/lib/Components/Icon';
import { IStoreState } from '../../helpers/rootReducer';
import FormList from 'andbeauty-ui/lib/Applications/eBeauty/Components/FormList/index';
import FormListItem from 'andbeauty-ui/lib/Applications/eBeauty/Components/FormListItem';
import { closeOverlay } from 'data/overlays/overlayHandler';
import Img from 'components/Image/Img';
import CartShareEmailForm from 'components/CartShare/CartShareEmailForm';
import { includes } from 'lodash';
import { useRequest } from 'redux-query-react';
import cartShareRequest from 'components/CartShare/requests/cartShareRequest';
import ControlInput from 'andbeauty-ui/lib/Components/ControlInput';

export interface IProps {
    config: IShareCart;
}

export interface ITranslations {
    shareCart: string;
    copyLink: string;
    orShareByEmail: string;
    yourName: string;
    recipientName: string;
    recipientEmail: string;
    message: string;
    send: string;
    requiredField: string;
    invalidEmail: string;
}

export interface IShareCart {
    labels: ITranslations;
    url: string;
    emailActionUrl: string;
}

const mapStateToProps = (state) => {
    return {
        cartShareUrl: state.entities.cartShareRequest?.url,
    };
};

const CartSharePopup = (props: IProps) => {
    const { config } = props;
    const dispatch = useDispatch();

    const [{}, shareRequest] = useRequest(cartShareRequest(config.url));
    const { cartShareUrl } = useSelector(mapStateToProps);

    const { openOverlays } = useSelector((state: IStoreState) => state.overlaysReducer);
    const isOpen = includes(openOverlays, 'shareCartPopup');

    const reloadShareRequest = () => shareRequest();
    useEffect(() => {
        if (isOpen) {
            reloadShareRequest();
        }
    }, [isOpen]);

    const closePopup = () => {
        dispatch(closeOverlay({ name: 'all' }));
    };

    return (
        <Overlay
            isOpen={isOpen}
            doClose={closePopup}
            layout="dialog"
            className="ebeauty-dialog"
            customHeader={
                <div className="ebeauty-dialog-heading">
                    <h2>{config.labels.shareCart}</h2>
                    <button onClick={closePopup}>
                        <Icon kind="close02" width={16} height={16} />
                    </button>
                </div>
            }
        >
            {cartShareUrl && (
                <div className="ebeauty-dialog-content">
                    <div className="layout-grid vertical">
                        <div className="layout-grid__column">
                            <h3 className="mt10">{config.labels.copyLink}</h3>
                            <FormList size="full">
                                <FormListItem>
                                    <ControlInput disabled={true} className="form-control-url" value={cartShareUrl} />
                                </FormListItem>
                            </FormList>
                            <Buttons>
                                <Button
                                    type="button"
                                    intent="secondary-muted"
                                    size="small"
                                    onClick={() => {
                                        navigator.clipboard.writeText(cartShareUrl);
                                    }}
                                    icon="ebeauty-link"
                                    title={config.labels.copyLink}
                                />
                            </Buttons>
                        </div>
                        <CartShareEmailForm
                            cartShareUrl={cartShareUrl}
                            actionUrl={config.emailActionUrl}
                            labels={config.labels}
                        />
                    </div>
                </div>
            )}
        </Overlay>
    );
};

export default CartSharePopup;
