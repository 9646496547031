import React, { useEffect, useState } from 'react';
import Buttons from 'andbeauty-ui/lib/Components/Buttons';
import Button from 'andbeauty-ui/lib/Components/Button';
import ControlQuantity from 'components/GeneralForm/Field/ControlQuantity';
import useCartSummary from '../../../../hooks/useCartSummary';
import { IProduct } from 'components/Catalog/interfaces/IProduct';
import { IVariantProductInformation } from 'components/Catalog/Product/Configurator/ProductVariantResolver';
import { useMutation } from 'redux-query-react';
import AddToCartRequest, { IProductConfiguration } from 'components/Checkout/requests/AddToCartRequest';

interface IProps {
    product: IProduct;
    variantProductInformation?: IVariantProductInformation;
    onAddToCart: (result: { qty: number }) => void;
    onError: (error: string) => void;
    labels: {
        addToCart: string;
        addToCartFailed: string;
        lessLabel: string;
        moreLabel: string;
        qtyLabel: string;
    };
}
const ProductActions = (props: IProps) => {
    const { product, variantProductInformation, onAddToCart, onError, labels } = props;
    const { quoteId, addToCartUri } = useCartSummary();
    const [{ isPending }, addToCartRequest] = useMutation((url, quoteId, product, qty, configurations) =>
        AddToCartRequest(url, quoteId, product, qty, configurations),
    );

    const [canAddToCart, setCanAddToCart] = useState(true);
    const [qty, setQty] = useState(1);

    const handleAddToCart = async () => {
        const productConfiguration: IProductConfiguration[] = [];

        if (variantProductInformation) {
            Object.keys(variantProductInformation.selectedOptions).forEach((attributeId) => {
                productConfiguration.push({
                    option_id: attributeId,
                    option_value: variantProductInformation.selectedOptions[attributeId].id,
                });
            });
        }

        const response = await addToCartRequest(addToCartUri, quoteId, product, qty, productConfiguration);

        if (response.status === 200) {
            onAddToCart({ qty });
        } else {
            onError(response.body.message ?? labels.addToCartFailed);
        }
    };

    useEffect(() => {
        if (product.typeId === 'configurable') {
            setCanAddToCart(!!variantProductInformation);
        }
    }, [product, variantProductInformation]);

    return (
        <div className="product-actions">
            <div className="product-actions__row">
                <div className="product-actions__quantity">
                    <ControlQuantity labels={labels} onChange={(newQty) => setQty(newQty)} />
                </div>
            </div>
            <Buttons>
                <Button
                    loading={isPending}
                    intent="primary"
                    title={labels.addToCart}
                    icon={'ebeauty-icon-cart-inverted'}
                    onClick={handleAddToCart}
                    disabled={isPending || !canAddToCart}
                />
            </Buttons>
        </div>
    );
};

export default ProductActions;
