import React from 'react';
import { IProduct } from 'components/Catalog/interfaces/IProduct';
import { IProductListLabels } from 'components/Catalog/Product/ProductList';
import MultiCarousel from 'andbeauty-ui/lib/Components/MultiCarousel';
import BoxProduct from 'components/Catalog/Product/List/BoxProduct';

interface IProps {
    config: {
        products: IProduct[];
        labels: IProductListLabels;
    };
}

const BestSellerSlider = (props: IProps) => {
    const { config } = props;

    return (
        <div className="carousel carousel-inline size-auto">
            <MultiCarousel showArrows renderArrowsOutside>
                {config.products.map((product) => (
                    <BoxProduct product={product} labels={config.labels} key={product.sku} />
                ))}
            </MultiCarousel>
        </div>
    );
};

export default BestSellerSlider;
