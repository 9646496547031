import React, { useEffect } from 'react';
import { useRequest } from 'redux-query-react';
import { useDispatch, useSelector } from 'react-redux';
import { ISelectedOption } from 'components/Catalog/Product/ProductConfigurator';
import ProductViewRequest, { IProductViewResponse } from 'components/Catalog/requests/ProductViewRequest';
import { setSelectedProduct } from 'components/Catalog/store/variantProductHandler';

interface IProps {
    ajaxUrl: string;
    variantProductInformation: IVariantProductInformation;
}

export interface IVariantProductInformation {
    productId: string;
    selectedOptions: ISelectedOption;
}

const mapStateToProps = (state) => {
    return {
        productViewRequest: state.entities.productViewRequest as IProductViewResponse,
    };
};

const ProductVariantResolver = (props: IProps) => {
    const { ajaxUrl, variantProductInformation } = props;
    const { productId } = variantProductInformation;

    const requestUrl = new URL(ajaxUrl);
    requestUrl.searchParams.set('productId', productId);

    const [{ isFinished }] = useRequest(ProductViewRequest(requestUrl.href));
    const { productViewRequest } = useSelector(mapStateToProps);
    const dispatch = useDispatch();

    useEffect(() => {
        if (isFinished && productViewRequest.product && productViewRequest.galleryConfig) {
            dispatch(
                setSelectedProduct({
                    product: productViewRequest.product,
                    galleryConfig: productViewRequest.galleryConfig,
                }),
            );
        }
    }, [isFinished, productViewRequest]);

    return null;
};

export default ProductVariantResolver;
