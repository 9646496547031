import React from 'react';
import PriceValue from './PriceValue';
import { IUnitPriceData } from 'components/Catalog/interfaces/IProduct';

interface IProps {
    price: number;
    specialPrice?: number;
    minimalPrice?: number;
    pricePrefix?: string;
    unitPriceData?: IUnitPriceData;
}
export const PriceRenderer = (props: IProps) => {
    const { price, specialPrice, minimalPrice, pricePrefix, unitPriceData } = props;

    const unitPrices = {
        regular: undefined,
        special: undefined,
        referenceUnit: undefined,
    };

    if (unitPriceData) {
        Object.assign(unitPrices, {
            regular: unitPriceData.amount,
            special: unitPriceData.amountSpecial,
            referenceUnit: unitPriceData.referenceUnit,
        });
    }

    return (
        <div className="product-pricing">
            {specialPrice && (
                <React.Fragment>
                    <PriceValue
                        price={specialPrice}
                        type="special"
                        unitPrice={unitPrices.special}
                        referenceUnit={unitPrices.referenceUnit}
                    />
                    <PriceValue
                        price={price}
                        type="discount"
                        unitPrice={unitPrices.regular}
                        referenceUnit={unitPrices.referenceUnit}
                    />
                </React.Fragment>
            )}
            {!specialPrice && !minimalPrice && (
                <PriceValue price={price} unitPrice={unitPrices.regular} referenceUnit={unitPrices.referenceUnit} />
            )}
            {minimalPrice && minimalPrice < price && (
                <React.Fragment>
                    <PriceValue
                        price={minimalPrice}
                        type="special"
                        unitPrice={unitPrices.special}
                        referenceUnit={unitPrices.referenceUnit}
                        pricePrefix={pricePrefix}
                    />
                    <PriceValue
                        price={price}
                        type="discount"
                        unitPrice={unitPrices.regular}
                        referenceUnit={unitPrices.referenceUnit}
                    />
                </React.Fragment>
            )}
            {minimalPrice && minimalPrice >= price && <PriceValue price={minimalPrice} pricePrefix={pricePrefix} />}
        </div>
    );
};

export default PriceRenderer;
