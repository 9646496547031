import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ISelectedFilterItem, ISelectedFilters } from 'components/LayeredNavigation/store/selectedFiltersSelector';
import {
    addFilter,
    initSelectedFilters,
    removeFilter,
} from 'components/LayeredNavigation/store/selectedFiltersHandler';
import { IStoreState } from '../../helpers/rootReducer';
import { useMutation } from 'redux-query-react';
import FiltersRequest, { IFiltersResponse } from 'components/LayeredNavigation/requests/FiltersRequest';
import RenderHTML from 'andbeauty-ui/lib/Components/RenderHTML/index';
import FiltersWrapper from 'components/LayeredNavigation/FiltersWrapper';
import SelectedFiltersWrapper from 'components/LayeredNavigation/SelectedFiltersWrapper';
import LayeredNavigationMobile from 'components/LayeredNavigation/LayeredNavigationMobile';
import Sorter, { IOrderItem } from 'components/ui/Sorter';

interface IProps {
    config: {
        clearAllUrl: string;
        currentCategoryId?: number;
        minPrice: number;
        maxPrice: number;
        productsCount: number;
        filters: IFilter[];
        selectedFilters: ISelectedFilters;
        orders: IOrderItem[];
        currentOrder: string;
        currentDirection: 'asc' | 'desc';
        ajaxUrl: string;
        labels: {
            selectedFilters: string;
            showAll: string;
            showLess: string;
            minimum: string;
            maximum: string;
            remove: string;
            clearAll: string;
            foundProducts: string;
            closeFilters: string;
            applyFilters: string;
            orderBy: string;
        };
    };
}

export interface IFilter {
    attributeCode: string;
    label: string;
    items: IFilterItem[];
}

export interface IFilterItem {
    label: string;
    value: string;
    productCount: number;
}

const LayeredNavigation = (props: IProps) => {
    const { config } = props;
    const { clearAllUrl, currentCategoryId, currentOrder, currentDirection, orders, labels } = config;
    const [filters, setFilters] = useState<IFilter[]>(config.filters);
    const [productsCount, setProductsCount] = useState(config.productsCount);
    const [minPrice, setMinPrice] = useState(config.minPrice);
    const [maxPrice, setMaxPrice] = useState(config.maxPrice);
    const { selectedFilters } = useSelector((state: IStoreState) => state.selectedFiltersReducer);
    const [{}, filtersRequest] = useMutation((url: string) => FiltersRequest(url));
    const dispatch = useDispatch();

    useEffect(() => {
        if (Object.keys(config.selectedFilters).length) {
            dispatch(initSelectedFilters({ selectedFilters: config.selectedFilters }));
        }
    }, [config]);

    const dispatchAddFilter = async (attributeCode: string, item: ISelectedFilterItem, replace = false) => {
        await dispatch(addFilter({ attributeCode, item }, replace));
        await handleFiltersHistoryChange();
        reloadFilters();

        window.dispatchEvent(new CustomEvent('layered-navigation:filtersUpdated'));
    };

    const dispatchRemoveFilter = async (attributeCode: string, item: ISelectedFilterItem) => {
        await dispatch(removeFilter({ attributeCode, item }));
        await handleFiltersHistoryChange();
        reloadFilters();

        window.dispatchEvent(new CustomEvent('layered-navigation:filtersUpdated'));
    };

    const handleFiltersHistoryChange = async () => {
        const filtersUrl = new URL(window.location.href);
        const searchParams = new URLSearchParams(filtersUrl.searchParams);
        const preserveParams = ['q', 'product_list_dir', 'product_list_order'];

        searchParams.forEach((value, key) => {
            if (!preserveParams.includes(key)) {
                filtersUrl.searchParams.delete(key);
            }
        });

        Object.keys(selectedFilters).forEach((attributeCode) => {
            const selectedOptions = selectedFilters[attributeCode];

            if (selectedOptions.length === 1) {
                filtersUrl.searchParams.set(attributeCode, selectedOptions[0].value);
            } else {
                selectedOptions.forEach((selectedFilterItem) => {
                    filtersUrl.searchParams.append(`${attributeCode}[]`, selectedFilterItem.value);
                });
            }
        });

        window.history.pushState({}, '', filtersUrl.href);
    };

    const onOrderChange = async (attributeCode: string, direction: 'asc' | 'desc') => {
        await handleOrderByHistoryChange(attributeCode, direction);

        window.dispatchEvent(new CustomEvent('layered-navigation:filtersUpdated'));
    };

    const handleOrderByHistoryChange = async (attributeCode: string, direction: 'asc' | 'desc') => {
        const orderUrl = new URL(window.location.href);
        orderUrl.searchParams.set('product_list_order', attributeCode);
        orderUrl.searchParams.set('product_list_dir', direction);

        window.history.pushState({}, '', orderUrl.href);
    };

    const reloadFilters = async () => {
        const ajaxUrl = new URL(config.ajaxUrl);
        ajaxUrl.search = window.location.search;

        if (currentCategoryId) {
            ajaxUrl.searchParams.set('categoryId', currentCategoryId.toString());
        }

        filtersRequest(ajaxUrl.href).then((result) => {
            const response = result.body as IFiltersResponse;

            setFilters(response.filters);
            setMinPrice(response.minPrice);
            setMaxPrice(response.maxPrice);
            setProductsCount(response.productsCount);
        });
    };

    return (
        <React.Fragment>
            <div className="product-filters__inner">
                <FiltersWrapper
                    config={{
                        isOverlay: false,
                        maxPrice,
                        minPrice,
                        labels,
                    }}
                    filters={filters}
                    selectedFilters={selectedFilters}
                    addFilter={dispatchAddFilter}
                    removeFilter={dispatchRemoveFilter}
                />

                {!!Object.keys(selectedFilters).length && (
                    <SelectedFiltersWrapper
                        config={{
                            clearAllUrl,
                            labels,
                        }}
                        showClearAllButton
                        selectedFilters={selectedFilters}
                        removeFilter={dispatchRemoveFilter}
                    />
                )}
            </div>
            <div className="product-filters__results">
                <p className="product-filters__results-count">
                    <RenderHTML html={`${labels.foundProducts.replace('%1', productsCount.toString())}`} nowrapper />
                </p>
                <Sorter
                    orders={orders}
                    currentOrder={currentOrder}
                    currentDirection={currentDirection}
                    onChange={onOrderChange}
                    labels={labels}
                />
                <LayeredNavigationMobile
                    config={{
                        clearAllUrl,
                        minPrice,
                        maxPrice,
                        labels,
                    }}
                    filters={filters}
                    selectedFilters={selectedFilters}
                    addFilter={dispatchAddFilter}
                    removeFilter={dispatchRemoveFilter}
                />
            </div>
        </React.Fragment>
    );
};

export default LayeredNavigation;
