import { request } from 'data/requests/request';

const cartShareRequest = (url) =>
    request({
        type: 'cartShareRequest',
        url,
        method: 'GET',
        notApi: true,
        absolute: true,
    });

export default cartShareRequest;
