import React from 'react';
import ProductBadges, { IProductBadge } from './ProductBadges';

interface IProps {
    config: {
        badges: IProductBadge[];
    };
}

const ProductBadgesRenderer = (props: IProps) => {
    const { config } = props;
    const { badges } = config;

    return <ProductBadges badges={badges} />;
};

export default ProductBadgesRenderer;
