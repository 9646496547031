import React, { useEffect, useState } from 'react';

interface IProps {
    className?: string;
    onChange?: (event) => void;
    config: {
        options: ICountryOption[];
        selectedValue?: string;
    };
}

export interface ICountryOption {
    value: string;
    label: string;
    is_region_required: boolean;
    is_default: boolean;
}

const CountrySelect = (props: IProps): JSX.Element => {
    const { config } = props;
    const [selectedItemValue, setSelectedItemValue] = useState(config.selectedValue ?? '');

    useEffect(() => {
        if (!config.selectedValue && config.options.length) {
            setSelectedItemValue(config.options[0].value);
        }
    }, [config.options]);

    return (
        <React.Fragment>
            <select
                id="country"
                className={props.className}
                value={selectedItemValue}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    setSelectedItemValue(e.target.value);

                    if (props.onChange) {
                        props.onChange(e);
                    }
                }}
            >
                {config.options.length &&
                    config.options.map((item: ICountryOption) => (
                        <option key={item.value} value={item.value}>
                            {item.label}
                        </option>
                    ))}
            </select>
            <input type="hidden" value={selectedItemValue} name="country_id" />
        </React.Fragment>
    );
};

export default CountrySelect;
