import React, { useEffect, useRef, useState } from 'react';
import { useMutation } from 'redux-query-react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import FormList from 'andbeauty-ui/lib/Applications/eBeauty/Components/FormList/index';
import FormListItem from 'andbeauty-ui/lib/Applications/eBeauty/Components/FormListItem/index';
import ControlInput from 'andbeauty-ui/lib/Components/ControlInput/index';
import Button from 'andbeauty-ui/lib/Components/Button/index';
import Buttons from 'andbeauty-ui/lib/Components/Buttons/index';
import CheckboxList from 'andbeauty-ui/lib/Applications/eBeauty/Components/CheckboxList/index';
import CheckboxWithLabel from 'andbeauty-ui/lib/Components/CheckboxWithLabel/index';
import addressDeleteRequest from 'components/Customer/requests/AddressDeleteRequest';
import ControlPhone from 'components/GeneralForm/Field/ControlPhone';
import RegionSelect from 'components/ui/RegionSelect';
import CountrySelect from 'components/ui/CountrySelect';
import IAddressForm from 'components/Customer/interfaces/IAddressForm';
import AdsSearch from 'components/AdsSearch';
import { IAdsAddress } from 'components/AdsSearch/interfaces/IAdsAddress';
import Img from 'components/Image/Img';
import FormKey from 'components/GeneralForm/Field/FormKey';
import Overlay from 'andbeauty-ui/lib/Components/Overlay/index';

interface IProps {
    config: IAddressForm;
}

const CustomerAddressForm = (props: IProps): JSX.Element => {
    const { config } = props;
    const formElement = useRef<HTMLFormElement>(null);
    const [{}, addressDelete] = useMutation(() => addressDeleteRequest(config.initialValues.id as number));
    const [phoneNoPrefix, setPhoneNoPrefix] = useState(window.generalConfig?.defaultPhonePrefix ?? '');
    const [phoneNo, setPhoneNo] = useState('');
    const [isDefaultShipping, setIsDefaultShipping] = useState(false);
    const [isDefaultBilling, setIsDefaultBilling] = useState(false);
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);

    const additionalValidation = {};

    if (config.companyConfig.isEnabled) {
        Object.assign(additionalValidation, {
            is_company: Yup.boolean(),
            company: Yup.string()
                .nullable()
                .when('is_company', {
                    is: true,
                    then: Yup.string().nullable().required(config.labels.requiredField),
                }),
            vat_id: Yup.string()
                .nullable()
                .when('is_company', {
                    is: true,
                    then: Yup.string().nullable().required(config.labels.requiredField),
                }),
        });

        if (config.companyConfig.isFaxFieldVisible) {
            Object.assign(additionalValidation, {
                fax: Yup.string()
                    .nullable()
                    .when('is_company', {
                        is: true,
                        then: Yup.string().nullable().required(config.labels.requiredField),
                    }),
            });
        }
    }

    const FormDataValidationSchema = Yup.object().shape({
        firstname: Yup.string().nullable().required(config.labels.requiredField),
        lastname: Yup.string().nullable().required(config.labels.requiredField),
        telephone: Yup.string().nullable().required(config.labels.requiredField),
        street: Yup.string().nullable().required(config.labels.requiredField),
        city: Yup.string().nullable().required(config.labels.requiredField),
        region_id: Yup.string().nullable().required(config.labels.requiredField),
        postcode: Yup.string().nullable().required(config.labels.requiredField),
        ...additionalValidation,
    });

    const formik = useFormik({
        initialValues: config.initialValues,
        validationSchema: FormDataValidationSchema,
        validateOnBlur: true,
        validateOnChange: false,
        onSubmit: () => {
            if (formElement.current) {
                formElement.current.submit();
            }
        },
    });

    const effect = (e: CustomEvent<IAdsAddress>) => {
        const address = e.detail;

        void formik.setFieldValue('street', address.street[0]);
        void formik.setFieldValue('city', address.city);
        void formik.setFieldValue('postcode', address.postcode);
        void formik.setFieldValue('region_id', address.region_id);
    };
    window.addEventListener('ads-address-select', (e) => effect(e as CustomEvent));

    useEffect(() => {
        if (config.initialValues.phone_prefix) {
            setPhoneNoPrefix(config.initialValues.phone_prefix);
        }
        if (config.initialValues.telephone) {
            setPhoneNo(config.initialValues.telephone);
        }

        if (config.initialValues.default_shipping) {
            setIsDefaultShipping(true);
        }

        if (config.initialValues.default_billing) {
            setIsDefaultBilling(true);
        }
    }, [config.initialValues]);

    return (
        <form
            ref={formElement}
            action={config.formConfig.actionUrl}
            method="post"
            className="margt"
            onSubmit={formik.handleSubmit}
        >
            <input type="hidden" name="success_url" value={config.formConfig.successUrl ?? ''} />
            <input type="hidden" name="error_url" value={config.formConfig.errorUrl ?? ''} />
            <input type="hidden" name="back_url" value={config.formConfig.backUrl} />
            <FormKey />

            <FormList size="wide">
                <FormListItem label={config.labels.firstNameLabel} required={true} error={formik.errors.firstname}>
                    <ControlInput name="firstname" value={formik.values.firstname} onChange={formik.handleChange} />
                </FormListItem>

                <FormListItem label={config.labels.lastNameLabel} required={true} error={formik.errors.lastname}>
                    <ControlInput name="lastname" value={formik.values.lastname} onChange={formik.handleChange} />
                </FormListItem>

                <FormListItem label={config.labels.phoneLabel} required={true} error={formik.errors.telephone}>
                    <ControlPhone
                        phoneNoPrefix={phoneNoPrefix}
                        setPhoneNoPrefix={setPhoneNoPrefix}
                        phoneNo={phoneNo}
                        setPhoneNo={setPhoneNo}
                        onChange={(result) => {
                            void formik.setFieldValue('phone_prefix', result.phoneNoPrefix);
                            void formik.setFieldValue('telephone', result.phoneNo);
                        }}
                        phonePrefixes={window.generalConfig?.phonePrefixes ?? []}
                    />
                    <input type="hidden" name="phone_prefix" value={formik.values.phone_prefix ?? ''} />
                    <input type="hidden" name="telephone" value={formik.values.telephone ?? ''} />
                </FormListItem>

                {config.inAdsSearchConfig.enabled && <AdsSearch config={config.inAdsSearchConfig} />}

                <FormListItem label={config.labels.streetLabel} required={true} error={formik.errors.street}>
                    <ControlInput
                        value={formik.values.street ?? ''}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            void formik.setFieldValue('street', e.target.value);
                        }}
                    />
                    <input type="hidden" name="street[]" value={formik.values.street} />
                </FormListItem>

                <FormListItem label={config.labels.cityLabel} required={true} error={formik.errors.city}>
                    <ControlInput name="city" value={formik.values.city ?? ''} onChange={formik.handleChange} />
                </FormListItem>

                <FormListItem label={config.labels.regionLabel} required={true} error={formik.errors.region_id}>
                    <RegionSelect
                        className="form-control"
                        config={config.regionSelectConfig}
                        value={formik.values.region_id}
                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                            void formik.setFieldValue('region_id', e.target.value);
                        }}
                    />
                </FormListItem>

                <FormListItem label={config.labels.postCodeLabel} required={true} error={formik.errors.postcode}>
                    <ControlInput name="postcode" value={formik.values.postcode ?? ''} onChange={formik.handleChange} />
                </FormListItem>

                <FormListItem label={config.labels.countryLabel} required={true} error={formik.errors.country_id}>
                    <CountrySelect
                        className="form-control"
                        config={config.countrySelectConfig}
                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                            void formik.setFieldValue('country_id', e.target.value);
                        }}
                    />
                </FormListItem>

                {config.companyConfig.isEnabled && (
                    <React.Fragment>
                        <FormListItem>
                            <CheckboxWithLabel
                                label={config.labels.isCompanyAddressLabel}
                                checked={formik.values.is_company}
                                onChange={() => {
                                    void formik.setFieldValue('is_company', !formik.values.is_company);
                                }}
                            />
                            {formik.values.is_company && <input type="hidden" name="is_company" value="1" />}
                        </FormListItem>

                        {formik.values.is_company && (
                            <React.Fragment>
                                {config.companyConfig.isCompanyFieldVisible && (
                                    <FormListItem
                                        label={config.labels.companyLabel}
                                        required={true}
                                        error={formik.errors.company}
                                    >
                                        <ControlInput
                                            name="company"
                                            value={formik.values.company ?? ''}
                                            onChange={formik.handleChange}
                                        />
                                    </FormListItem>
                                )}

                                {config.companyConfig.isVatFieldVisible && (
                                    <FormListItem
                                        label={config.labels.vatLabel}
                                        required={true}
                                        error={formik.errors.vat_id}
                                    >
                                        <ControlInput
                                            name="vat_id"
                                            value={formik.values.vat_id ?? ''}
                                            onChange={formik.handleChange}
                                        />
                                    </FormListItem>
                                )}

                                {config.companyConfig.isFaxFieldVisible && (
                                    <FormListItem
                                        label={config.labels.faxLabel}
                                        required={true}
                                        error={formik.errors.fax}
                                    >
                                        <ControlInput
                                            name="fax"
                                            value={formik.values.fax ?? ''}
                                            onChange={formik.handleChange}
                                        />
                                    </FormListItem>
                                )}
                            </React.Fragment>
                        )}
                    </React.Fragment>
                )}

                <FormListItem>
                    <CheckboxList>
                        <CheckboxWithLabel
                            name="default_shipping"
                            label={config.labels.useDefaultShippingLabel}
                            disabled={isDefaultShipping}
                            checked={formik.values.default_shipping ?? false}
                            onChange={() => {
                                void formik.setFieldValue('default_shipping', !formik.values.default_shipping);
                            }}
                        />
                        <CheckboxWithLabel
                            name="default_billing"
                            label={config.labels.useDefaultBillingLabel}
                            disabled={isDefaultBilling}
                            checked={formik.values.default_billing ?? false}
                            onChange={() => {
                                void formik.setFieldValue('default_billing', !formik.values.default_billing);
                            }}
                        />
                    </CheckboxList>
                    {formik.values.default_shipping && !isDefaultShipping && (
                        <input type="hidden" name="default_shipping" value="1" />
                    )}
                    {formik.values.default_billing && !isDefaultBilling && (
                        <input type="hidden" name="default_billing" value="1" />
                    )}
                </FormListItem>
            </FormList>
            {config.initialValues.id && !isDefaultShipping && !isDefaultBilling && (
                <Buttons>
                    <Button
                        type="anchor"
                        intent="secondary-muted"
                        size="small"
                        title={
                            <React.Fragment>
                                <Img iconName="icon_cross" />
                                <span>{config.labels.deleteButtonLabel}</span>
                            </React.Fragment>
                        }
                        onClick={() => setShowConfirmDelete(true)}
                    />
                </Buttons>
            )}
            <hr />
            <Buttons>
                <Button type="submit" intent="primary" title={config.labels.formSubmitLabel} />
                <Button
                    type="anchor"
                    intent="secondary"
                    title={config.labels.cancelButtonLabel}
                    onClick={() => {
                        window.location.href = config.formConfig.backUrl;
                    }}
                />
            </Buttons>

            <Overlay
                layout="dialog"
                isOpen={showConfirmDelete}
                title={config.labels.deleteDialogTitleLabel}
                canOutsideClickClose={true}
                doClose={() => setShowConfirmDelete(false)}
                dialogConfig={{
                    buttons: [
                        {
                            title: config.labels.deleteButtonLabel,
                            intent: 'primary',
                            onClick: () => {
                                void addressDelete().then(() => {
                                    window.location.href = config.formConfig.backUrl;
                                });
                            },
                        },
                        {
                            title: config.labels.cancelDeleteLabel,
                            intent: 'discrete',
                        },
                    ],
                }}
            />
        </form>
    );
};

export default CustomerAddressForm;
