import { request } from 'data/requests/request';
import { IProduct } from 'components/Catalog/interfaces/IProduct';
import { IPagerProps } from 'components/ui/Pager';

const ProductListRequest = (url: string) =>
    request({
        type: 'productListRequest',
        url,
        method: 'GET',
        notApi: true,
        absolute: true,
    });

export default ProductListRequest;

export interface IProductListResponse {
    products: IProduct[];
    paginationConfig: IPagerProps;
}
