import React, { useEffect, useState } from 'react';
import Button from 'andbeauty-ui/lib/Components/Button';
import RenderHTML from 'andbeauty-ui/lib/Components/RenderHTML/index';
import { closeOverlay, openOverlay } from 'data/overlays/overlayHandler';
import { useDispatch, useSelector } from 'react-redux';
import { mapStateToProps } from 'components/SampleProduct/SampleProductPopup';

interface IProps {
    intent?: 'success';
    labels: {
        addFreeSamples: string;
    };
}

const SampleProductPopupTriggerButton = (props: IProps) => {
    const { intent, labels } = props;
    const dispatch = useDispatch();
    const [buttonLabel, setButtonLabel] = useState(labels.addFreeSamples);
    const { sampleProductsDataConfig } = useSelector(mapStateToProps);

    const openSampleProductPopup = (e?: React.MouseEvent<HTMLAnchorElement>) => {
        if (e) {
            e.preventDefault();
        }

        dispatch(closeOverlay({ name: 'all' }));
        dispatch(openOverlay({ name: 'sampleProductPopup' }));
    };

    useEffect(() => {
        if (sampleProductsDataConfig) {
            setButtonLabel(
                labels.addFreeSamples.replace('%1', sampleProductsDataConfig.availableSamplesQty.toString()),
            );

            if (window.location.hash === '#autoOpenSamplesPopup') {
                openSampleProductPopup();
            }
        }
    }, [sampleProductsDataConfig]);

    return (
        <React.Fragment>
            {sampleProductsDataConfig?.availableSamplesQty > 0 && (
                <Button
                    intent={intent ?? 'primary'}
                    title={<RenderHTML html={buttonLabel} nowrapper />}
                    onClick={openSampleProductPopup}
                    size="small"
                />
            )}
        </React.Fragment>
    );
};

SampleProductPopupTriggerButton.displayName = 'Button';

export default SampleProductPopupTriggerButton;
