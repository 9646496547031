import React from 'react';
import classNames from 'classnames';

export interface IProps {
    badges: IProductBadge[];
}

export interface IProductBadge {
    badgeId: number;
    theme: string;
    label: string;
    imageFile?: string;
}

const ProductBadges = (props: IProps) => {
    const { badges } = props;

    return (
        <React.Fragment>
            {!!badges.length && (
                <div className="box-product__badges">
                    {badges.map((badge, i) => (
                        <div className={classNames('box-product__badge-square', badge.theme)} key={i}>
                            {badge.imageFile && <img src={badge.imageFile} alt={badge.label} />}
                            {!badge.imageFile && badge.label}
                        </div>
                    ))}
                </div>
            )}
        </React.Fragment>
    );
};

export default ProductBadges;
