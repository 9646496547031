import React, { useRef } from 'react';
import FormKey from 'components/GeneralForm/Field/FormKey';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import FormList from 'andbeauty-ui/lib/Applications/eBeauty/Components/FormList';
import FormListItem from 'andbeauty-ui/lib/Applications/eBeauty/Components/FormListItem';
import ControlInput from 'andbeauty-ui/lib/Components/ControlInput';
import ControlPassword from 'components/GeneralForm/Field/ControlPassword';
import Buttons from 'andbeauty-ui/lib/Components/Buttons';
import Button from 'andbeauty-ui/lib/Components/Button';

interface IProps {
    config: {
        formConfig: {
            actionUrl: string;
        };
        labels: {
            username: string;
            password: string;
            requiredField: string;
            showPassword: string;
            formSubmitLabel: string;
        };
    };
}

const CustomerLoginForm = (props: IProps) => {
    const { config } = props;
    const { labels, formConfig } = config;

    const formElement = useRef<HTMLFormElement>(null);
    const FormDataValidationSchema = {
        username: Yup.string().nullable().required(config.labels.requiredField),
        password: Yup.string().nullable().required(config.labels.requiredField),
    };
    const formik = useFormik({
        initialValues: { username: '', password: '' },
        validationSchema: Yup.object().shape(FormDataValidationSchema),
        validateOnChange: false,
        validateOnBlur: true,
        onSubmit: () => {
            if (formElement.current) {
                formElement.current.submit();
            }
        },
    });

    return (
        <form ref={formElement} action={config.formConfig.actionUrl} method="post" onSubmit={formik.handleSubmit}>
            <FormKey />
            <FormList size="wide" className="margt">
                <FormListItem label={labels.username} required={true} error={formik.errors.username}>
                    <ControlInput
                        name="username"
                        value={formik.values.username ?? ''}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            formik.setFieldValue('username', e.target.value)
                        }
                    />
                    <input type="hidden" name="login[username]" value={formik.values.username ?? ''} />
                </FormListItem>
                <FormListItem label={labels.password} required={true} error={formik.errors.password}>
                    <ControlPassword
                        name="password"
                        value={formik.values.password ?? ''}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            formik.setFieldValue('password', e.target.value)
                        }
                        showPasswordButtonLabel={labels.showPassword}
                    />
                    <input type="hidden" name="login[password]" value={formik.values.password ?? ''} />
                </FormListItem>
            </FormList>
            <Buttons layout="vertical-wide">
                <Button type="submit" intent="primary" title={labels.formSubmitLabel} />
            </Buttons>
        </form>
    );
};

export default CustomerLoginForm;
