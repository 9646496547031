import React, { useState } from 'react';
import { useMutation } from 'redux-query-react';
import Buttons from 'andbeauty-ui/lib/Components/Buttons';
import Button from 'andbeauty-ui/lib/Components/Button';
import applyCouponRequest from 'components/Checkout/requests/ApplyCouponRequest';
import cancelCouponRequest from 'components/Checkout/requests/CancelCouponRequest';
import MessageManager, { IMessage } from 'components/ui/MessageManager';

interface IProps {
    config: ICouponConfig;
}

export interface ICouponConfig {
    couponCode?: string;
    couponUri: string;
    labels: {
        close: string;
        applyDiscountCode: string;
        enterDiscountCode: string;
        applyDiscount: string;
        cancelCoupon: string;
        failedToApply: string;
        usedCoupon: string;
        canceledCoupon: string;
        couponCannotBeEmpty: string;
    };
}

const Coupon = (props: IProps) => {
    const { config } = props;
    const { couponCode, couponUri, labels } = config;

    const [{}, couponRequest] = useMutation((url: string, coupon: string) => applyCouponRequest(url, coupon));
    const [{}, removeCouponRequest] = useMutation((url) => cancelCouponRequest(url));
    const [hasCoupon, setHasCoupon] = useState(!!couponCode);
    const [couponInput, setCouponInput] = useState(couponCode ?? '');
    const [messages, setMessages] = useState<IMessage[]>([]);

    const applyCoupon = async () => {
        if (!couponInput) {
            setMessages([
                {
                    intent: 'danger',
                    content: labels.couponCannotBeEmpty,
                },
            ]);

            return;
        }

        const response = await couponRequest(couponUri, couponInput);

        if (response.status === 200) {
            setHasCoupon(true);
            setMessages([
                {
                    intent: 'success',
                    content: labels.usedCoupon.replace('%1', couponInput),
                },
            ]);
            window.dispatchEvent(new CustomEvent('cartUpdated'));
        } else {
            setMessages([
                {
                    intent: 'danger',
                    content: response.body.message ?? labels.failedToApply,
                },
            ]);
        }
    };

    const cancelCoupon = async () => {
        const response = await removeCouponRequest(couponUri);

        if (response.status === 200) {
            setHasCoupon(false);
            setCouponInput('');
            setMessages([
                {
                    intent: 'success',
                    content: labels.canceledCoupon,
                },
            ]);
            window.dispatchEvent(new CustomEvent('cartUpdated'));
        } else {
            setMessages([
                {
                    intent: 'danger',
                    content: response.body.message ?? '',
                },
            ]);
        }
    };

    return (
        <div className="cart-block theme01">
            <p>{labels.applyDiscountCode}</p>
            <MessageManager messages={messages} labels={{ close: labels.close }} />
            <form>
                <input
                    type="text"
                    className="form-control"
                    aria-label={labels.enterDiscountCode}
                    placeholder={labels.enterDiscountCode}
                    onChange={(e) => setCouponInput(e.target.value)}
                    readOnly={hasCoupon}
                    value={couponInput}
                />
                <Buttons>
                    {hasCoupon && (
                        <Button
                            type="button"
                            size="small"
                            onClick={cancelCoupon}
                            intent="secondary-muted"
                            icon="ebeauty-cross"
                            title={labels.cancelCoupon}
                        />
                    )}
                    {!hasCoupon && (
                        <Button
                            type="button"
                            size="small"
                            onClick={applyCoupon}
                            intent="secondary-muted"
                            icon="ebeauty-check02"
                            iconClassName="icon-success"
                            title={labels.applyDiscount}
                        />
                    )}
                </Buttons>
            </form>
        </div>
    );
};

export default Coupon;
