import React from 'react';
import Img from 'components/Image/Img';
import { IPaginationConfig } from 'components/ui/TableTools/interfaces/IToolbarConfig';

interface IProps {
    config: IPaginationConfig;
}

const Pagination = (props: IProps): JSX.Element => {
    const { config } = props;

    return (
        <nav role="navigation" className="paging" aria-label={config.labels.paginationLabel}>
            <ul>
                <li className={'paging-item paging-item__previous' + (config.isFirstPage ? ' disabled' : '')}>
                    <a href={config.previousPageUrl} aria-label={config.labels.previousLabel}>
                        <Img iconName="icon_arrow_left02" alt={config.labels.previousLabel} />
                        {config.labels.previousLabel}
                    </a>
                </li>

                {config.canShowFirstPage && (
                    <li className="paging-item">
                        <a href={config.firstPageUrl} aria-label={config.labels.firstPageLabel}>
                            1
                        </a>
                    </li>
                )}

                {config.canShowPreviousJump && (
                    <li className="paging-item">
                        <a href={config.previousJumpUrl}>...</a>
                    </li>
                )}

                {config.framePages.map((frame) => (
                    <li key={frame.pageNum} className="paging-item">
                        <a
                            href={frame.pageUrl}
                            className={frame.isCurrent ? 'current' : ''}
                            aria-current={frame.isCurrent ? 'true' : 'false'}
                            aria-label={frame.label}
                        >
                            {frame.pageNum}
                        </a>
                    </li>
                ))}

                {config.canShowNextJump && (
                    <li className="paging-item">
                        <a href={config.nextJumpUrl}>...</a>
                    </li>
                )}

                {config.canShowLastPage && (
                    <li className="paging-item">
                        <a href={config.lastPageUrl} aria-label={config.labels.lastPageLabel}>
                            {config.lastPageNum}
                        </a>
                    </li>
                )}

                <li className={'paging-item paging-item__next' + (config.isLastPage ? ' disabled' : '')}>
                    <a href={config.nextPageUrl} aria-label={config.labels.nextLabel}>
                        <Img iconName="icon_arrow_right02" alt={config.labels.nextLabel} />
                        {config.labels.nextLabel}
                    </a>
                </li>
            </ul>
        </nav>
    );
};

export default Pagination;
