import React from 'react';
import Overlay from 'andbeauty-ui/lib/Components/Overlay';
import RenderHTML from 'andbeauty-ui/lib/Components/RenderHTML/index';

interface IProps {
    content: string;
    confirmButtonLabel: string;
    cancelButtonLabel: string;
    onConfirm: () => void;
    onCancel: () => void;
}

const ConfirmationDialog = (props: IProps) => {
    const { content, confirmButtonLabel, cancelButtonLabel, onConfirm, onCancel } = props;

    const closeDialog = () => {};

    return (
        <Overlay
            isOpen={true}
            doClose={closeDialog}
            layout="dialog"
            dialogConfig={{
                description: <RenderHTML html={content} nowrapper />,
                buttons: [
                    {
                        title: confirmButtonLabel,
                        intent: 'primary',
                        onClick: onConfirm,
                    },
                    {
                        title: cancelButtonLabel,
                        intent: 'discrete',
                        onClick: onCancel,
                    },
                ],
            }}
        />
    );
};

export default ConfirmationDialog;
