import * as React from 'react';
import { CLIENT_TYPE, IPaymentMethodProps } from 'components/Checkout/Payment/PaymentBlock';
import BanklinkComponent from 'components/Checkout/Payment/Methods/BanklinkComponent';
import Checkmo from 'components/Checkout/Payment/Methods/Checkmo';
import MakeCommerce from 'components/Checkout/Payment/Methods/MakeCommerce';
import isLoggedIn from '../../../../helpers/auth/isLoggedIn';
import Esto from 'components/Checkout/Payment/Methods/Esto';
import Montonio from 'components/Checkout/Payment/Methods/Montonio';
import Empty from 'components/empty/Empty';
import TfBank from 'components/Checkout/Payment/Methods/TfBank';
import Inbank from 'components/Checkout/Payment/Methods/Inbank';
import FreePayment from 'components/Checkout/Payment/Methods/FreePayment';
import EveryPay from 'components/Checkout/Payment/Methods/EveryPay';
import EstoPay from 'components/Checkout/Payment/Methods/EstoPay';
import ResursBank from 'components/Checkout/Payment/Methods/ResursBank';
import ModenaPaymentBase from 'components/Checkout/Payment/Methods/ModenaPaymentBase';

export enum PaymentMethodEnum {
    citadele_lv = 'citadele_lv',
    swedbank = 'swedbank',
    swedbank_lv = 'swedbank_lv',
    swedbank_lt = 'swedbank_lt',
    seb = 'seb',
    seb_lv = 'seb_lv',
    seb_lt = 'seb_lt',
    nordea = 'nordea',
    lhv = 'lhv',
    telia = 'telia',
    liisi = 'liisi',
    makecommerce = 'makecommerce',
    esto_hirepurchase = 'esto_hirepurchase',
    esto_pay_later = 'esto_pay_later',
    esto_x = 'esto_x',
    esto_pay = 'esto_pay',
    checkmo = 'checkmo',
    cashondelivery = 'cashondelivery',
    einvoice = 'einvoice',
    montonio = 'montonio',
    montonio_payments = 'montonio_payments',
    montonio_payments_grouped = 'montonio_payments_grouped',
    tfbank_hp = 'tfbank_hp',
    inbank_payments = 'inbank_payments',
    inbank_payments_ee_hps = 'inbank_payments_ee_hps',
    inbank_payments_pl_hps = 'inbank_payments_pl_hps',
    inbank_payments_ee_go = 'inbank_payments_ee_go',
    inbank_payments_ee_slice = 'inbank_payments_ee_slice',
    inbank_lv = 'inbank_lv',
    verifone_payment = 'verifone_payment',
    free = 'free',
    seb_estonia = 'seb_estonia',
    seb_latvia = 'seb_latvia',
    seb_lithuania = 'seb_lithuania',
    swedbank_estonia = 'swedbank_estonia',
    swedbank_latvia = 'swedbank_latvia',
    swedbank_lithuania = 'swedbank_lithuania',
    lhv_estonia = 'lhv_estonia',
    citadele_latvia = 'citadele_latvia',
    luminor_estonia = 'luminor_estonia',
    everypay = 'everypay',
    resursbank_default = 'resursbank_default',
    banktransfer = 'banktransfer', // resurs bank related method
    modena_slice = 'slice',
    modena_credit = 'credit',
    modena_direct = 'direct',
}
const checkMethodCodeExceptions = (methodCode: string) => {
    if (methodCode.startsWith(PaymentMethodEnum.montonio)) {
        methodCode = PaymentMethodEnum.montonio;
    }
    if (methodCode.startsWith(PaymentMethodEnum.inbank_payments)) {
        methodCode = PaymentMethodEnum.inbank_payments;
    }
    return methodCode;
};

const HandleMethod = (props: IPaymentMethodProps): React.Component => {
    let methodComponent;
    const method = props.method;
    let methodCode = method.code;
    const ignorePayments = [
        PaymentMethodEnum.montonio_payments,
        PaymentMethodEnum.montonio,
        PaymentMethodEnum.inbank_payments,
        PaymentMethodEnum.banktransfer,
        PaymentMethodEnum.resursbank_default,
        methodCode.match('resursbank_account') ? methodCode : null,
    ];

    if (props.clientType === CLIENT_TYPE.CLIENT_BUSINESS) {
        // resurs bank installment does not support business client
        ignorePayments.push(methodCode.match('resursbank_newaccount') ? methodCode : null);
    }

    const disableDefaultLogoPayments = [PaymentMethodEnum.telia, PaymentMethodEnum.liisi];
    if (ignorePayments.includes(methodCode as PaymentMethodEnum)) {
        methodComponent = <Empty />;
        return methodComponent;
    }
    methodCode = checkMethodCodeExceptions(methodCode);

    switch (methodCode) {
        case PaymentMethodEnum.citadele_lv:
        case PaymentMethodEnum.swedbank:
        case PaymentMethodEnum.swedbank_lv:
        case PaymentMethodEnum.swedbank_lt:
        case PaymentMethodEnum.seb:
        case PaymentMethodEnum.seb_lv:
        case PaymentMethodEnum.seb_lt:
        case PaymentMethodEnum.nordea:
        case PaymentMethodEnum.lhv:
        case PaymentMethodEnum.telia:
        case PaymentMethodEnum.liisi:
            methodComponent = (
                <BanklinkComponent
                    disableDefaultLogo={disableDefaultLogoPayments.includes(methodCode)}
                    key={method.code}
                    method={method.code}
                    title={method.title}
                    paymentMethodProps={props}
                    to={`banklink_payment/payment/start/banklink/${method.code}`}
                />
            );
            break;
        case PaymentMethodEnum.seb_estonia:
        case PaymentMethodEnum.seb_latvia:
        case PaymentMethodEnum.seb_lithuania:
        case PaymentMethodEnum.swedbank_estonia:
        case PaymentMethodEnum.swedbank_latvia:
        case PaymentMethodEnum.swedbank_lithuania:
        case PaymentMethodEnum.lhv_estonia:
        case PaymentMethodEnum.citadele_latvia:
        case PaymentMethodEnum.luminor_estonia:
            methodComponent = (
                <BanklinkComponent
                    disableDefaultLogo={disableDefaultLogoPayments.includes(methodCode)}
                    key={method.code}
                    method={method.code}
                    title={method.title}
                    paymentMethodProps={props}
                    to={`payment_banklink/payment/start/method_code/${method.code}`}
                />
            );
            break;
        case PaymentMethodEnum.inbank_lv:
            methodComponent = (
                <BanklinkComponent
                    key={method.code}
                    method={method.code}
                    title={method.title}
                    paymentMethodProps={props}
                    to={`inbank_lv/payment/start/`}
                />
            );
            break;
        case PaymentMethodEnum.tfbank_hp:
            methodComponent = <TfBank key={method.code} method={method} paymentMethodProps={props} />;
            break;
        case PaymentMethodEnum.inbank_payments:
            methodComponent = <Inbank key={method.code} method={method} paymentMethodProps={props} />;
            break;
        case PaymentMethodEnum.makecommerce:
            methodComponent = <MakeCommerce key={method.code} paymentMethodProps={props} />;
            break;
        case PaymentMethodEnum.esto_hirepurchase:
        case PaymentMethodEnum.esto_pay_later:
        case PaymentMethodEnum.esto_x:
            methodComponent = <Esto key={method.code} method={method} paymentMethodProps={props} />;
            break;
        case PaymentMethodEnum.esto_pay:
            methodComponent = <EstoPay key={method.code} method={method} paymentMethodProps={props} />;
            break;
        case PaymentMethodEnum.montonio:
        case PaymentMethodEnum.montonio_payments:
        case PaymentMethodEnum.montonio_payments_grouped:
            methodComponent = (
                <Montonio
                    key={method.code}
                    method={method}
                    paymentMethodProps={props}
                    email={props.email}
                    extensionAttributes={props.extensionAttributes}
                    setAllowQuery={props.setAllowQuery}
                />
            );
            break;
        case PaymentMethodEnum.checkmo:
        case PaymentMethodEnum.cashondelivery:
        case PaymentMethodEnum.einvoice:
            if (
                (props.clientType === CLIENT_TYPE.CLIENT_BUSINESS &&
                    window.moneyOrdersAllowedForBusiness !== undefined) ||
                (window.allowCheckMoAll !== undefined &&
                    (props.clientType === CLIENT_TYPE.CLIENT_BUSINESS || window.allowCheckMoAll)) ||
                methodCode === PaymentMethodEnum.cashondelivery
            ) {
                methodComponent = (
                    <Checkmo
                        key={method.code}
                        method={method.code}
                        title={method.title}
                        paymentMethodProps={props}
                        disabled={
                            methodCode !== PaymentMethodEnum.cashondelivery &&
                            ((window.moneyOrdersAllowedForBusiness !== undefined &&
                                !window.moneyOrdersAllowedForBusiness[methodCode] &&
                                props.clientType === CLIENT_TYPE.CLIENT_BUSINESS) ||
                                (window.allowCheckMoAll !== undefined && !window.allowCheckMoAll && !isLoggedIn()))
                        }
                    />
                );
            }
            break;
        case PaymentMethodEnum.verifone_payment:
            methodComponent = (
                <BanklinkComponent
                    disableDefaultLogo={true}
                    key={method.code}
                    method={method.code}
                    title={method.title}
                    paymentMethodProps={props}
                    to={`verifone_payment/payment/form`}
                />
            );
            break;
        case PaymentMethodEnum.free:
            methodComponent = (
                <FreePayment key={method.code} method={method} paymentMethodProps={props} email={props.email} />
            );
            break;
        case PaymentMethodEnum.everypay:
            methodComponent = (
                <EveryPay
                    key={method.code}
                    method={method}
                    paymentMethodProps={props}
                    email={props.email}
                    extensionAttributes={props.extensionAttributes}
                    setAllowQuery={props.setAllowQuery}
                />
            );
            break;
        case methodCode.match('resursbank_newaccount') ? methodCode : null:
            methodComponent = (
                <ResursBank
                    key={method.code}
                    method={method}
                    paymentMethodProps={props}
                    email={props.email}
                    extensionAttributes={props.extensionAttributes}
                    setAllowQuery={props.setAllowQuery}
                />
            );
            break;
        case methodCode.match(PaymentMethodEnum.modena_credit) ? methodCode : null:
        case methodCode.match(PaymentMethodEnum.modena_slice) ? methodCode : null:
        case methodCode.match(PaymentMethodEnum.modena_direct) ? methodCode : null:
            methodComponent = (
                <ModenaPaymentBase
                    key={method.code}
                    method={method}
                    paymentMethodProps={props}
                    email={props.email}
                    extensionAttributes={props.extensionAttributes}
                    setAllowQuery={props.setAllowQuery}
                />
            );
            break;
        default:
            // tslint:disable-next-line:no-console
            console.error(`Invalid payment method (TODO) "${method.code}"`);
            methodComponent = <Empty />;
            break;
    }
    return methodComponent;
};

export default HandleMethod;
