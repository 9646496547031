import { request } from 'data/requests/request';

const cancelCouponRequest = (url: string) =>
    request({
        method: 'DELETE',
        type: 'cancelCouponRequest',
        url,
    });

export default cancelCouponRequest;
