export interface IOptionsConfig {
    attributes: { [key: number]: IAttribute };
    index: {
        [key: number]: { [key: number]: string };
    };
}

export interface ISwatchConfig {
    [key: number]: {
        [key: number]: ISwatchOption;
    };
}

export interface ISwatchOption {
    type: number;
    label: string;
    thumb: string;
    value: string;
}

export enum SWATCH_TYPE {
    TEXTUAL = 0,
    COLOR = 1,
    IMAGE = 2,
    EMPTY = 3,
}

export interface IAttribute {
    id: string;
    code: string;
    label: string;
    options: IAttributeOption[];
    position: string;
}

export interface IAttributeOption {
    id: string;
    label: string;
    products: string[];
}
