import { useEffect } from 'react';

const saveScrollPos = (asPath: string) => {
    window.sessionStorage.setItem(`scrolledTo:${asPath}`, JSON.stringify({ x: window.scrollX, y: window.scrollY }));
};

const removeScrollPos = (identifier: string) => {
    window.sessionStorage.removeItem(`scrolledTo:${identifier}`);
};

const restoreScrollPos = (identifier: string) => {
    const json = window.sessionStorage.getItem(`scrolledTo:${identifier}`);
    const scrollPos = json ? JSON.parse(json) : undefined;

    return new Promise((resolve, reject) => {
        if (scrollPos) {
            window.scrollTo(scrollPos.x, scrollPos.y);
        }

        resolve();
    });
};

const useScrollRestoration = () => {
    const scrollPosIdentifier = window.location.toString();
    const onBeforeUnload = () => saveScrollPos(window.location.toString());

    useEffect(() => {
        restoreScrollPos(scrollPosIdentifier).then(() => removeScrollPos(scrollPosIdentifier));

        window.addEventListener('beforeunload', onBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', onBeforeUnload);
        };
    }, []);
};

export default useScrollRestoration;
