import React, { useCallback, useEffect } from 'react';
import { useRequest } from 'redux-query-react';
import DataConfigRequest from 'components/SampleProduct/requests/DataConfigRequest';
import CartSummaryRequest from 'components/Checkout/requests/CartSummaryRequest';

const EventListeners = () => {
    const [{}, dataConfigRequest] = useRequest(DataConfigRequest());
    const [{}, summaryRequest] = useRequest(CartSummaryRequest());

    const reloadDataConfig = () => dataConfigRequest();
    const reloadCartSummary = () => summaryRequest();

    const effect = useCallback(() => {
        reloadDataConfig();
        reloadCartSummary();
    }, [reloadDataConfig, reloadCartSummary]);

    useEffect(() => {
        window.addEventListener('cartUpdated', effect);

        return function cleanup() {
            window.removeEventListener('cartUpdated', effect);
        };
    }, []);

    return null;
};

export default EventListeners;
