import { request } from 'data/requests/request';

const activeReviewRequest = (url) =>
    request({
        type: 'reviews',
        url: `${url}`,
        method: 'GET',
        notApi: true,
        absolute: true,
    });

export default activeReviewRequest;
