import { request } from 'data/requests/request';

const loginRequest = (type: string, accessToken: string) =>
    request({
        type: 'socialLoginRequest',
        url: 'customer/ajax/sociallogin',
        data: {
            type,
            accessToken,
        },
        method: 'POST',
        notApi: true,
    });

export default loginRequest;

export interface ILoginResponse {
    error: boolean;
    message: string;
    action: 'login' | 'register';
}
